import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import Button from '@material-ui/core/Button';
import {BASE_API_URL} from '../../constant';
import userContext from "../../context/userContext";


const CreateEmployee = () => {
    const history = useHistory();
    const { userData, setUserData } = useContext(userContext);
    const [role, setRole] = useState([]);
    const [employee,setEmployee] = useState({
        name:"",
        role_id:"",
        email:"",
        password:"",
        status:"1",
    })

    let name, value;
    const handleInputs = (e) => {
        name=e.target.name;
        value=e.target.value;
        setEmployee({...employee, [name]:value}); 
    }

    const getRoles = async () => {
        const options = {
          method: "GET",
          headers: {
            Authorization: userData.token,
          },
        };
        const response = await fetch(
          `${BASE_API_URL}role-list`, options);
    
        const data = response.json(); 
    
        data.then((data) => { 
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
          setRole(data.data);
        })
          .catch((error) => console.log(error));
    };
    useEffect(() => {
        getRoles();
        // eslint-disable-next-line
      }, []);

    const PostData = async (e) => {
        e.preventDefault();
        const {name,role_id,email,password,status } = employee;
        const res = await fetch(`${BASE_API_URL}create-employee`,{
            method:"POST",
            headers:{
                    "Content-Type":"application/json",
                    "authorization":userData.token
            },
            body:JSON.stringify({
                name,role_id,email,password,status
            })          
        });

        const data=await res.json();
        if (res.status === 401) {
              localStorage.removeItem("auth-token");
              setUserData({
                token: "",
              });
        }
        
        if(!data)
        {
            window.alert("Somthing went wrong");
        }
        else
        {
            if(data.statusCode === 200){
            window.alert('Employee added successfully');
            history.push("/employee-list");
            }
            else{
                window.alert(data.message);
            }
        }
    }
return ( 
    <div class="container">
        <div class="row">
            <h2 class="page-title my-3 ">Add Employee</h2>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="border-bottom pb-2 ">
                    <div id="services" className="tab-pane active my-4">
                        <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3 ">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="">Employee Name<span className='orangeTxt'>*</span></label>
                                        <input type="text" name="name" className="form-control" value={employee.name} onChange={handleInputs} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Role<span className='orangeTxt'>*</span></label>
                                        <select name="role_id" value={employee.role_id} className="form-control" onChange={handleInputs} >
                                            <option value="">Select Role</option>
                                            { role && role.map((rol,i)=> {
                                                return <option value={rol._id}>{rol.role_name}</option>
                                            })}
                                        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Email Address<span className='orangeTxt'>*</span></label>
                                        <input type="text" name="email" className="form-control" value={employee.email} onChange={handleInputs} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Password<span className='orangeTxt'>*</span></label>
                                        <input type="text" name="password" className="form-control" value={employee.password} onChange={handleInputs} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Status<span className='orangeTxt'>*</span></label>
                                        <select name="status" className="form-control" value={employee.status} onChange={handleInputs} >
                                            <option value="1">Active</option>
                                            <option value="2">Inactive</option>
                                        </select>
                                        </div>
                                    </div>
                                </div>
                            </div>          
                        </div>
                        <Button  color="primary" disableElevation variant="contained" onClick={PostData} >Save</Button>
                        </div>          
                    </div>
            </div>                    
        </div>                
    </div>
            
    );
}

export default CreateEmployee;