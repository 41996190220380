import React, { useContext, useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Button,
  Typography
} from "@material-ui/core";
import { Link } from 'react-router-dom'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import { BASE_API_URL } from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import './../../styles/cssformui.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Checkbox from "@material-ui/core/Checkbox";
import priceType from "./../../util/priceType";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell style={{ fontWeight: 'bold' }}>Sr.No.</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Service Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Service Type</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Vendor Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Price Type</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Price</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold' }}>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ vendorId, VendorChange,vendorName,serviceType,selectedService,handleService,handleStatusChange, filterStatus }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Top Services
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Vendor</InputLabel>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={vendorId}
          onChange={VendorChange}
        >
           <MenuItem value="">Select Vendor</MenuItem>
          {vendorName.map(element => {
           return <MenuItem value={element._id}>{element.firstName} { element.lastName}</MenuItem>
          })}
        </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Service Type</InputLabel>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedService}
          onChange={handleService}
        >
          <MenuItem value="">Select Service Type</MenuItem>
          {serviceType.map(element => {
           return <MenuItem value={element._id}>{element.name} </MenuItem>
          })}
        </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Status</InputLabel>
          <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterStatus}
          onChange={handleStatusChange}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="1">Featured</MenuItem>
          <MenuItem value="0">Not Featured</MenuItem>
        </Select>
        </FormControl>

        </div>
    </Toolbar>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function TopServices({ classes }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const {userData, setUserData } = useContext(userContext);
  const [count, setcount] = useState(0);
  const [create, setcreate] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [vendor, setVendor] = useState([]);
  const [vendorId, setVenderId] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [serviceType, setServiceType] = useState([]);
  const [approv, setApprov] = React.useState(true); 
  const [selectedService, setSelectedService] = React.useState("");
  const [selectedVenderService, setselectedVenderService] = useState(
    {
      vser: []
    }
  );

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { vser } = selectedVenderService;

    if(checked){
      setselectedVenderService({
        vser: [...vser, value],
       });
    }
    else{
      setselectedVenderService({
        vser: vser.filter((e) => e !== value),
      });
    }
  }

  // const handleSubmit = async ()=>{
  //   if(selectedVenderService.vser.length>0){
  //     let formdata = new FormData();
  //     formdata.append("services", JSON.stringify(selectedVenderService.vser));
  //     const response = await fetch(
  //       `${BASE_API_URL}update-top-services`,
  //       {
  //           method: "POST",
  //           headers: {
  //               Authorization: userData.token,
  //           },
  //           body: formdata
  //       }
  //   );
  //   const res = await response.json();
  //   }
  //   else{
  //     alert('Please select services')
  //   }
  // } 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const VendorChange = (event) => { 
    setVenderId(event.target.value);
  };

  const handleService = (event) => {
    setSelectedService(event.target.value);
  };

  const getServices = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(`${BASE_API_URL}get-vendor-services?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}&vendorId=${vendorId}&serviceId=${selectedService}&featuredStatus=${status}`, options);
    const data = response.json();

    data.then((data) => { 
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setRows(data.data.result);
      setVendor(data.data.vendorData)
      setcount(data.data.serviceCount);
      setLoading(false);
    })
    .catch((error) => console.log(error));
  };

  const getServiceType = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(`${BASE_API_URL}service-types?limit=100`, options);
    const data = response.json();

    data.then((data) => { 
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setServiceType(data.data.result);
    })
    .catch((error) => console.log(error));
  };

  const actionApprove = async(status,serviceId) => {
    confirmAlert({
        title: '',
        message: 'Do you want to update service',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { 
                setLoading(true);
                let options = {
                  method: "POST",
                  headers: {
                      Authorization: userData.token,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    "serviceId": serviceId,
                    "isFeatured" : status==1 ? 0 : 1 ,
                  })
              };
                try
                  {
                    const response =  fetch(`${BASE_API_URL}marktop-services`,options);
                    setApprov(!approv);
                  }
                catch(err){
                  alert("Error" + err)
                }
            }
          },
          {
            label: 'Cancel',
            onClick: () => {}
          }
        ]
      });
  }



  useEffect(() => {
    getServices();
    getServiceType();
    // eslint-disable-next-line
  }, [search, create, approv,rowsPerPage, page, vendorId,selectedService,status]);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Paper style={{ margin: 10 }}>
          <EnhancedTableToolbar handleStatusChange={handleStatusChange} filterStatus={status} selectedService={selectedService} handleService={handleService} serviceType={serviceType} vendorName={vendor} vendorId={vendorId} VendorChange={VendorChange} onChange={e => { setsearch(e.target.value); setPage(0) }} />
          <Divider />
          <TableContainer>
            <Table >
              <EnhancedTableHead />
              <TableBody>
              {rows.map((row,index)=>{
                let v = vendor.find((vid)=>{ return vid._id == row.vendorId}); 

                return <StyledTableRow key={index}>
                <StyledTableCell align='left'>{page*rowsPerPage+index+1}</StyledTableCell>
                <StyledTableCell align='left'>{row.title}</StyledTableCell>
                <StyledTableCell align='left'>{row.serviceId.name}</StyledTableCell>
                <StyledTableCell align='left'>{v.firstName} {v.lastName}</StyledTableCell>
                <StyledTableCell align='left'>{priceType[row.priceType]}</StyledTableCell>
                <StyledTableCell align='left'>{row.price}</StyledTableCell>
                <StyledTableCell align='left' onClick={()=>{ actionApprove(row.isFeatured,row._id)}}>{row.isFeatured ? <span style={{color:"green"}}>Featured</span>: <span style={{color:"red"}}>Not Featured</span> }</StyledTableCell>
              </StyledTableRow>
              })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})