import React,{ useContext, useEffect, useState } from 'react';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Button,
    lighten
  } from "@material-ui/core";

  import { makeStyles, withStyles } from "@material-ui/core/styles";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import TableHeader from '../../components/table/TableHeader';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';

const EnhancedTableToolbar = ({ value, onChange, onButtonClicked }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root} style={{ padding: '-10px'}}>
      <Typography
        className={classes.subtitle}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Event Wise Payments
      </Typography>

     {/*<div style={{ marginLeft: "auto" }}>
        <Modal type='Add' buttonText='Add Vendor' />
      </div>*/}
    </Toolbar>
  );
};




function EnhancedTableHead() {
    return (
      <TableHead>
        <StyledTableRow >
          <StyledTableCell>No.</StyledTableCell>
          <StyledTableCell>DATE</StyledTableCell>
          <StyledTableCell>EVENT</StyledTableCell>
          <StyledTableCell>LISTING</StyledTableCell>
          <StyledTableCell>SERVICE</StyledTableCell>
          <StyledTableCell>AMOUNT</StyledTableCell>
        </StyledTableRow>
      </TableHead>
    );
  }

const VendorServiceDetails = ({ history }) => {
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState('');
  const [tab, settab] = useState("Event");
  const classes = useStyles();
  const [create, setCreate] = useState(false);
  const [categoryUpdate, setcategoryUpdate] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setcount] = useState(0);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.paper} style={{ margin: 30 }}>
      <TableHeader
        lable="Vendor Payment Details"
        btnName={`Approve ${tab}`}
        value={search}
        onChange={e => setSearch(e.target.value)} 
      />
      <Divider />
     
      <Grid container className={classes.pageBody} style={{paddingRight: '20px' }}>
        <Grid container xs={6} className={classes.pageBody}>
          <div>
            <span className={classes.head} >Nora Cook</span><br/>
            <span>Dubai, UAE.</span><br/>
          </div>
          <div id="payment" style={{padding: '30px 0px', width:'100%'}}>
          <Grid container style={{ marginTop: '20px'}}>
              <Grid container xs={6}>
              <span>Email : <strong>muhammad@gmail.com</strong></span><br/>
              <span>Phone Number : <strong>9992589631</strong></span><br/>      
              </Grid>
              <Grid container xs={2}>
                    &nbsp;
              </Grid>
              <Grid container xs={4}>
              <span>DUE AMOUNT: <strong><span style={{color : 'blue'}}>AED 700</span></strong></span><br/>
              <span>Events : <strong>15</strong></span>
              </Grid>
            </Grid>  
            <Grid container style={{ marginTop: '30px'}}>
                <span>Account Details   &nbsp;&nbsp; </span> <br/>                                
            </Grid>  
            <Grid container>
                <span><strong> Created On - 24 August 2021</strong></span> 
            </Grid>   
          </div>
          
        </Grid>
        <Grid container style={{ border: '1px solid rgba(224, 224, 224, 1)'}}>
        <Divider />
        <div id="listings" style={{width:'100%'}}>
            <EnhancedTableToolbar value={search} />
            <Divider />
            <TableContainer>
                <Table >
                  <EnhancedTableHead />
                  <TableBody>
                        <StyledTableRow style={{padding:'5px'}}>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> 1</StyledTableCell>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>15 March 2022</StyledTableCell>
                          <StyledTableCell align='left'>Birthday</StyledTableCell>
                          <StyledTableCell align='left'>The Ritz Dubai</StyledTableCell>
                          <StyledTableCell align='left'>Venue</StyledTableCell>
                          <StyledTableCell align='left'><span style={{color : 'blue'}}>AED 350</span></StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{padding:'5px'}}>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> 2</StyledTableCell>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>04 April 2022</StyledTableCell>
                          <StyledTableCell align='left'>Engagement</StyledTableCell>
                          <StyledTableCell align='left'>The Ritz Dubai</StyledTableCell>
                          <StyledTableCell align='left'>Venue</StyledTableCell>
                          <StyledTableCell align='left'><span style={{color : 'blue'}}>AED 200</span></StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{padding:'5px'}}>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> 3</StyledTableCell>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>23 April 2022</StyledTableCell>
                          <StyledTableCell align='left'>Birthday</StyledTableCell>
                          <StyledTableCell align='left'>Ritz Decor</StyledTableCell>
                          <StyledTableCell align='left'>Decoration</StyledTableCell>
                          <StyledTableCell align='left'><span style={{color : 'blue'}}>AED 150</span></StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>          
              </TableContainer>
          </div>
        </Grid>
        <Grid container style={{ marginTop: '30px'}}></Grid>
    </Grid>
    
    </Paper>
    
  );}

  const useToolbarStyles = makeStyles((theme) => ({
    title: {
      fontWeight: '600'
    },
    root: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: '#fff'
    },
  }));
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#fff",
      color: "#787878",
    },
    body: {
      fontSize: 14,
      padding: '0px 16px',
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: '#fff',
      "&:nth-of-type(odd)": {
        backgroundColor: '#f8f8f8',
      },
    },
  }))(TableRow);
  
  

export default withRouter(VendorServiceDetails);



const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#F2F2F2",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  buttonContainer: {
    backgroundColor: '#FFF',
    width: '30%',
    '&>*': {
      width: '50%',
      borderRadius: 0,
      textTransform: 'none'
    },
  },

  pageBody:
    {
    backgroundColor: '#FFF', 
    paddingTop: '20px', 
    paddingLeft: '20px'
  },

  head:{
    fontSize:'30px',
    fontStyle:'bold',
  },

  subhead:{
    fontSize:'15px',
    fontWeight:'bold',
  },

  activeButton: {
    border: '2px solid #f47824',
    backgroundColor: '#f47824',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#f47824'
    }
  }
}));
