import { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import EventCategory from "./EventCategory";
import Events from "./Events";
import TableHeader from '../../components/table/TableHeader';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import EventCategoryAdd from './eventCategoryAdd';

const Event = ({ history }) => {
  const [search, setSearch] = useState('');
  const [tab, settab] = useState("Event");
  const classes = useStyles();
  const [create, setCreate] = useState(false);
  const [categoryUpdate, setcategoryUpdate] = useState(null);


  return (
    <Paper className={classes.paper} style={{ margin: 30 }}>
      {tab !== "Deleted Events" && <TableHeader
          lable="Event Management"
          btnName={`Add ${tab}`}
          value={search}
          onChange={e => setSearch(e.target.value)}
          onButtonClicked={e => {
            tab === 'Event Category' ? setCreate(true) : history.push('addevent')
          }}
      />}
      <Divider />
      <Grid style={{ backgroundColor: '#FFF', paddingTop: 20, paddingLeft: 20 }}>
        <Grid container className={classes.buttonContainer}>
          <Button
            variant="contained"
            disableElevation
            onClick={e => { settab("Event") }}
            className={tab === "Event" ? classes.activeButton : ""}
          >Event</Button>
          <Button
              variant="contained"
              disableElevation
              onClick={e => settab("Deleted Events")}
              className={tab === "Deleted Events" ? classes.activeButton : ""}
          > Deleted Events </Button>
          <Button
            variant="contained"
            disableElevation
            onClick={e => settab("Event Category")}
            className={tab === "Event Category" ? classes.activeButton : ""}
          > Event Category </Button>


        </Grid>
      </Grid>
      <Divider />
      {tab === "Event Category" && (<EventCategory search={search} onEdit={category => setcategoryUpdate(category)} change={create} />)}
      {tab === "Deleted Events" && (<Events search={search} isDeleted={true}/>)}
      {tab === "Event" && (<Events search={search} />)}
      <EventCategoryAdd open={create || categoryUpdate} update={categoryUpdate} onClose={() => { setCreate(false); setcategoryUpdate(null) }} />
    </Paper>

  );
}

export default withRouter(Event);



const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: "#F2F2F2",
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  buttonContainer: {
    backgroundColor: '#FFF',
    width: '50%',
    '&>*': {
      width: '30%',
      borderRadius: 0,
      textTransform: 'none'
    },

  },

  activeButton: {
    border: '2px solid #f47824',
    backgroundColor: '#f47824',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#f47824'
    }
  }
}));
