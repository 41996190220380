import { useContext, useEffect, useState } from "react";
import {BounceLoader} from "react-spinners";
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import TableHeader from "../../components/table/TableHeader";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import TablePagination from "@material-ui/core/TablePagination";
import ReviewAdd from "../RateReview/ReviewAdd";
import DeleteDialog from "../../components/dialog/DeleteDialog";
import {css} from "@emotion/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import userContext from "../../context/userContext";
import {BASE_API_URL} from "../../constant";


const StyledTableCell = withStyles(() => ({
    head: {
        backgroundColor: "#FFF",
        color: "#787878",
    },
    body: {
        fontSize: 12,
        padding: '0px 16px',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        "&:nth-of-type(odd)": {
            backgroundColor: '#f8f8f8',
        },
    },
}))(TableRow);


function EnhancedTableHead() {
    return (
        <TableHead >
            <StyledTableRow >

                <StyledTableCell>S/N</StyledTableCell>
                <StyledTableCell>Review</StyledTableCell>
                <StyledTableCell>City</StyledTableCell>
                <StyledTableCell>Company</StyledTableCell>
                <StyledTableCell> </StyledTableCell>
            </StyledTableRow>
        </TableHead>
    );
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function List() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [forDelete, setForDelete] = useState(null);
    const [update, setupdate] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const classes = useStyles();




    const getFilters = async () => {
        let options = {
            method: "GET",
            headers: {
                Authorization: userData.token,
            },
        };
        const response = await fetch(
            `${BASE_API_URL}rate-review?search=${search}&skip=${page * rowsPerPage}&limit=${rowsPerPage}`,
            options
        );
        const data = response.json();
        data
            .then((data) => {
                if (response.status === 401) {
                    localStorage.removeItem("auth-token");
                    setUserData({
                        token: "",
                    });
                }

                setRows(data.data.result);
                setcount(data.data.totalFilter);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleConfirmDelete = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer "+ userData.token);

        var raw = JSON.stringify({

            "isDeleted": true,
            "filterId" :  forDelete._id

        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        const doDelete = await fetch(`${BASE_API_URL}rate-review`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log({result})
                alert('Review Added')
                // handleOnClose()
                getFilters()
                setForDelete(null);

            })
            .catch(error => console.log('error', error));


    }






    useEffect(() => {
        getFilters();
        // eslint-disable-next-line
    }, [search, rowsPerPage, page, create, update]);

    return (
        <>
            {loading ? (
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <BounceLoader
                            color={" #f47824"}
                            loading={loading}
                            css={override}
                            size={50}
                        />
                    </div>
                ) :
                (
                    <div className={classes.root} style={{ margin: 10 }}>

                        <Paper className={classes.paper}>
                            <TableHeader
                                lable="Rate & Review List"
                                btnName="Create Review"
                                value={search}
                                onChange={e => { setsearch(e.target.value); setPage(0) }}
                                onButtonClicked={e => setcreate(true)}
                            />
                            <div
                                style={{
                                    border: "0.25px solid rgba(0, 0, 0, 0.1)",
                                    width: "100%",

                                }}
                            />
                            <TableContainer>
                                <Table className={classes.table}>
                                    <EnhancedTableHead />
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            return (
                                                <StyledTableRow

                                                    key={row.name}>

                                                    <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{page * rowsPerPage + index + 1}</StyledTableCell>
                                                    <StyledTableCell align='left'>
                                                        <span style={{fontWeight:'bold'}}>{row.userName}:</span> <br/>
                                                        <span style={{fontStyle:'italic'}}>{row.reviewText}</span> <br/>
                                                        <span className={`badge ${row?.isApproved ? 'badge-success' : 'badge-danger'}`}>{row.isApproved ? 'Approved' : 'Not Approved'}</span>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {row.city}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {row.company}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align='right'
                                                    >
                                                        <Grid container justify="flex-end">
                                                            <IconButton onClick={e => setupdate(row)}>
                                                                <EditOutlinedIcon />
                                                            </IconButton>
                                                            <IconButton onClick={e => setForDelete(row)}>
                                                                <DeleteOutlinedIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25]}
                                    component="div"
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Paper>
                        <ReviewAdd
                            open={create || update}
                            update={update}
                            onClick={e => { setcreate(false); setupdate(null); getFilters()}}
                        />
                        <DeleteDialog open={forDelete} what="Review" onOkClick={handleConfirmDelete} onCancelClick={e => setForDelete(null)} />
                    </div>
                )
            }
        </>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        padding: 30
    },
    paper: {
        backgroundColor: "#F2F2F2",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));


