import React, { useContext, useState, useEffect } from 'react';
import BackDrop from '@material-ui/core/Backdrop';
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { BootstrapInput } from '../../components/small/Select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import userContext from "../../context/userContext";
import { getURL } from "../../util";
import searchContext from "../../context/searchContext";
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { BASE_API_URL } from '../../constant';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Snackbar from '@material-ui/core/Snackbar';
import AddCircleTwoTone from '@material-ui/icons/AddCircleTwoTone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Alert from '@material-ui/lab/Alert';
import EditText from '../../components/small/EditText';
import CancelIcon from '@material-ui/icons/Cancel';


const FeatureAdd = ({ classes, onClose, update }) => {
    //const update = location.state;
    
    const { userData, setUserData } = useContext(userContext);
    const { search } = useContext(searchContext);
    //const [featureName, setname] = useState('');
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [iscategory, setIscategory] = useState(0);
    const [open, setOpen] = useState(false);
    const [error, seterror] = useState({ open: false, message: 'Error' });
    const [featureAdd, setFeatureAdd] = useState({
        name: '',
        primary: []
    });
    const checkValidation = () => {
        const { name, image } = featureAdd
        if (name === '') {
            seterror({ open: true, message: 'Name Should not Be Empty' })
            return false
        }
        
        return true
    }


    const getIdPrimary = () => {
        const arr = [];
        for (let service of services) {
            if (service.isPrimary === true) {
                arr.push(service._id )
            }
        }
        return arr
    }


    const getServices = async () => {
        const options = {
            method: "GET",
            headers: {
                Authorization: userData.token,
            },
        };
        const response = await fetch(
            getURL(`${BASE_API_URL}service-types`, search),
            options
        );
        const data = response.json();
        data.then((data) => {
            if (response.status === 401) {
                localStorage.removeItem("auth-token");
                setUserData({
                    token: "",
                });
            }
            if (data.data) {
                //add multiple  keys for easily filter

                if (update) {
                    const withIsPrimaryData = [];
                    for (let resData of data.data.result) {
                        const foundInPrimary = update.serviceTypes.find(x => x._id === resData._id);

                        if (foundInPrimary) {
                            
                            withIsPrimaryData.push({
                                ...resData,
                                isPrimary: true,
                                isRequired: false,
                               
                            });
                        } else {
                            withIsPrimaryData.push({
                                ...resData,
                                isPrimary: false,
                                isRequired: false,
                                
                            });
                        }

                    }
                    setServices(withIsPrimaryData);

                } else {
                    const withIsPrimaryData = data.data.result.map((ser) => {   
                        return {
                            ...ser,
                            isPrimary: false,
                            isRequired: false   
                        }
                    });
                    setServices(withIsPrimaryData);
                }

            }
            setLoading(false);
        })
            .catch((error) => console.log(error));
    };


    // const handleAddFeature = async () => {
    //     if (checkValidation()) {
    //         const formdata = new FormData();
            
    //         if (update) {
    //             formdata.append("featureId", update._id);
    //         }
    //         formdata.append("featureName", featureAdd.name);
    //         formdata.append("serviceTypes", JSON.stringify(getIdPrimary()));
    //         formdata.append("isCategory", iscategory);
    //         let options = {
    //             method: "POST",
    //             headers: {
    //                 Authorization: userData.token,
    //             },
    //             body: JSON.stringify(formdata),
    //         };
    //         console.log(options);
    //         try {
    //             const response = await fetch(
    //                 `${BASE_API_URL}features`,
    //                 options
    //             );
    //             const res = await response.json();
    //             if(response.status==409){
    //                 seterror('Already exists')
    //             }else{
    //                 onClose()
    //             }

    //         } catch (e) {
    //             alert("Error" + error)
    //         }
    //     }
    // };

    const handleAddFeature = async () => {
        if (checkValidation()) {
        
            var raw = {
                "featureName": featureAdd.name,
                "serviceTypes": getIdPrimary(),
                "isCategory": iscategory,
            };

            if (update) {
                raw.featureId= update._id;
            }

            let options = {
                method: "POST",
                headers: {
                    Authorization: userData.token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(raw),
            };

            try {
                const response = await fetch(
                    `${BASE_API_URL}features`,
                    options
                );
                const res = await response.json();
                if(response.status==409){
                    seterror('Already exists')
                }else{
                    onClose()
                }

            } catch (e) {
                alert("Error" + error)
            }
        }
    };


    useEffect(() => {
        // if update autoFill All Forms
        //alert(update.featureName);
        const serv = [...services];
        if (update) {
        setFeatureAdd({ ...featureAdd, name: update.featureName });
        }
        //alert(featureAdd.name);
        getServices();
    }, [])


    const handleSelectPrimary = (i) => {
        const services1 = [...services];
        services1[i].isPrimary = !services1[i].isPrimary
        setServices(services1);
    }

    const handleIsRequired = (i) => {
        const services1 = [...services];
        services1[i].isRequired = !services1[i].isRequired
        setServices(services1);
    }

    const handleRemoveService = i => {
        const services1 = [...services];
        services1[i].isPrimary = false
        setServices(services1);
    }

    return (
        <BackDrop
            open={true}
            className={classes.backdrop}
        >
            <Paper variant="outlined" style={{ width: '30%', padding: '24px', position: 'relative' }}>
                <Typography style={{ fontWeight: 'bolder' }}>{update ? "Update" : "Add New"} Feature</Typography>
                <Grid container style={{ maxHeight: '80vh', overflow: 'auto' }}>
                    <Grid item xs={12} className={classes.line}>
                        <EditText value={featureAdd.name} onChange={e => { setFeatureAdd({ ...featureAdd, name: e.target.value }) }} rootStyle={{ width: '100%' }} label="Feature Name" />
                        <div className="form-group">
                        <label for="iscategory">Is Category</label>
                            <select name="iscategory" className="form-control" value={iscategory} onChange={(e)=>{ setIscategory(e.target.value)}}>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Primary Services</Typography>
                            <AddCircleTwoTone
                                onClick={e => setOpen(true)}
                                style={{ color: "#f47824", cursor: "pointer" }}
                            />
                        </Grid>

                        <Grid>
                            {services.map((serv, i) => (
                                !serv.isAddOn && serv.isPrimary && (
                                    <Button
                                        key={i}
                                        variant="contained"
                                        disableElevation
                                        color="primary"
                                        onClick={e => { handleIsRequired(i) }}
                                        style={{ padding: '4px 6px', margin: '7px', textTransform: 'capitalize', backgroundColor: '#8489b2' }}
                                        startIcon={serv.isRequired ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                                    >
                                        <CancelIcon onClick={e => handleRemoveService(i)} fontSize="small" className={classes.cancelIcon} />
                                        {serv.name}
                                    </Button>
                                )
                            ))}

                        </Grid>
                    </Grid>
                    
                </Grid>

                <Button
                    onClick={handleAddFeature}
                    disableElevation
                    style={{ backgroundColor: '#8489b2', color: 'white', textTransform: 'none', width: '100%' }}
                    variant="contained">{update ? "Update" : "Create"} Feature</Button>
                <Link to="/features">
                    <IconButton className={classes.floatIcon}>
                        <ClearIcon fontSize="small" style={{ color: "#515151" }} />
                    </IconButton>
                </Link>

            </Paper>

            <BackDrop open={open} className={classes.backdrop2}>
                <Paper variant="outlined" className={classes.line} style={{ width: '60%', padding: '24px', position: 'relative' }}>
                    <Typography>Categories to choose</Typography>
                    {services.map((ser, i) => (
                        <Chip
                            key={i}
                            color={ser.isPrimary ? "primary" : "default"}
                            style={{ cursor: 'pointer' }}
                            onClick={e => handleSelectPrimary(i)}
                            label={ser.name} />
                    ))}
                    <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={e => setOpen(false)}
                        >Ok</Button>
                    </Grid>
                </Paper>
            </BackDrop>

            <Snackbar open={error.open} autoHideDuration={3000} onClose={e => seterror({ open: false, message: 'Error' })}>
                <Alert severity="error">{error.message}</Alert>
            </Snackbar>
        </BackDrop>
    )
}

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    backdrop2: {
        zIndex: theme.zIndex.drawer + 2,
    },
    line: {
        '&>*': {
            marginTop: '1rem'
        }
    },
    floatIcon: {
        position: 'absolute',
        right: -11,
        top: -11,
        color: "#515151",
        width: '1.4rem',
        backgroundColor: 'white',
        height: '1.4rem', padding: 4
    },
    cancelIcon: {
        position: 'absolute',
        right: -10,
        top: -10,
        color: 'lightgrey'
    }
});


export default withRouter(withStyles(styles)(FeatureAdd));