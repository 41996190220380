import React,{ useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Button,
  lighten,
  Typography
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {BASE_API_URL,VENDOR_API_BASE_URL} from '../../constant';
import DatePicker from "react-datepicker";
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
import './../../styles/cssformui.css';

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell style={{ fontWeight: 'bold'}}>Sr. No</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>EventID</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Date</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Booking Date</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Type</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}} align="right">Total amt <br/>in AED</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}} align="right">Total Paid amt <br/>in AED</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}} align="right">Tax (5%) <br/>in AED </StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}} align="right">Vendor amt <br/>in AED</StyledTableCell>        
        <StyledTableCell style={{ fontWeight: 'bold'}} align="right">Admin amt (10%) <br/>in AED</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}></StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

  const EnhancedTableToolbar = ({ value, onChange,fromDate,toDate,setToDate,setStartDate,changeval,setChange}) => {
  const classes = useToolbarStyles();
  const toReadable = (Unix="") =>{
		return Unix === "" ? moment().format("YYYY-MM-DD") : moment.unix(Unix/1000).format("YYYY-MM-DD");
	}
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Payment Distribution
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            startAdornment={<SearchIcon />}
            value={value}
            onChange={onChange}
            placeholder="Search by eventId"
            style={{ marginRight: '10px' }}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
            labelWidth={0}
          />
        <div style={{ paddingRight:'10px' }}>
        <DatePicker className="form-control"
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          showMonthDropdown
          placeholderText="Start Date"
          onChange={(date) => {setStartDate(moment(date).format("x")); setChange(!changeval)}}
          selected = { new Date(toReadable(fromDate))}
          />
        </div>
        <div>
          <DatePicker className="form-control"
          dateFormat="dd/MM/yyyy"
          showYearDropdown
          showMonthDropdown
          placeholderText="End Date"
          onChange={(date) => {setToDate(date); setChange(!changeval)}}
          selected = { new Date(toReadable(toDate))}
          />
        </div>
      </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function PaymentDistribution({ classes }) {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setStartDate] = useState(moment().subtract(30, "days").format("x"));
    const [toDate, setToDate] = useState(new Date());
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [TotalPaidAmount, setTotalPaidAmount] = React.useState(0);
    const [TotalEventAmount, setTotalEventAmount] = React.useState(0);
    const [totalTax, setTotalTax] = React.useState(0);
    const [adminAmount, setAdminAmount] = React.useState(0);
    const [vendorAmount, setVendorAmount] = React.useState(0);
    const [changeval, setChange] = React.useState(true);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const getPaymentDistributionData = async () => {
    // let tdate = new Date();
    // tdate.setDate(tdate.getDate() - 30);
    // setStartDate(tdate)
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
    `${BASE_API_URL}get-payment-distribution-report?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}&startDate=${fromDate}&EndDate=${moment(toDate).format("x")}`, options);
    const data = response.json(); 
    
    data.then((data) => { 
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }

      setRows(data.data.result);
      setcount(data.data.eventCount);
      setTotalPaidAmount(data.data.TotalPaidAmount)
      setTotalEventAmount(data.data.TotalEventAmount)
      setTotalTax(data.data.totalTax)
      setAdminAmount(data.data.adminAmount)
      setVendorAmount(data.data.vendorAmount)
      setLoading(false);
    })
    .catch((error) => console.log(error));
  };

  useEffect(() => {
    getPaymentDistributionData();
    // eslint-disable-next-line
  }, [search, create,rowsPerPage,page,changeval]);

  return (
    <>
     {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
    <Paper style={{ margin: 10 }}>
    <EnhancedTableToolbar value={search} onChange={e => {setsearch(e.target.value); setPage(0)}} fromDate={fromDate} toDate={toDate}  setStartDate={setStartDate} setToDate={setToDate} changeval={changeval} setChange={setChange} />
    <Divider />
    <TableContainer>
                <Table >
                  <EnhancedTableHead />
                  <TableBody>
                    {rows.map((row,index)=>{
                      return (<StyledTableRow key={index}>
                      <StyledTableCell align='left'>{page*rowsPerPage+index+1}</StyledTableCell> 
                      <StyledTableCell align='left'>{row.sysEventId}</StyledTableCell> 
                      <StyledTableCell align='left'>{moment(parseInt(row.eventDate)).format('MMM DD YYYY')}</StyledTableCell> 
                      <StyledTableCell align='left'>{moment(row.createdAt).format('MMM DD YYYY')}</StyledTableCell> 
                      <StyledTableCell align='left'><Link to={`/booked-event-details/${row._id}`} href="_blank">{row.eventName}</Link></StyledTableCell>
                      <StyledTableCell align='left'>{row.eventTypes['name']}</StyledTableCell>
                      <StyledTableCell align='right'>{row.packagePrice.toFixed(2)}</StyledTableCell> 
                      <StyledTableCell align='right'>{row.total.toFixed(2)}</StyledTableCell> 
                      <StyledTableCell align='right'>{row.eventTax.toFixed(2)}</StyledTableCell>{/* (total*5/100) */ }
                      <StyledTableCell align='right'>{row.eventVendorAmount.toFixed(2)}</StyledTableCell>{/* (total-tax-admin) */}
                      <StyledTableCell align='right'>{row.eventAdminAmount.toFixed(2)}</StyledTableCell>{/* (total-tax)*10/100 */ }
                    </StyledTableRow> );
                    })}
                        
                        
                        <StyledTableRow>
                          <StyledTableCell align='left'></StyledTableCell>
                          <StyledTableCell align='left'></StyledTableCell>  
                          <StyledTableCell align='left'></StyledTableCell> 
                          <StyledTableCell align='left'></StyledTableCell> 
                          <StyledTableCell align='left'></StyledTableCell>
                          <StyledTableCell style={{ fontWeight: 'bold'}} align='left'>Sum (AED)</StyledTableCell> 
                          <StyledTableCell style={{ fontWeight: 'bold'}} align='right'>{TotalEventAmount.toFixed(2)}</StyledTableCell>
                          <StyledTableCell style={{ fontWeight: 'bold'}} align='right'>{TotalPaidAmount.toFixed(2)}</StyledTableCell>
                          <StyledTableCell style={{ fontWeight: 'bold'}} align='right'>{totalTax.toFixed(2)}</StyledTableCell>
                          <StyledTableCell style={{ fontWeight: 'bold'}} align='right'>{vendorAmount.toFixed(2)}</StyledTableCell>
                          <StyledTableCell style={{ fontWeight: 'bold'}} align='right'>{adminAmount.toFixed(2)}</StyledTableCell>
                        </StyledTableRow>                   
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
    </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})