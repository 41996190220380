import React,{ useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Button,
  lighten
} from "@material-ui/core";
import {Link,useParams} from 'react-router-dom'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import {BASE_API_URL} from '../../constant';
import { css } from "@emotion/core";
import axios from "axios";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
import './../../styles/cssformui.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell style={{ fontWeight: 'bold'}}>Booking ID</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Payment date</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Payment Amount</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Coupon Applied</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Coupon Code</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Coupon Cost</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Total Amount</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Payment Type</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({eventName, eventId, value, onChange, onButtonClicked }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Payment Log ({eventId})
      </Typography>
        <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
            <h4 style={{fontWeight: "600"}}>{eventName}</h4>
        </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function PaymentLog({ classes }) {
  const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [eventId,seteventId] = useState();
    const [eventName,seteventName] = useState();
    const [paymentDone,setpaymentDone] = useState(false);
    const params = useParams();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getPaymentLog = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(`${BASE_API_URL}payment-log?userEventId=${params.eventId}`,options);
    const data = response.json(); 
    
    data.then((data) => {  
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      seteventId(data.data.result[0].userEventSysId);
      seteventName(data.data.result[0].userEventName)
      setRows(data.data.result);
      setcount(data.data.result.length);
      setLoading(false);
    })
    .catch((error) => console.log(error));
  };

  useEffect(() => { 
    getPaymentLog();
    // eslint-disable-next-line
  }, [search, create,rowsPerPage, page, paymentDone ]);

  const claimPatment = (bookingId,userId) => {
    confirmAlert({
        title: '',
        message: 'Do you want to claim payment',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { 
                setLoading(true);
                const header = {
                    Authorization: userData.token,
                }
                axios.post(`${BASE_API_URL}claim-payment`,{bookingId:bookingId,userId:userId},{headers: header})
                .then(res=>{
                  console.log(res);
                    setLoading(false);
                    setpaymentDone(true);
                })
                .catch(err=>{
                   console.log(err);
                   setLoading(false);
                    setpaymentDone(true);
                  })
            }
          },
          {
            label: 'Cancel',
            onClick: () => {}
          }
        ]
      });
  }
  return (
    <>
     {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
    <Paper style={{ margin: 10 }}>
    <EnhancedTableToolbar eventId={eventId} eventName={eventName} onButtonClicked={e => setcreate(true)} value={search} onChange={e => {setsearch(e.target.value); setPage(0)}} />
    <Divider />
    <TableContainer>
                <Table >
                  <EnhancedTableHead />

                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={row.name}>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> {row._id}</StyledTableCell>
                          <StyledTableCell align='left'>{moment(row.createdAt).format('MMM DD YYYY')}</StyledTableCell> 
                          <StyledTableCell align='left'>{(row.intialPaymentPaid).toFixed(2)}</StyledTableCell> 
                          <StyledTableCell align='left'>{row.couponApplied?'Yes':'No'}</StyledTableCell> 
                          <StyledTableCell align='left'>{row.couponCode}</StyledTableCell> 
                          <StyledTableCell align='left'>{row.couponApplied?(row.totalPrice-row.intialPaymentPaid).toFixed(2):''}</StyledTableCell> 
                          <StyledTableCell align='left'>{(row.totalPrice).toFixed(2)}</StyledTableCell> 
                          <StyledTableCell align='left'>{row.PaymentType==1?'Half Payment':'Full Payment'}</StyledTableCell> 
                          <StyledTableCell align='left'>{row.status==1?<Link onClick={()=>claimPatment(row._id,row.userId)}>Claim Payment</Link>:'Paid'}</StyledTableCell>
                          
                        </StyledTableRow>
                      );
                    })}
                    
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
    </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})