import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import {BASE_API_URL} from '../../constant';
import userContext from "../../context/userContext";

const OwnerDetails = () => {
   const history = useHistory(); 
   const { userData, setUserData } = useContext(userContext); 
   let curdisplay="none";
   const [cDisplay, setDisplay] = useState(curdisplay);
   const [startDate, setStartDate] = useState(new Date());
   const {email} = useParams();
   const [user, setUser] = useState({
      venEmail:email,
      uaeResidence:"",
      emiratesIdNumber:"",
      uid:"",
      email:"",
      phone:"",
      mobile:"",
      gender:"",
      firstName:"",
      middleName:"",
      lastName:"",
      passportNo:"",
      passIssueDate:startDate,
      passExpiryDate:startDate,
      placeOfIssue:"",
      passCountry:"",
      dob:startDate,
      birthCountry:"",
      currentNationality:"",
      previousNationality:"",
      addressLine1:"",
      addressLine2:"",
      postBox:"",
      city:"",
      state:"",
      country:"",
      vendorId:"",
    })

    let name, value;

   const handleInputs = (e) => {
      console.log(e);
      name=e.target.name;
      value=e.target.value;

      setUser({...user, [name]:value}); 
   }

   const displayuid = (e) => {
      name=e.target.name;
      value=e.target.value;
      setUser({...user, [name]:value}); 
      //alert(`${value}`)
      //alert('uaeResidence value:'+ user.uaeResidence)
      if(user.uaeResidence=="YES")
      {
         //alert("in yes");
        curdisplay="none";
        setDisplay(curdisplay);           
      }
      else
      {
         //alert("in no");
         curdisplay="flex";
        setDisplay(curdisplay); 
      }          
    }

    const PostData = async (e) => {
      e.preventDefault();
      const {venEmail,
      uaeResidence,
      emiratesIdNumber,
      uid,
      email,
      phone,
      mobile,
      gender,
      firstName,
      middleName,
      lastName,
      passportNo,
      passIssueDate,
      passExpiryDate,
      placeOfIssue,
      passCountry,
      dob,
      birthCountry,
      currentNationality,
      previousNationality,
      addressLine1,
      addressLine2,
      postBox,
      city,
      state,
      country,
      vendorId
      } = user;
   

   const res = await fetch(`${BASE_API_URL}company-owners-entry`,{
      method:"POST",
      headers:{
              "Content-Type":"application/json",
              "authorization":userData.token
      },
      body:JSON.stringify({
         venEmail,
         uaeResidence,
         emiratesIdNumber,
         uid,
         email,
         phone,
         mobile,
         gender,
         firstName,
         middleName,
         lastName,
         passportNo,
         passIssueDate,
         passExpiryDate,
         placeOfIssue,
         passCountry,
         dob,
         birthCountry,
         currentNationality,
         previousNationality,
         addressLine1,
         addressLine2,
         postBox,
         city,
         state,
         country,
         vendorId
      })          
  });

  const data=await res.json();

  if (res.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
  }
  
  if(!data)
  {
      window.alert("Invalid Registration");
      console.log("Invalid Registration");
  }else{
      window.alert("Registration Successfull");
      console.log("Registration Successfull");

      history.push("/owner-details/"+user.venEmail);
  }
}


    return ( 
      <div className="container">
      <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="border-bottom pb-2 ">
                  <h2 className="page-title my-3">Owner Details</h2>
                  <div className="border-outer mb-4" id="partners">
                      <div className="border-top px-4  py-3 ">
                          <div className="row">
                              <div className="col-md-12 col-sm-12 col-xs-12">
                              <hidden id="vendorId" name="vendorId" value={user.vendorId}
                                       />
                                  <div className="form-group">
                                      <label for="uaeResidence">Is residence of UAE ?</label><br/>
                                      <input type="radio" id="uaeResidence" name="uaeResidence" value="YES" 
                                      onChange={displayuid} />
  									            <label for="html">Yes</label>
  									            <input type="radio" id="uaeResidence" name="uaeResidence" value="NO" 
                                       onChange={displayuid} />
  									    <label for="css">No</label>
                                  </div>
                              </div>
                          </div>
                          
                          <div className="row" style={{ display : cDisplay }}>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="emiratesIdNumber">Emirates ID Number</label>
                                  <input type="text" className="form-control" id="emiratesIdNumber"
                                   name="emiratesIdNumber" placeholder=""  
                                   value={user.emiratesIdNumber}
                                   onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="uid">UID</label>
                                  <input type="text" className="form-control" id="uid" name="uid" placeholder=""   
                                   value={user.uid}
                                   onChange={handleInputs}/>
                               </div>
                            </div>
                         </div>
                         <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="email">Email</label>
                                  <input type="email" className="form-control" id="email" name="email" 
                                  placeholder=""   
                                  value={user.email}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div class="col-md-4 col-sm-12 col-xs-12">
                               <div class="form-group">
                                  <label for="phone">Telephone</label>
                                  <input type="text" className="form-control" id="phone" name="phone" 
                                  placeholder=""   
                                  value={user.phone}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div class="col-md-4 col-sm-12 col-xs-12">
                               <div class="form-group">
                                  <label for="mobile">Mobile</label>
                                  <input type="text" className="form-control" id="mobile" name="mobile" 
                                  placeholder="+971"   
                                  value={user.mobile}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                         </div>
                         <div className="row">
                            
                            <div className="col-md-12 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="gender">Gender</label>
                                  <select name="gender" id="gender" 
                                  className="form-control" 
                                  value={user.gender} 
                                  onChange={handleInputs}>
                                      <option value="MALE">Male</option>
                                      <option value="FEMALE">Female</option>
                                      <option value="OTHERS">Other</option>
                                  </select>
                               </div>
                            </div>
                         </div>
                         <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="firstName">First Name</label>
                                  <input type="text" className="form-control" id="firstName" 
                                  name="firstName" placeholder=""   
                                  value={user.firstName}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="middleName">Middle Name</label>
                                  <input type="text" className="form-control" id="middleName" 
                                  name="middleName" placeholder=""   
                                  value={user.middleName}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="lastName">Last Name</label>
                                  <input type="text" className="form-control" id="lastName" 
                                  name="lastName" placeholder=""   
                                  value={user.lastName}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                         </div>
       
                         <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="passportNo">Passport Number</label>
                                  <input type="text" className="form-control" id="passportNo" 
                                  name="passportNo" placeholder=""   
                                  value={user.passportNo}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="passIssueDate">Passport Issue Date</label>
                                  <DatePicker className="form-control" id="passIssueDate" 
                                  name="passIssueDate"   
                                  selected={startDate} 
                                  onChange={(date) => setStartDate(date)}
                                  dateFormat='yyyy-dd-MM' 
                                  value={user.passIssueDate}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="passExpiryDate">Passport Expiry Date</label>
                                  <DatePicker className="form-control" id="passExpiryDate" 
                                  name="passExpiryDate"  
                                  selected={startDate}   
                                  onChange={(date) => setStartDate(date)} 
                                  dateFormat='yyyy-dd-MM'  
                                  value={user.passExpiryDate}/>
                               </div>
                            </div>
                         </div>
                         <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="placeOfIssue">Place Of Issue</label>
                                  <input type="text" className="form-control" id="placeOfIssue" 
                                  name="placeOfIssue" placeholder=""   
                                  value={user.placeOfIssue}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="passCountry">Passport Country of Issue </label>
                                  <select name="passCountry" id="passCountry" className="form-control"   
                                   value={user.passCountry}
                                   onChange={handleInputs}>
                                      <option value="abu-dhabi">Abu Dhabi</option>
                                      <option value="saab">Ajman</option>
                                      <option value="mercedes">Fujairah</option>
                                      <option value="saab">Dubai</option>
                                      <option value="abu-dhabi">Ras Al Khaimah</option>
                                      <option value="mercedes">Sharjah</option>
                                      <option value="mercedes">Umm Al-Quwain</option>
                                  </select>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="dob">Date of Birth</label>
                                  <DatePicker className="form-control" id="dob" 
                                  name="dob"
                                  selected={startDate}      
                                  onChange={(date) => setStartDate(date)}
                                  dateFormat='yyyy-dd-MM' 
                                  value={user.dob}/>
                               </div>
                            </div>
                         </div>
                         <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="birthCountry">Country of Birth</label>
                                  <select name="birthCountry" id="birthCountry" className="form-control"   
                                   value={user.birthCountry}
                                   onChange={handleInputs}>
                                      <option value="abu-dhabi">Abu Dhabi</option>
                                      <option value="saab">Ajman</option>
                                      <option value="mercedes">Fujairah</option>
                                      <option value="saab">Dubai</option>
                                      <option value="abu-dhabi">Ras Al Khaimah</option>
                                      <option value="mercedes">Sharjah</option>
                                      <option value="mercedes">Umm Al-Quwain</option>
                                  </select>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="currentNationality">Current Nationality</label>
                                  <input type="text" className="form-control" id="currentNationality" 
                                  name="currentNationality" placeholder=""   
                                  value={user.currentNationality}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="previousNationality">Previous Nationality</label>
                                  <input type="text" className="form-control" id="previousNationality" 
                                  name="previousNationality" placeholder=""   
                                  value={user.previousNationality}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                         </div>
                         <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="addressLine1">Address Line 1</label>
                                  <input type="text" className="form-control" id="addressLine1" 
                                  name="addressLine1"   
                                  value={user.addressLine1}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="addressLine2">Address Line 2</label>
                                  <input type="text" className="form-control" id="addressLine2" 
                                  name="addressLine2"   
                                  value={user.addressLine2}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="postBox">Post Box</label>
                                  <input type="text" className="form-control" id="postbox" name="postBox"   
                                  value={user.postBox}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                         </div>
                         <div className="row"> 
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="city">City</label>
                                  <input type="text" className="form-control" id="city" name="city" 
                                  placeholder=""    
                                  value={user.city}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="state">State / Province</label>
                                  <input type="text" className="form-control" id="state" name="state"    
                                  value={user.state}
                                  onChange={handleInputs}/>
                               </div>
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12">
                               <div className="form-group">
                                  <label for="country">Country</label>
                                  <select name="country" id="country" class="form-control"    
                                  value={user.country}
                                  onChange={handleInputs}>
                                      <option value="abu-dhabi">Abu Dhabi</option>
                                      <option value="saab">Ajman</option>
                                      <option value="mercedes">Fujairah</option>
                                      <option value="saab">Dubai</option>
                                      <option value="abu-dhabi">Ras Al Khaimah</option>
                                      <option value="mercedes">Sharjah</option>
                                      <option value="mercedes">Umm Al-Quwain</option>
                                  </select>
                               </div>
                            </div>
                         </div>
                      </div>                           
                   </div>
                   <Button 
                           color="primary" 
                            disableElevation 
                            variant="contained" 
                            onClick={PostData}>Save</Button>
                      </div>
              </div>
          </div>
      </div>
        ); 
    }

export default OwnerDetails;