import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import { BASE_API_URL } from '../../constant';
import userContext from "../../context/userContext";
import { uploadImageToS3 } from '../../aws/awsUploader';
import moment from "moment";
import axios from "axios";

const VendorRegistration = () => {
    const history = useHistory();
    const { userData, setUserData } = useContext(userContext);
    const [errMessage, setErrMessage] = useState('');
    const [locations, setLocation] = useState([]);
    const [services, setServices] = useState([]);
    const [user, setUser] = useState({
        aboutyou: "",
        companyName: "",
        email: "",
        phoneNumber: "",
        mobile: "",
        contactPerson: "",
        contactEmail: "",
        contactMobile:"",
        specilization: "",
        whereYourBusiness: "",
        tradeExpiry: "",
        estCardExpiry: "",
        address: "",
        planDetails: "",
        planExpiry: "",
        agreementFileUrl: "",
        bankName: "",
        accountName: "",
        ibanNo: "",
        accountNumber: "",
        swift: "",
        branchAddress: "",
        noOfPartners: "",
        userType: "VENDOR",
        password: "",
        userName:""
    });

    const setTradeExpiry = (e) => { 
        setUser({...user, tradeExpiry:moment.unix(e).format("X")}); 
    }
    const setPlanExpiry = (e) => { 
        setUser({...user, planExpiry:moment.unix(e).format("X")}); 
    }
    const setEstCardExpiry = (e) => { 
        setUser({...user, estCardExpiry:moment.unix(e).format("X")}); 
    }
	const toReadable = (Unix="") =>{
		return Unix === "" ? moment().format("YYYY-MM-DD") : moment.unix(Unix/1000).format("YYYY-MM-DD");
	}

    useEffect(() => {
        getLocations();
        getServices();
        // eslint-disable-next-line
    }, []);


    let name, value;

    const handleInputs = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUser({ ...user, [name]: value });
    }

    const fileChange = evt => { 
        uploadImageToS3(evt.target.files[0],'vendor_document')
            .then(res => { setUser({ ...user, agreementFileUrl: res.location }) })
            .catch(err => { console.log(err) })
    }

    const handleCalendarClose = () => {
        //console.log('pragya :'+startDate);
        //const NewDate = moment(startDate).format('YYYY-MM-DD');
        //console.log('new value1 :' + NewDate);
        //setUser({planExpiry:moment(NewDate).format('YYYY-MM-DD')});   
        //user.planExpiry=moment(user.planExpiry).format('YYYY-MM-DD');
        //console.log('new value2 :'+user.planExpiry);
    }

    //Get Locations
    const getLocations = () => {
        axios.get(`${BASE_API_URL}get-locations`)
            .then(res => {
                setLocation(res.data.data);
            })
            .catch(err => { console.log(err) })
    }

    //get services
    const getServices = () => {
        const headers = {
            "Content-Type": "application/json",
            "authorization": userData.token
        }
        axios.get(`${BASE_API_URL}service-types`, { headers })
            .then(res => {
                setServices(res.data.data.result);
            })
            .catch(err => { console.log(err) })
    }

    const PostData = async (e) => { 
        e.preventDefault();
        
        const { 
            aboutyou,
            companyName,
            email,
            phoneNumber,
            mobile,
            contactPerson,
            contactEmail,
            contactMobile,
            specilization,
            whereYourBusiness,
            tradeExpiry,
            estCardExpiry,
            address,
            planDetails,
            planExpiry,
            agreementFileUrl,
            bankName,
            accountName,
            ibanNo,
            accountNumber,
            swift,
            branchAddress,
            noOfPartners,
            userType,
            userName,
            password } = user;
            //console.log(user); return false;
        const res = await fetch(`${BASE_API_URL}vendor-details-entry`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": userData.token
            },
            body: JSON.stringify({
                aboutyou,
                companyName,
                email,
                phoneNumber,
                mobile,
                contactPerson,
                contactEmail,
                contactMobile,
                specilization,
                whereYourBusiness,
                tradeExpiry,
                estCardExpiry,
                address,
                planDetails,
                planExpiry,
                agreementFileUrl,
                bankName,
                accountName,
                ibanNo,
                accountNumber,
                swift,
                branchAddress,
                noOfPartners,
                userType,
                password,
                userName
            })
        });

        const data = await res.json();

        if (res.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
                token: "",
            });
        }
        else if (res.status === 400 || res.status === 409) {
            setErrMessage(data.message)
        }
        else {
            window.alert("vendor Registration Successfull");
            history.push("/vendors-data");
            // if (user.noOfPartners == "0" && user.noOfPartners == "") {
            //     history.push("/vendors-data");
            // }
            // else {
            //     history.push("/owner-details/" + user.email);
            // }
        }
    }

    return (
        <div class="container">
            <div class="row">
                <h2 class="page-title my-3 ">Vendor Registration (Organization)</h2>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="border-bottom pb-2 ">
                        <div id="services" className="tab-pane active my-4">
                            <h2 className="heading">For Companies</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="aboutyou">Tell Us about yourself</label>
                                                <textarea id="aboutyou" name="aboutyou" rows="4" cols="50" class="form-control"
                                                    value={user.aboutyou}
                                                    onChange={handleInputs} >&nbsp;</textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-6">
                                            <div className="form-group">
                                                <label for="companyName">Company Name*</label>
                                                <input type="text" required className="form-control" name="companyName" placeholder="Enter Company Name"
                                                    value={user.companyName}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-6">
                                            <div className="form-group">
                                                <label for="userName">User Name*</label>
                                                <input type="text" required className="form-control" name="userName" placeholder="Enter User name"
                                                    value={user.userName}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="email">(Primary)Company Email*</label>
                                                <input type="email" className="form-control" id="email" placeholder="Enter Company Email" name="email"
                                                    value={user.email}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="phoneNumber">Telephone</label>
                                                <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" placeholder=""
                                                    value={user.phoneNumber}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="mobile">Primary Mobile*(Don't add 0 or +971)</label><br />
                                                <input type="mobile" className="form-control" id="mobile" name="mobile" placeholder="Don't add 0 or +971"
                                                    value={user.mobile}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="contactPerson">Contact Person Name*</label>
                                                <input type="text" className="form-control" name="contactPerson" placeholder="Enter Name"
                                                    value={user.contactPerson}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="contactMobile">Contact Person Mobile*</label><br />
                                                <input type="mobile" className="form-control" name="contactMobile" placeholder="+971"
                                                    value={user.contactMobile}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="contactEmail">Contact Person Email*</label>
                                                <input type="email" className="form-control"  name="contactEmail" placeholder=""
                                                    value={user.contactEmail}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="password">Password*</label><br />
                                                <input type="text" className="form-control" id="password" name="password" placeholder=""
                                                    value={user.password}
                                                    onChange={handleInputs} />
                                                (Minimum 9 character, at least one uppercase letter, one lowercase letter, one number and one special character.)
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="specilization">Specialization*</label>
                                                <select name="specilization"
                                                    className="form-control"
                                                    value={user.specilization}
                                                    onChange={handleInputs} >
                                                    <option>Select Specialization</option>
                                                    {services.map((ser) => {
                                                        return <option value={ser.name}>{ser.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="whereYourBusiness">Where is your Business*</label>
                                                <select name="whereYourBusiness" className="form-control"
                                                    value={user.whereYourBusiness}
                                                    onChange={handleInputs} >
                                                    <option>Select Location</option>
                                                    {locations.map((loc) => {
                                                        return <option value={loc.stateName}>{loc.stateName}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="address">Address*</label><br />
                                                <input type="text" className="form-control" name="address" placeholder=""
                                                    value={user.address}
                                                    onChange={handleInputs} /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Company Information</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4 py-3">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="tradeExpiry">Trade License Expiry</label>
                                                <DatePicker className="form-control"
                                                minDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="dd/mm/yyyy"
                                                onChange={(date) => setTradeExpiry(date)}
                                                selected = {user.tradeExpiry==="" ? null : new Date(toReadable(user.tradeExpiry))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="estCardExpiry">Establishment Card Expiry</label>
                                                <DatePicker className="form-control"
                                                minDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="dd/mm/yyyy"
                                                onChange={(date) => setEstCardExpiry(date)}
                                                selected = {user.estCardExpiry==="" ? null : new Date(toReadable(user.estCardExpiry))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Vendor Plan</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="planDetails">Details of Plan</label>
                                                <input type="text" className="form-control" id="planDetails" name="planDetails"
                                                    value={user.planDetails}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="planExpiry">Plan Expiry</label>
                                                <DatePicker className="form-control"
                                                minDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="dd/mm/yyyy"
                                                onChange={(date) => setPlanExpiry(date)}
                                                selected = {user.planExpiry==="" ? null : new Date(toReadable(user.planExpiry))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="agreementFileUrl">Agreement File</label>
                                                <input type="file" className="form-control" name="agreementFileUrl"
                                                    onChange={fileChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Payment Bank Details</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="bankName">Bank Name</label>
                                                <input type="text" className="form-control" id="bankName" name="bankName"
                                                    value={user.bankName}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="accountName">Account Full Name</label>
                                                <input type="text" className="form-control" id="accountName" name="accountName"
                                                    value={user.accountName}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="ibanNo">IBAN No.</label>
                                                <input type="text" className="form-control" id="ibanNo" name="ibanNo"
                                                    value={user.ibanNo}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="accountNumber">Account Number</label>
                                                <input type="number" className="form-control" id="accountNumber" name="accountNumber"
                                                    value={user.accountNumber}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="swift">Swift</label>
                                                <input type="text" className="form-control" id="swift" name="swift"
                                                    value={user.swift}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="branchAddress">Branch Address</label>
                                                <input type="text" className="form-control" id="branchAddress" name="branchAddress"
                                                    value={user.branchAddress}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-outer mb-4">
                                <h2 className="heading ">Partner Information</h2>
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="partnerexists">Is Partners Exists?</label><br />
                                                <input type="checkbox" id="yes" name="uae-residence" value="yes" />&nbsp;<label for="yes">Partners</label>
                                            </div>
                                        </div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="noOfPartners">No of Partners</label>
                                                <input type="number" className="form-control" id="noOfPartners" name="noOfPartners"
                                                    value={user.noOfPartners}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p style={{ color: "red" }}>{errMessage}</p>
                            <Button color="primary" disableElevation variant="contained" onClick={PostData}>Save</Button>
                            <br />
                            <hr />
                            {/* <h2 className="heading">Owners and Partners Entry</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <a href="/owner-details" target="_blank">
                                                    <button className="btn-orange" >Partner 1 Entry</button>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <a href="/partner-details" target="_blank">
                                                    <button className="btn-orange" >Partner 2 Entry</button>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <a href="/partner-details" target="_blank">
                                                    <button className="btn-orange" >Partner 2 Entry</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>


                    </div>

                </div>
            </div>
        </div>

    );
}

export default VendorRegistration;