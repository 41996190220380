import { useEffect, useState } from "react";
import { FormLabel, makeStyles, TextField } from "@material-ui/core";

import PlusTextField from "./PlusTextField";

export default function Box({ disabled, value, setQuestionBox }) {
  const [question, setQuestion] = useState(value.ques ? value.ques : "");
  const classes = useStyles();

  const handleChange = (event) => {
    setQuestion(event.target.value);
  };

  useEffect(() => {
    setQuestionBox((old) => {
      return old.map((q) => {
        if (q._id === value._id) {
          return { ...q, ques: question };
        } else {
          return q;
        }
      });
    });
    // eslint-disable-next-line
  }, [question]);

  return (
    <div
      style={{
        border: "0.2px solid rgba(0,0,0,0.1)",
        marginTop: "1rem",
      }}
    >
      <div style={{ padding: "1rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormLabel style={{ color: "black" }}>Add Questions</FormLabel>
        </div>
        <div style={{ marginTop: "1rem" }}>
          <FormLabel>Questions</FormLabel>
          <TextField
            disabled={disabled}
            style={{ margin: "1rem 0" }}
            margin='dense'
            id='name_questions'
            variant='outlined'
            fullWidth
            className={classes.textField}
            value={question}
            onChange={handleChange}
            placeholder='Write Here...'
          />
          <FormLabel>Value</FormLabel>
          <PlusTextField
            disabled={disabled}
            edit={value.value}
            setQuestionBox={setQuestionBox}
            id={value._id}
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: "#c4c4c4",
  },
}));
