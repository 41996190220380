import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import {BASE_API_URL} from '../../constant';
import userContext from "../../context/userContext";
import moment from "moment";
import {Link,useParams} from 'react-router-dom'

const EditCoupon = () => {
    const history = useHistory();
    const params = useParams();
    const { userData, setUserData } = useContext(userContext);
    const [coupon, setCoupon] = useState({
        couponId:params.couponId,
        couponType:"",
        applying:"",
        couponTitle:"", 
        couponCode:"",
        couponValue:"",
	    maxDiscountAmount:"",
        minPurchaseAmount:"",
        startDate:"",
        endDate:""
    })
    const setStartDate = (e) => { 
        setCoupon({...coupon, startDate:moment.unix(e).format("X")}); 
    }
    const setEndDate = (e) => { 
        setCoupon({...coupon, endDate:moment.unix(e).format("X")}); 
    }
	const toReadable = (Unix="") =>{
		return Unix === "" ? moment().format("YYYY-MM-DD") : moment.unix(Unix/1000).format("YYYY-MM-DD");
	}

    const getCouponData = async () => { 
        const options = {
          method: "POST",
          headers: {
            Authorization: userData.token,
            "Content-Type": "application/json"
          },
          body : JSON.stringify({
            couponId:params.couponId
          })
        };
        const response = await fetch(`${BASE_API_URL}get-coupon-data`,options);
        const data = response.json(); 
        data.then((data) => { 
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
          else{
            setCoupon(data.data) ;
          }
         
        })
        .catch((error) => console.log(error));
    };

    useEffect(() => {
        getCouponData();
    }, []);

    let name, value;
    const handleInputs = (e) => {
        name=e.target.name;
        value=e.target.value;
        setCoupon({...coupon, [name]:value}); 
    }

    const PostData = async (e) => {
        e.preventDefault();
        const {
        _id,
        couponType,
        applying,
        couponTitle,
        couponCode,
        couponValue,
	    maxDiscountAmount,
        minPurchaseAmount,
        startDate,
        endDate} = coupon;
       // console.log(coupon); return false;
        const res = await fetch(`${BASE_API_URL}edit-coupons`,{
            method:"POST",
            headers:{
                    "Content-Type":"application/json",
                    "authorization":userData.token
            },
            body:JSON.stringify({
                _id,
                couponType,
                applying,
                couponTitle,
                couponCode,
                couponValue,
	            maxDiscountAmount,
                minPurchaseAmount,
                startDate,
                endDate
            })          
        });

        const data=await res.json();

        if (res.status === 401) {
              localStorage.removeItem("auth-token");
              setUserData({
                token: "",
              });
        }
        
        if(!data)
        {
            window.alert("Somthing went wrong");
        }
        else
        {
            window.alert("Coupon Update successfully");
            history.push("/coupon-List");
        }
    }
            return ( 
                <div className="container">
                    <div className="row">
	                    <h2 className="page-title my-3 ">Edit Coupon Master</h2>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="border-bottom pb-2 ">
                                <div id="services" className="tab-pane active my-4">
					                <h2 className="heading">For Coupon Edit</h2>
                                    <div className="border-outer mb-4">
                                        <div className="border-top px-4  py-3 ">
							                <div className="row">
                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                
                                                    <div className="form-group">
                                                    <label htmlFor="couponType">Coupon Type<span className='orangeTxt'>*</span></label>
                                                        <select name="couponType" className="form-control" 
                                                        value={coupon.couponType} onChange={handleInputs} >
                                                            <option value="">Select coupon type</option>
                                                            <option value="flat%">Flat % Discount</option>
                                                            <option value="amount">Fixed Amount Discount</option>
									                    </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-xs-6">
                                                    <div className="form-group">
                                                    <label htmlFor="applying">Applying<span className='orangeTxt'>*</span></label>
                                                        <select name="applying" className="form-control" 
                                                        value={coupon.applying} onChange={handleInputs} >
                                                            <option value="">Applying to</option>
                                                            <option value="onetime">One Time</option>
                                                            <option value="recurring">Recurring</option>
									                    </select>
                                                    </div>
                                                </div>
                                            </div>
						                    <div className="row">
							                    <div className="col-md-12 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label htmlFor="couponTitle">Coupon Title <span className='orangeTxt'>*</span></label>
                                                        <input type="text" className="form-control" name="couponTitle" placeholder="Enter Coupon Title" 
                                                        value={coupon.couponTitle}
                                                        onChange={handleInputs} />
                                                    </div>
                                                </div> 
                                            </div>
						                    <div className="row">
							                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label htmlFor="couponCode">Coupon Code<span className='orangeTxt'>*</span></label>
                                                        <input type="text" className="form-control"  placeholder="Enter Coupon Code" name="couponCode"
                                                        value={coupon.couponCode}
                                                        onChange={handleInputs} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label htmlFor="couponValue">Coupon Value</label>
                                                        <input type="text" className="form-control"  name="couponValue" placeholder="" 
                                                        value={coupon.couponValue}
                                                        onChange={handleInputs} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label htmlFor="maxDiscountAmount">Maximum Discount Amount <span className='orangeTxt'>*</span></label><br />
                                                        <input type="text" className="form-control" name="maxDiscountAmount" placeholder="" 
                                                        value={coupon.maxDiscountAmount}
                                                        onChange={handleInputs} />
                                                    </div>
                                                </div>
                                           
							                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label htmlFor="minPurchaseAmount">Minimum Purchase Amount<span className='orangeTxt'>*</span></label>
                                                        <input type="text" className="form-control" name="minPurchaseAmount" placeholder="Enter Purchase Amount" 
                                                        value={coupon.minPurchaseAmount}
                                                        onChange={handleInputs} />
                                                    </div>
                                                </div>
                                            </div> 
						                </div>          
                                    </div>
				                    <h2 className="heading ">Coupon Validity Period</h2>
					                    <div className="border-outer mb-4">
                                            <div className="border-top px-4 py-3">
							                    <div className="row">
							                        <div className="col-md-6 col-sm-12 col-xs-12">
                                                        <div className="form-group">
									                        <label htmlFor="startDate">Start Date</label>
                                                            <DatePicker className="form-control"
                                                            minDate={new Date()}
                                                            dateFormat="dd/MM/yyyy"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            placeholderText="dd/mm/yyyy"
                                                            onChange={(date) => setStartDate(date)}
                                                            selected = {coupon.startDate==="" ? null : new Date(toReadable(coupon.startDate))}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="endDate">End Date</label>
                                                    <DatePicker className="form-control"
                                                    minDate={new Date()}
                                                    dateFormat="dd/MM/yyyy"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    placeholderText="dd/mm/yyyy"
                                                    onChange={(date) => setEndDate(date)}
                                                    selected = {coupon.endDate==="" ? null : new Date(toReadable(coupon.endDate))}
                                                    />
                                                </div>
                                            </div>
							            </div>
						            </div>          
					            </div>
                            <Button 
                            color="primary" 
                            disableElevation 
                            variant="contained" 
                            onClick={PostData}>Save</Button>
                                <br/>
                                <hr/>  
                        </div>                    
	                </div>                
                </div>
            </div>    
        </div>
    );
}

export default EditCoupon;