import React,{ useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Button,
  lighten,
  Typography
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import TreeView from "@material-ui/lab/TreeView";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeItem from "@material-ui/lab/TreeItem";import {BASE_API_URL} from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import './../../styles/cssformui.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Grid from '@material-ui/core/Grid';
import Checkbox from "@material-ui/core/Checkbox";

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
      <StyledTableCell style={{ fontWeight: 'bold'}}>Sr no.</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Role Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Status</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

const useViewStyles = makeStyles({
  root: {}
});

const useItemStyles = makeStyles(theme => ({
  root: {
    "& > .MuiTreeItem-content > .MuiTreeItem-label": {
      display: "flex",
      alignItems: "center",
      padding: "4px 0",
      background: "transparent !important",
      pointerEvents: "none"
    },
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      content: "''",
      display: "inline-block",
      width: 12,
      height: 12,
      marginRight: 8,
      border: "1px solid #ccc",
      background: "white"
    }
  },
  iconContainer: {
    marginRight: 12,
    "& > svg": {
      padding: 8,
      "&:hover": {
        opacity: 0.6
      }
    }
  },
  label: {
    padding: 0
  },
  selected: {
    "& > .MuiTreeItem-content  > .MuiTreeItem-label::before": {
      background: theme.palette.primary.main,
      border: "1px solid transparent"
    }
  }
}));

const EnhancedTableToolbar = ({ value, onChange, onButtonClicked }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Role Permission
      </Typography>
      

    </Toolbar>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


export default function RolePermission({ classes }) {
  const classesView = useViewStyles();
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const classesItem = useItemStyles();
  const [create, setcreate] = useState(false);
  const [page, setPage] = React.useState(0);
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const handleToggle = (event, nodeIds) => {
    if (event.target.nodeName !== "svg") {
      return;
    }
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    if (event.target.nodeName === "svg") {
      return;
    }
    const first = nodeIds[0];
    if (selected.includes(first)) {
      setSelected(selected.filter(id => id !== first));
    } else {
      setSelected([first, ...selected]);
    }
  };

  return (
    <>
   
    <EnhancedTableToolbar onButtonClicked={e => setcreate(true)} value={search} onChange={e => {setsearch(e.target.value); setPage(0)}} />
    <Divider />
    <Grid container style={{ marginTop: '20px'}}>
    <Paper style={{ margin: '10px 20px'}}>  
    <Grid container xs={8}>
    <h5 style={{ padding: '10px'}}>Select Forms</h5>
     <TreeView
        classes={classesView}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
        style={{ padding: '10px'}}
    >
      <TreeItem classes={classesItem} nodeId="1" label="Dashboard"></TreeItem>
      <TreeItem classes={classesItem} nodeId="2" label="Services"></TreeItem>
      <TreeItem classes={classesItem} nodeId="3" label="Features"></TreeItem>
      <TreeItem classes={classesItem} nodeId="4" label="Filters"></TreeItem>
      <TreeItem classes={classesItem} nodeId="5" label="Coupon Master"></TreeItem>
      <TreeItem classes={classesItem} nodeId="6" label="Top Services"></TreeItem>
      <TreeItem classes={classesItem} nodeId="7" label="User Authentication">
        <TreeItem classes={classesItem} nodeId="8" label="Role Permission" />
      </TreeItem>
      <TreeItem classes={classesItem} nodeId="9" label="Event Management">
        <TreeItem classes={classesItem} nodeId="10" label="Add Event" />
        <TreeItem classes={classesItem} nodeId="11" label="Booked Events" />
        <TreeItem classes={classesItem} nodeId="12" label="Pending Amount" />
        <TreeItem classes={classesItem} nodeId="13" label="Payment Distribution" />
        <TreeItem classes={classesItem} nodeId="14" label="Feedback Approval" />
      </TreeItem>
      <TreeItem classes={classesItem} nodeId="15" label="Vendor Management">
        <TreeItem classes={classesItem} nodeId="16" label="Material-UI">
          <TreeItem classes={classesItem} nodeId="17" label="Lead data" /> 
        </TreeItem>
      </TreeItem>
    </TreeView>
    
    </Grid>
    </Paper>
    <Paper style={{ margin: '10px 0px', padding: '20px;'}}>  
    <Grid container xs={8}>    
    <h5 style={{ padding: '10px'}}>Mapping Role-Permission</h5>
    <div style={{ padding: '20px'}}>
    <label htmlFor="couponType">Select Role Type<span className='orangeTxt'>*</span></label>
    <select name="couponType" className="form-control" >
              <option value=""> Select Role Type</option>
              <option value="admin">Admin</option>
              <option value="editor">Editor</option>
		</select>

    <label htmlFor="permission" style={{ paddingTop: '30px'}}>Choose Permission</label>
    <ul style={{ listStyle: 'none'}}>
      <li><Checkbox />Create</li>
      <li><Checkbox />View</li>
      <li><Checkbox />Delete</li>
      <li><Checkbox />Update</li>
    </ul>
    </div>
    </Grid>
    </Paper>
    </Grid>
    <Paper style={{ margin: 10 }}>
    <Divider />
    
    
    <TableContainer>
                <Table >
                  <EnhancedTableHead />
                  <TableBody>
                    <StyledTableRow>
                       
                      <StyledTableCell align='left'>1.</StyledTableCell> 
                      <StyledTableCell align='left'>Admin</StyledTableCell>    
                      <StyledTableCell align='left'>active</StyledTableCell>
                      <StyledTableCell
                            align='right'
                            style={{ padding: 0, width: 10 }}
                          >
                            <IconButton>
                              <EditOutlinedIcon style={{ color: '#bcbcbc' }} />
                            </IconButton>
                          </StyledTableCell>
                    </StyledTableRow> 
                    <StyledTableRow>
                       
                      <StyledTableCell align='left'>2.</StyledTableCell> 
                      <StyledTableCell align='left'>Editor</StyledTableCell>    
                      <StyledTableCell align='left'>active</StyledTableCell>
                      <StyledTableCell
                            align='right'
                            style={{ padding: 0, width: 10 }}
                          >
                            <IconButton>
                              <EditOutlinedIcon style={{ color: '#bcbcbc' }} />
                            </IconButton>
                          </StyledTableCell>
                    </StyledTableRow>
                    
                   
                  </TableBody>
                </Table>
                
              </TableContainer>
              <Divider />        
    </Paper>
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));



const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})