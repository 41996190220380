import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import {BASE_API_URL} from '../../constant';
import userContext from "../../context/userContext";
import { uploadImageToS3 } from '../../aws/awsUploader';
import moment from "moment";
import axios from "axios";


const ProfessionalRegistration = () => {
    const history = useHistory();
    const { userData, setUserData } = useContext(userContext);
    const [errMessage, setErrMessage] = useState('');
    const [locations,setLocation] = useState([]);
    const [services,setServices] = useState([]);
    const [user, setUser] = useState({
        aboutyou:"",
        userName:"",
        firstName:"", 
        lastName:"",
        email:"",
        phoneNumber:"",
	    mobile:"",
        specilization:"",
	    whereYourBusiness:"",
        address:"",
	    visaType:"",
	    planDetails:"",
	    planExpiry:"",
	    agreementFileUrl:"",
	    bankName:"",
	    accountName:"",
	    ibanNo:"",
	    accountNumber:"",
	    swift:"",
	    branchAddress:"",
        userType:"PROFESSIONAL",
        password:""
    })
    const setPlanExpiry = (e) => { 
        //setStartDate(moment.unix(e).format("X"))
        setUser({...user, planExpiry:moment.unix(e).format("X")}); 
    }
	const toReadable = (Unix="") =>{
		return Unix === "" ? moment().format("YYYY-MM-DD") : moment.unix(Unix/1000).format("YYYY-MM-DD");
	}
    useEffect(() => {
        getLocations();
        getServices();
        // eslint-disable-next-line
      }, []);

    let name, value;

    const handleInputs = (e) => {
        name=e.target.name;
        value=e.target.value;
        setUser({...user, [name]:value}); 
    }

    

    //Get Locations
    const getLocations = () =>{
        axios.get(`${BASE_API_URL}get-locations`)
        .then(res=>{
            setLocation(res.data.data);
        })
        .catch(err=>{ console.log(err)})
    }
    
    //get services
    const getServices = () =>{
        const headers={
            "Content-Type":"application/json",
            "authorization":userData.token
        }
        axios.get(`${BASE_API_URL}service-types`,{headers})
        .then(res=>{
            setServices(res.data.data.result);
        })
        .catch(err=>{ console.log(err)})
    }

    const fileChange = evt => { 
        uploadImageToS3(evt.target.files[0],'vendor_document')
            .then(res => { setUser({ ...user, agreementFileUrl: res.location }) })
            .catch(err => { console.log(err) })
    }

    const PostData = async (e) => {
        e.preventDefault();
        const {
        aboutyou,
        firstName, 
        userName,
        lastName,
        email,
        phoneNumber,
        mobile,
        specilization,
        whereYourBusiness,
        address,
        visaType,
        planDetails,
        planExpiry,
        agreementFileUrl,
        bankName,
        accountName,
        ibanNo,
        accountNumber,
        swift,
        branchAddress,
        userType,
        password} = user;
            // console.log(user);return false;
        const res = await fetch(`${BASE_API_URL}profe-details-entry`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "authorization":userData.token
            },
            body:JSON.stringify({
                aboutyou,
                firstName, 
                lastName,
                email,
                phoneNumber,
                userName,
                mobile,
                specilization,
                whereYourBusiness,
                address,
                visaType,
                planDetails,
                planExpiry,
                agreementFileUrl,
                bankName,
                accountName,
                ibanNo,
                accountNumber,
                swift,
                branchAddress,
                userType,
                password
            })          
        });

        const data=await res.json();

        if (res.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
            token: "",
            });
        }
        else if(res.status === 400 || res.status === 409){
            setErrMessage(data.message)
        }
        else{
            window.alert("Registration Successfull");
            history.push("/professionals-data");
        }
    }

    return ( 
    <div className="container">
        <div className="row">
	    <h2 className="page-title my-3">Professional Registration (Individual)</h2>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="border-bottom pb-2">
                    <div id="services" className="tab-pane active my-4">
					    <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3">
							    <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="about">Tell Us about yourself</label>
									        <textarea id="aboutyou" name="aboutyou" rows="4" cols="50" className="form-control" 
                                             value={user.aboutyou}
                                             onChange={handleInputs}></textarea> 
                                        </div>
                                    </div>
                                </div>
						        <div className="row">
							        <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="firstName">First Name*</label>
                                            <input type="text" className="form-control" 
                                            id="firstName" name="firstName" placeholder="First Name" 
                                            value={user.firstName}
                                            onChange={handleInputs}/>
                                        </div>
                                    </div> 
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="lastName">Last Name*</label>
                                            <input type="text" className="form-control" 
                                            id="lastName" name="lastName" placeholder="Last name" 
                                            value={user.lastname}
                                            onChange={handleInputs}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="userName">User Name*</label>
                                            <input type="text" className="form-control" 
                                            id="userName" name="userName" placeholder="User Name" 
                                            value={user.userName}
                                            onChange={handleInputs}/>
                                        </div>
                                    </div>
                                </div>
						        <div className="row">
							        <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="email">Primary Email*</label>
                                            <input type="email" className="form-control" 
                                            id="email" name="email" placeholder="" 
                                            value={user.email} onChange={handleInputs}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="phoneNumber">Telephone</label>
                                            <input type="text" className="form-control" 
                                            name="phoneNumber" placeholder="" 
                                            value={user.phoneNumber} onChange={handleInputs}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="mobile">Primary Mobile*(Don't add 0 or +971)</label>
                                            <input type="mobile" className="form-control" 
                                            id="mobile" name="mobile" placeholder="Mobile Number(Don't add 0 or +971)" 
                                            value={user.mobile}
                                            maxlength="9" 
                                            onChange={handleInputs}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="password">Password*</label><br/>
                                            <input type="text" className="form-control" id="password" name="password" placeholder="" 
                                            value={user.password}
                                            onChange={handleInputs} />
                                            (Minimum 9 character, at least one uppercase letter, one lowercase letter, one number and one special character.)
                                        </div>
                                    </div>
                                </div>
						        <div className="row">
						    	    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="specilization">Specialization*</label>
                                            <select name="specilization" 
                                                className="form-control" 
                                                value={user.specilization}
                                                onChange={handleInputs} >
                                                <option>Select Specialization</option>
                                                {services.map((ser)=>{
                                                    return <option value={ser.name}>{ser.name}</option>
                                                })}
									        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="whereYourBusiness">Where is your Business*</label>
                                        <select name="whereYourBusiness" 
                                        id="whereYourBusiness" className="form-control" 
                                        value={user.whereYourBusiness}
                                        onChange={handleInputs} >
                                        <option>Select Location</option>
                                        { locations.map((loc)=>{
                                            return <option value={loc.stateName}>{loc.stateName}</option>
                                        })
                                        }
									    </select>
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="visaType">Visa Type</label>
                                        <select name="visaType" id="visaType" className="form-control" 
                                        value={user.visaType}
                                        onChange={handleInputs} >
										    <option value="work-visa">UAE Work Visa</option>
										    <option value="student-visa">UAE Student Visa</option>
										    <option value="family-visa">UAE Family Visa</option>
										    <option value="investment-visa">UAE Investment Visa</option>
										    <option value="retirement-visa">UAE Retirement Visa</option>
									    </select>
                                    </div>
                                </div>
                            </div>
						    <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="address">Address*</label>
                                        <input type="text" className="form-control" 
                                        id="address" name="address"
                                        value={user.address}
                                        onChange={handleInputs}
                                             placeholder="Enter Address" />
                                    </div>
                                </div>
                            </div>
						</div>          
                    </div>
				    <h2 className="heading">Professional Plan</h2>
					<div className="border-outer mb-4">
                        <div className="border-top px-4  py-3 ">
							<div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
									    <label for="planDetails">Details of Plan</label>
                                        <input type="text" className="form-control" 
                                        id="planDetails" name="planDetails"
                                        value={user.planDetails}
                                             onChange={handleInputs}
                                             />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="planExpiry">Plan Expiry</label>
                                        <DatePicker className="form-control"
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        placeholderText="dd/mm/yyyy"
                                        onChange={(date) => setPlanExpiry(date)}
                                        selected = {user.planExpiry==="" ? null : new Date(toReadable(user.planExpiry))}
                                        />
                                        
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="agreementFileUrl">Agreement File Upload.</label>
                                        <input type="file" className="form-control" name="agreementFileUrl" onChange={fileChange} />
                                    </div>
                                </div>
							</div>
						</div>          
					</div>
				    <h2 className="heading ">Payment Bank Details</h2>
					<div className="border-outer mb-4">
                        <div className="border-top px-4  py-3 ">
							<div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
									    <label for="bankName">Bank Name</label>
                                        <input type="text" className="form-control" 
                                        id="bankName" name="bankName"
                                        value={user.bankName}
                                             onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="accountName">Account Full Name</label>
                                        <input type="text" className="form-control" 
                                        id="accountName" name="accountName"
                                        value={user.accountName}
                                             onChange={handleInputs} />
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="ibanNo">IBAN No.</label>
                                        <input type="text" className="form-control" 
                                        id="ibanNo" name="ibanNo"
                                        value={user.ibanNo}
                                             onChange={handleInputs} />
                                    </div>
                                </div>
							</div>
							<div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
									    <label for="accountNumber">Account Number</label>
                                        <input type="text" className="form-control" 
                                        id="accountNumber" name="accountNumber"
                                        value={user.accountNumber}
                                             onChange={handleInputs} />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="swift">Swift</label>
                                        <input type="text" className="form-control" 
                                        id="swift" name="swift"
                                        value={user.swift}
                                             onChange={handleInputs} />
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="branchAddress">Branch Address</label>
                                        <input type="text" className="form-control" 
                                        id="branchAddress" name="branchAddress" 
                                        value={user.branchAddress}
                                             onChange={handleInputs} />
                                    </div>
                                </div>
							</div>
						</div>          
					</div>	
				</div>		
                <p style={{color:"red"}}>{errMessage}</p>        
                <Button color="primary" disableElevation variant="contained" onClick={PostData} >Save</Button>
            </div>
		</div>
       
    </div>
</div>
);
}

export default ProfessionalRegistration;