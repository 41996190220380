import React, { useContext, useState, useEffect } from 'react';
import BackDrop from '@material-ui/core/Backdrop';
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';
import { BootstrapInput } from '../../components/small/Select';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import userContext from "../../context/userContext";
import { getURL } from "../../util";
import searchContext from "../../context/searchContext";
import EditText from '../../components/small/EditText';
import AddCircleTwoTone from '@material-ui/icons/AddCircleTwoTone';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Box from '@material-ui/core/Box';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom';
import DragAndDrop from '../../components/small/DragAndDrop';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from '@material-ui/core/Chip';
import { uploadImageToS3 } from '../../aws/awsUploader';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { BASE_API_URL } from '../../constant';



const AddEvent = ({ classes, history, location }) => {
    const update = location.state;

    const { userData, setUserData } = useContext(userContext);
    const { search } = useContext(searchContext);
    const [loading, setLoading] = useState(false);
    const [eventCategory, setEventCategory] = useState([]);
    const [category, setCategory] = useState("");
    const [services, setServices] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedFilters, setselectedFilters] = useState([]);
    const [openAddOn, setopenAddOn] = useState(false);
    const [error, seterror] = useState({ open: false, message: 'Error' });
    const [eventAdd, setEventAdd] = useState({
        name: '',
        image: '',
        filters: [],
        primary: [],
        addOns: []
    });
    const [filters, setFilters] = useState([]);
    const [duplicateFilters, setduplicateFilters] = useState([]);
    const [grouping, setGrouping] = useState([]);

    const checkValidation = () => {
        const { name, image } = eventAdd
        if (name === '') {
            seterror({ open: true, message: 'Type Should not Be Empty' })
            return false
        }
        else if (category === '') {
            seterror({ open: true, message: 'Category Selection is must' })
            return false
        }
        return true
    }


    const fileChange = evt => { 
        uploadImageToS3(evt.target.files[0],'events')
            .then(res => { setEventAdd({ ...eventAdd, image: res.location }) })
            .catch(err => { console.log(err) })
    }


    const getIdPrimary = () => {
        const arr = [];
        for (let service of services) {
            if (service.isPrimary === true) {
                arr.push({ serviceId: service._id, isCompulsory: service.isRequired })
            }
        }
        return arr
    }

    const getAddOnsPrimary = () => {
        const arr = [];
        for (let service of services) {
            if (service.isAddOn === true) {
                arr.push({ serviceId: service._id })
            }
        }
        return arr
    }

    const filterIds = () => {
        const arr = [];
        for (let filter of selectedFilters) {
            arr.push({ filterId: filter._id, position: filter.position })
        }
        return arr
    }


    const getServices = async () => {
        const options = {
            method: "GET",
            headers: {
                Authorization: userData.token,
            },
        };
        const response = await fetch(
            getURL(`${BASE_API_URL}service-types`, search),
            options
        );
        const data = response.json();
        data.then((data) => {
            if (response.status === 401) {
                localStorage.removeItem("auth-token");
                setUserData({
                    token: "",
                });
            }
            if (data.data) {
                //add multiple  keys for easily filter

                const makeFilters = filters => {
                    const flt = [];
                    const existingFilters = update.filters;
                    for (let filter of filters) {
                        const found = existingFilters.find(x => x.filterId._id === filter._id);
                        if (found) {


                            flt.push({ ...filter, position: found.position, isSelected: true })
                        } else {
                            flt.push({ ...filter, isSelected: false })
                        }
                    }

                    return flt;
                }

                if (update) {
                    const withIsPrimaryData = [];
                    const allFilters = [];
                    for (let resData of data.data.result) {
                        const foundInPrimary = update.primary.find(x => x.serviceId._id === resData._id);
                        const foundInAddOns = update.addOns.find(x => x.serviceId._id === resData._id);

                        if (foundInPrimary) {
                            allFilters.push(...resData.filters);
                            withIsPrimaryData.push({
                                ...resData,
                                isPrimary: true,
                                isRequired: false,
                                isAddOn: false,
                                filters: makeFilters(resData.filters)
                                // filters: resData.filters
                            })
                        } else if (foundInAddOns) {
                            allFilters.push(...resData.filters);
                            withIsPrimaryData.push({
                                ...resData,
                                isPrimary: false,
                                isRequired: false,
                                isAddOn: true,
                                // filters: resData.filters
                                filters: makeFilters(resData.filters)
                            })
                        } else {
                            withIsPrimaryData.push({
                                ...resData,
                                isPrimary: false,
                                isRequired: false,
                                isAddOn: false,
                                filters: makeFilters(resData.filters)
                                // filters: resData.filters
                            })
                        }

                    }
                    setServices(withIsPrimaryData);

                } else {
                    const withIsPrimaryData = data.data.result.map((ser) => {
                        const filterWithIsSelected = ser.filters.map((filter, i) => {
                            return { ...filter, isSelected: false }
                        })
                        return {
                            ...ser,
                            isPrimary: false,
                            isRequired: false,
                            isAddOn: false,
                            filters: filterWithIsSelected
                        }
                    });
                    setServices(withIsPrimaryData);
                }

            }
            setLoading(false);
        })
            .catch((error) => console.log(error));
    };


    const handleAddEvent_backup = async () => {
        if (checkValidation()) {

           /* const formdata = new FormData();
            formdata.append("categoryId", category);
            if (update) {
                formdata.append("eventId", update._id);
            }
            formdata.append("name", eventAdd.name);
            formdata.append("primary", JSON.stringify(getIdPrimary()));
            formdata.append("addOns", JSON.stringify(getAddOnsPrimary()));
            formdata.append("filters", JSON.stringify(filterIds()));
            formdata.append("image", eventAdd.image);
            let options = {
                method: "POST",
                headers: {
                    //Authorization: userData.token,
                    "authorization":userData.token
                },
                body: formdata,
            };
            try {
                const response = await fetch(
                    `${BASE_API_URL}event-types`,
                    options
                );
                const filterResult = await response.json();
                history.push('/event');

            } catch (e) {
                alert("Error" + error)
            }
*/


            //start Jerry
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+userData?.token);
            let rawOBJ = {
                "categoryId": category,
                "name":eventAdd?.name,
                "primary": getIdPrimary(),//JSON.stringify(getIdPrimary()),
                "addOns": getAddOnsPrimary(),//JSON.stringify(getAddOnsPrimary()),
                "filters": filterIds(),//JSON.stringify(filterIds()),
                "image": eventAdd?.image,
            }
            if (update) {
                rawOBJ.eventId=update._id;
            }
            var raw = JSON.stringify(rawOBJ);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BASE_API_URL}event-types`, requestOptions)
                .then(response => response.json())
                .then(response => {

                    console.log(response)
                    history.push('/event');
                })
                .catch(error => console.log('error', error));


        }
    };

    const handleAddEvent = async () => {
        if (checkValidation()) {
            //start Jerry
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+userData?.token);
            let rawOBJ = {
                "categoryId": category,
                "name":eventAdd?.name,
                "primary": getIdPrimary(),//JSON.stringify(getIdPrimary()),
                "addOns": getAddOnsPrimary(),//JSON.stringify(getAddOnsPrimary()),
                "filters": filterIds(),//JSON.stringify(filterIds()),
                "image": eventAdd?.image,
            }
            if (update) {
                rawOBJ.eventId=update._id;
            }
            var raw = JSON.stringify(rawOBJ);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${BASE_API_URL}event-types`, requestOptions)
                .then(response => response.json())
                .then(response => {

                    console.log(response)
                    history.push('/event');
                })
                .catch(error => console.log('error', error));
        }
    };

    const getEventCategory = async () => {
        let options = {
            method: "GET",
            headers: {
                Authorization: userData.token,
            },
        };
        const response = await fetch(
            getURL(`${BASE_API_URL}event-categories`, search),
            options
        );
        const data = response.json();
        data
            .then((data) => {
                if (response.status === 401) {
                    localStorage.removeItem("auth-token");
                    setUserData({
                        token: "",
                    });
                }
                setEventCategory(data.data.result);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    useEffect(() => {
        getEventCategory();
        getServices();
    }, []);


    useEffect(() => {
        const serv = [...services];
        let allFilter = [];
        const grouping = [];
        for (let service of serv) {
            if (service.isPrimary || service.isAddOn) {
                allFilter.push(...service.filters)
            }
        }
        const duplicatefilters = allFilter.filter((value, index) => allFilter.findIndex(x => x._id === value._id) !== index);
        const duplicateSet = duplicatefilters.filter((value, index) => duplicatefilters.findIndex(x => x._id === value._id) === index);
        //  const duplicatefilters = allFilter.filter(allFilter.findIndex(x => x._id === value._id) !== index);
        // make grouping set for 
        for (let s of serv) {
            if (s.isPrimary || s.isAddOn) {
                let filter = [...s.filters];
                for (let i of duplicateSet) {
                    filter = filter.filter(x => x._id !== i._id)
                }
                grouping.push({ ...s, filters: filter })
            }
        }

        setGrouping([...grouping, { name: 'Common Filters', filters: duplicateSet }]);
    }, [services])

    //Add Filter with position for order Filters
    useEffect(() => {

        const filterOrder = [];

        for (let i in grouping) {
            const group = grouping[i]
            for (let k in group.filters) {
                if (group.filters[k].isSelected) {

                    filterOrder.push({ ...group.filters[k], position: group.filters[k].position ? group.filters[k].position : filterOrder.length + 1 })
                }

            }
        }

        filterOrder.sort(function (x, y) {
            return x.position - y.position;
        })

        setselectedFilters(filterOrder);
    }, [grouping]);



    useEffect(() => {
        // if update autoFill All Forms
        if (update) {
            setCategory(update.categoryId._id);
            setEventAdd({ ...eventAdd, name: update.name, image: update.image });
        }
    }, [])


    const handleSelect = (evt) => {
        setCategory(evt.target.value);
    }

    const handleSelectPrimary = (i) => {
        const services1 = [...services];
        services1[i].isPrimary = !services1[i].isPrimary
        setServices(services1);
    }

    const handleSelectAddOn = (i) => {
        const services1 = [...services];
        services1[i].isAddOn = !services1[i].isAddOn
        setServices(services1);
    }

    const handleIsRequired = (i) => {
        const services1 = [...services];
        services1[i].isRequired = !services1[i].isRequired
        setServices(services1);
    }

    const handleEventFilterSelected = (i, j) => {
        const grouping1 = [...grouping];
        grouping[i].filters[j].isSelected = !grouping[i].filters[j].isSelected;
        setGrouping(grouping1);
    }

    const handleRemoveService = i => {
        const services1 = [...services];
        services1[i].isPrimary = false
        setServices(services1);
    }

    const handleRemovetAddOn = (i) => {
        const services1 = [...services];
        services1[i].isAddOn = false
        setServices(services1);
    }

    const handleDuplicateFilterSelected = i => {
        const dup = [...duplicateFilters];
        dup[i].isSelected = !dup[i].isSelected;
        setduplicateFilters(dup);
    }





    return (
        <BackDrop
            open={true}
            className={classes.backdrop}
        >
            <Paper variant="outlined" style={{ width: '60%', padding: '24px', position: 'relative' }}>
                <Typography style={{ fontWeight: 'bolder' }}>{update ? "Update" : "Add New"} Events</Typography>
                <Grid container style={{ maxHeight: '80vh', overflow: 'auto' }}>
                    <Grid item xs={5} className={classes.line}>
                        <InputLabel shrink htmlFor={"title"}>Category</InputLabel>
                        <Select
                            id={"title"}
                            style={{ marginTop: 5, width: '100%' }}
                            value={category}
                            onChange={handleSelect}
                            input={<BootstrapInput />}
                        >
                            {eventCategory.map((l, i) => (<MenuItem key={i} value={l._id}>{l.name}</MenuItem>))}
                        </Select>

                        <EditText value={eventAdd.name} onChange={e => { setEventAdd({ ...eventAdd, name: e.target.value }) }} rootStyle={{ width: '100%' }} label="Event Type" />

                        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Primary Services</Typography>
                            <AddCircleTwoTone
                                onClick={e => setOpen(true)}
                                style={{ color: "#f47824", cursor: "pointer" }}
                            />
                        </Grid>

                        <Grid>
                            {services.map((serv, i) => (
                                !serv.isAddOn && serv.isPrimary && (
                                    <Button
                                        key={i}
                                        variant="contained"
                                        disableElevation
                                        color="primary"
                                        onClick={e => { handleIsRequired(i) }}
                                        style={{ padding: '4px 6px', margin: '7px', textTransform: 'capitalize', backgroundColor: '#8489b2' }}
                                        startIcon={serv.isRequired ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                                    >
                                        <CancelIcon onClick={e => handleRemoveService(i)} fontSize="small" className={classes.cancelIcon} />
                                        {serv.name}
                                    </Button>
                                )
                            ))}

                        </Grid>



                        <Typography style={{ fontSize: 12, color: '#f47824' }}>
                            Mark the Primary service or Add-on to make it <br />
                            *Required Service
                        </Typography>

                        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>AddOn Services</Typography>
                            <AddCircleTwoTone
                                onClick={e => { setopenAddOn(true) }}
                                style={{ color: "#f47824", cursor: "pointer" }}
                            />
                        </Grid>

                        <Grid>
                            {services.map((serv, i) => (
                                !serv.isPrimary && serv.isAddOn && (
                                    <Button
                                        key={i}
                                        variant="contained"
                                        disableElevation
                                        onClick={e => { handleIsRequired(i) }}
                                        color="primary"
                                        style={{ padding: '4px 6px', margin: '7px', textTransform: 'capitalize', backgroundColor: '#fff2ea', color: '#f87a25' }}
                                    // startIcon={<CheckCircleIcon />}
                                    >
                                        <CancelIcon onClick={e => handleRemovetAddOn(i)} fontSize="small" className={classes.cancelIcon} />
                                        {serv.name}
                                    </Button>
                                )
                            ))}
                        </Grid>

                    </Grid>
                    <Grid item xs={1}>

                    </Grid>
                    <Grid item xs={5} className={classes.line}>
                        <Typography>Add Images</Typography>
                        <Box style={{ position: 'relative', width: 90 }}>
                            <label htmlFor="image">
                                <Avatar
                                    src={eventAdd.image}
                                    variant="rounded"
                                    style={{ width: 90, height: 100, cursor: 'pointer' }}>
                                    <CameraAltIcon />
                                </Avatar>
                                <input id="image" type="file" onChange={fileChange} style={{ display: 'none' }} />
                            </label>
                            {eventAdd.image !== "" && (<CancelIcon onClick={e => setEventAdd({ ...eventAdd, image: '' })} style={{ position: 'absolute', right: -12, top: -12, cursor: 'pointer' }} />)}

                        </Box>

                        <Typography>Event Filter</Typography>

                        <Grid>
                            {grouping.map((group, i) => (
                                group.filters.length !== 0 && (
                                    <Paper variant="outlined" style={{ marginBlock: 10, position: 'relative' }}>
                                        <Typography style={{
                                            position: 'absolute',
                                            fontSize: 12,
                                            top: -10,
                                            left: 10,
                                            backgroundColor: '#FFF'
                                        }}>{group.name}</Typography>
                                        {group.filters.map((filter, j) => (
                                            <Button
                                                key={Math.random()}
                                                style={{ fontSize: 14, textTransform: 'capitalize' }}
                                                onClick={e => handleEventFilterSelected(i, j)}
                                                startIcon={filter.isSelected ? <CheckCircleIcon style={{ color: '#f47824' }} /> : <CheckCircleOutlineIcon style={{ color: '#f47824' }} />}
                                            >{filter.name}</Button>
                                        ))}
                                    </Paper>
                                )

                            ))}
                        </Grid>

                        <Typography>Filter Order</Typography>
                        <Grid >
                            <DragAndDrop list={selectedFilters} onDrag={list => {
                                setselectedFilters(list)
                            }} />
                        </Grid>

                    </Grid>
                </Grid>

                <Button
                    onClick={handleAddEvent}
                    disableElevation
                    style={{ backgroundColor: '#8489b2', color: 'white', textTransform: 'none', width: 300 }}
                    variant="contained">{update ? "Update" : "Create"} Service</Button>
                <Link to="/event">
                    <IconButton className={classes.floatIcon}>
                        <ClearIcon fontSize="small" style={{ color: "#515151" }} />
                    </IconButton>
                </Link>

            </Paper>

            <BackDrop open={open} className={classes.backdrop2}>
                <Paper variant="outlined" className={classes.line} style={{ width: '60%', padding: '24px', position: 'relative' }}>
                    <Typography>Categories to choose</Typography>
                    {services.map((ser, i) => (
                        <Chip
                            key={i}
                            color={ser.isPrimary ? "primary" : "default"}
                            style={{
                                cursor: 'pointer',
                                // borderColor:ser?.bgColor,
                                textShadow: '0px 0px 3px white',
                                // border: `2px solid ${ser?.bgColor}`,
                                border: '2px solid black',
                                backgroundColor: ser?.bgColor,
                             }}
                            onClick={e => handleSelectPrimary(i)}
                            label={ser.name}
                        />
                    ))}
                    <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={e => setOpen(false)}
                        >Ok</Button>
                    </Grid>
                </Paper>
            </BackDrop>


            <BackDrop open={openAddOn} className={classes.backdrop2}>
                <Paper variant="outlined" className={classes.line} style={{ width: '60%', padding: '24px', position: 'relative' }}>
                    <Typography>Select AddOn</Typography>
                    {services.map((ser, i) => (
                        !ser.isPrimary && (
                            <Chip
                                key={i}
                                color={ser.isAddOn ? "primary" : "default"}
                                style={{
                                    cursor: 'pointer',
                                    // borderColor:ser?.bgColor,
                                    textShadow: '0px 0px 3px white',
                                    // border: `2px solid ${ser?.bgColor}`,
                                    border: '2px solid black',
                                    backgroundColor: ser?.bgColor,
                                }}
                                onClick={e => handleSelectAddOn(i)}
                                label={ser.name} />
                        )
                    ))}
                    <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={e => setopenAddOn(false)}
                        >Ok</Button>
                    </Grid>
                </Paper>
            </BackDrop>
            <Snackbar open={error.open} autoHideDuration={3000} onClose={e => seterror({ open: false, message: 'Error' })}>
                <Alert severity="error">{error.message}</Alert>
            </Snackbar>
        </BackDrop>
    )
}

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    backdrop2: {
        zIndex: theme.zIndex.drawer + 2,
    },
    line: {
        '&>*': {
            marginTop: '1rem'
        }
    },
    floatIcon: {
        position: 'absolute',
        right: -11,
        top: -11,
        color: "#515151",
        width: '1.4rem',
        backgroundColor: 'white',
        height: '1.4rem', padding: 4
    },
    cancelIcon: {
        position: 'absolute',
        right: -10,
        top: -10,
        color: 'lightgrey'
    }
});


export default withRouter(withStyles(styles)(AddEvent));