import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';





export const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        // backgroundColor: theme.palette.background.paper,
        border: '2px solid rgba(0,0,0,0.2)',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


const MaterialSelect = ({ title, list, types = false, onChange, value, style, selectStyle }) => {
    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', ...style }}>
            {/* <label htmlFor={title}>
                {title}
            </label> */}


            <InputLabel shrink htmlFor={title}>
                {title}</InputLabel>
            {types ? (
                <Select
                    id={title}
                    style={{ marginTop: 5, ...selectStyle }}
                    value={value}
                    onChange={onChange}
                    input={<BootstrapInput />}
                >
                    <MenuItem value={0} selected disabled hidden>Add select field</MenuItem>
                    <MenuItem value={1}>Single Select</MenuItem>
                    <MenuItem value={2}>Multi Select</MenuItem>
                    <MenuItem value={3}>Date</MenuItem>
                    <MenuItem value={4}>Date Range</MenuItem>
                    <MenuItem value={5}>Time</MenuItem>
                    <MenuItem value={6}>Time Range</MenuItem>
                    <MenuItem value={7}>Range</MenuItem>
                    <MenuItem value={8}>Location</MenuItem>
                </Select>
            ) : (
                    <Select
                        id={title}
                        style={{ marginTop: 5, ...selectStyle }}
                        value={value}
                        onChange={onChange}
                        input={<BootstrapInput />}
                    >
                        {list && list.map((l, i) => (<MenuItem key={i} value={l}>{l}</MenuItem>))}
                    </Select>
                )
            }

        </Grid>
    );
}

export default MaterialSelect;







