import React,{ useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Button,
  lighten,
  Typography
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {BASE_API_URL} from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
import './../../styles/cssformui.css';

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell style={{ fontWeight: 'bold'}}>Id</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Id</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>User name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Email</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Mobile</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event date</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Next due date</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Total Amount</StyledTableCell>        
        <StyledTableCell style={{ fontWeight: 'bold'}}>Paid Amount</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Pending Amount</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ value, onChange, onButtonClicked }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Pending Amount
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            startAdornment={<SearchIcon />}
            value={value}
            onChange={onChange}
            placeholder="Search"
            style={{ marginRight: '10px' }}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
            labelWidth={0}
          />
        </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function BookedEvents({ classes }) {
  const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sendReminder = async (eventId) => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      `${BASE_API_URL}send-pending-amount-reminder?userEventId=${eventId}`, options);
  }

  const getPendingPayments = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      `${BASE_API_URL}get-pending-amount-report?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}`, options);
    const data = response.json(); 
    
    data.then((data) => { 
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setRows(data.data);
      setcount(data.data.length);
      setLoading(false);
    })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getPendingPayments();
    // eslint-disable-next-line
  }, [search, create,rowsPerPage, page ]);

  return (
    <>
     {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
    <Paper style={{ margin: 10 }}>
    <EnhancedTableToolbar onButtonClicked={e => setcreate(true)} value={search} onChange={e => {setsearch(e.target.value); setPage(0)}} />
    <Divider />
    <TableContainer>
                <Table >
                  <EnhancedTableHead />
                  <TableBody>
                  {rows.map((row, index) => {
                      return (
                      <StyledTableRow>
                        <StyledTableCell align='left'>{page*rowsPerPage+index+1}</StyledTableCell> 
                        <StyledTableCell align='left'>{row.sysEventId}</StyledTableCell> 
                        <StyledTableCell align='left'><Link to={`/booked-event-details/${row._id}`} href="_blank">{row.eventName}</Link></StyledTableCell> 
                        <StyledTableCell align='left'>{row.user[0].firstName}</StyledTableCell> 
                        <StyledTableCell align='left'>{row.user[0].email}</StyledTableCell> 
                        <StyledTableCell align='left'>{row.user[0].phoneNumber}</StyledTableCell>
                        <StyledTableCell align='left'>{moment(row.eventDate).format('MMM DD YYYY')}</StyledTableCell>
                        <StyledTableCell align='left'>{moment(row.eventDate).subtract(2, "days").format('MMM DD YYYY')}</StyledTableCell>
                        <StyledTableCell align='left'>AED {row.eventLog[0].totalPrice}</StyledTableCell> 
                        <StyledTableCell align='left'>AED {row.eventLog[0].intialPaymentPaid}</StyledTableCell> 
                        <StyledTableCell align='left'>AED {row.eventLog[0].totalPrice - row.eventLog[0].intialPaymentPaid}</StyledTableCell> 
                        <StyledTableCell align='left' ><button className="btn-orange" onClick={()=>{sendReminder(row._id)}}>Send mail</button></StyledTableCell>
                      </StyledTableRow> 
                    );
                  })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
    </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})