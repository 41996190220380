import { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { AddCircleTwoTone, CancelTwoTone, Close } from "@material-ui/icons";

import BootstrapButton from "./BootstrapButton";
import QuestionBox from "./QuestionBox";
import userContext from "../context/userContext";
import PlusTextField from "./PlusTextField";
import { getURL } from "../util";
import searchContext from "../context/searchContext";

export default function Modal({
  type,
  value,
  children,
  buttonText,
  page,
  setReload,
}) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(value === undefined ? [] : value);
  const [serviceType, setServiceType] = useState("Professional");
  const [questionBox, setQuestionBox] = useState(
    value === undefined ? [] : value.questions
  );
  const [selectedFilterArray, setselectedFilterArray] = useState([]);
  const [newFilter, setNewFilter] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filterValueArray, setFilterValueArray] = useState(
    data.type === 1
      ? data.singleSelect
        ? data.singleSelect
        : []
      : data.multiSelect
        ? data.multiSelect
        : []
  );
  const [minRange, setMinRange] = useState("");
  const [maxRange, setMaxRange] = useState("");
  const [serviceFilters, setServiceFilters] = useState([]);
  const [editServiceFilters, setEditServiceFilters] = useState(
    value === undefined ? [] : value.filters
  );
  const { userData } = useContext(userContext);
  const { search } = useContext(searchContext);
  const classes = useStyles();

  const handleSelectChange = (event) => {
    setData((old) => ({
      ...old,
      type: event.target.value,
    }));
  };

  const handleOpen = () => {
    if (page === "services") {
      const getFilters = async () => {
        let options = {
          method: "GET",
          headers: {
            Authorization: userData.token,
          },
        };
        const response = await fetch(
          getURL("https://api.eventstan.com/admin/events-filters", search),
          options
        );
        const data = response.json();
        data
          .then((data) => {
            setServiceFilters(data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      getFilters();
    }
    setOpen(true);
  };

  const handleClose = () => {
    setReload((old) => {
      return !old;
    });
    setOpen(false);
  };

  const handleChange = (event) => {
    setData((old) => ({
      ...old,
      name: event.target.value,
    }));
  };

  const handleFilterChange = () => {
    setselectedFilterArray((old) => {
      return [...old, newFilter];
    });
  };

  const handleFilterTextChange = (event) => {
    setNewFilter(event.target.value);
  };

  const handleRemoveFilter = (f) => {
    let index = selectedFilterArray.indexOf(f);
    setselectedFilterArray((old) => {
      let value = [...old];
      value.splice(index, 1);
      return value;
    });
  };

  const handleEditRemoveFilter = (f) => {
    let index = editServiceFilters.indexOf(f);
    setEditServiceFilters((old) => {
      let value = [...old];
      value.splice(index, 1);
      return value;
    });
  };

  const handleAddQuestion = () => {
    setQuestionBox((old) => [
      ...old,
      {
        _id: old.length,
        ques: "",
        value: [""],
      },
    ]);
  };
  const handleMinRangeChange = (event) => {
    setMinRange(event.target.value);
  };

  const handleMaxRangeChange = (event) => {
    setMaxRange(event.target.value);
  };

  const typeToNameFilter = (f) => {
    for (const i of serviceFilters) {
      if (i.name === f) {
        return f;
      }
    }
  };

  const typeToIdFilter = (f) => {
    for (const i of serviceFilters) {
      if (i.name === f) {
        return i._id;
      }
    }
  };

  const idArray = selectedFilterArray.map((value) => {
    return typeToIdFilter(value);
  });

  const handleFilterSubmit = async () => {
    if (type === "Edit") {
      const formdata = new FormData();
      formdata.append("filterId", data._id);
      formdata.append("name", data.name);
      formdata.append("type", data.type);

      data.type === 1
        ? formdata.append("singleSelect", filterValueArray)
        : data.type === 2
          ? formdata.append("multiSelect", filterValueArray)
          : console.log("not 1 and 2");
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/events-filters",
        options
      );
      const filterResult = response.json();
      filterResult.catch((error) => console.log(error));
      window.location.reload();
      handleClose();
    } else if (type === "Delete") {
      const formdata = new FormData();
      formdata.append("filterId", data._id);
      formdata.append("name", data.name);
      formdata.append("type", data.type);
      formdata.append("isDeleted", true);
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/events-filters",
        options
      );
      const filterResult = response.json();
      filterResult.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else if (type === "Add") {
      const formdata = new FormData();
      formdata.append("name", data.name);
      formdata.append("type", data.type);
      data.type === 1
        ? formdata.append("singleSelect", JSON.stringify(filterValueArray))
        : data.type === 2
          ? formdata.append("multiSelect", JSON.stringify(filterValueArray))
          : console.log(null);
      if (data.type === 7) {
        formdata.append("membersMinRange", minRange);
        formdata.append("membersMaxRange", maxRange);
      }
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/events-filters",
        options
      );
      const filterResult = response.json();
      filterResult.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleServicesSubmit = async () => {
    if (type === "Edit") {
      const formdata = new FormData();
      formdata.append("serviceId", data._id);
      formdata.append("name", data.name);
      formdata.append("type", serviceType);
      formdata.append("questions", JSON.stringify(questionBox));
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/service-types",
        options
      );
      const result = response.json();
      result.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else if (type === "Delete") {
      const formdata = new FormData();
      formdata.append("serviceId", data._id);
      formdata.append("name", data.name);
      formdata.append("isDeleted", true);
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/service-types",
        options
      );
      const result = response.json();
      result.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else if (type === "Add") {
      const formdata = new FormData();
      formdata.append("name", data.name);
      formdata.append("type", serviceType);
      formdata.append("filters", JSON.stringify(idArray));
      formdata.append(
        "questions",
        JSON.stringify(
          questionBox.map((q) => {
            if (typeof q._id === "number") {
              return {
                ques: q.ques,
                value: q.value,
              };
            } else {
              return q;
            }
          })
        )
      );
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/service-types",
        options
      );
      const result = response.json();
      result.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else {
      handleClose();
    }
  };

  const handleSubmit = async () => {
    if (type === "Edit") {
      const formdata = new FormData();
      formdata.append("categoryId", data._id);
      formdata.append("name", data.name);
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/event-categories?lang=en",
        options
      );
      const result = response.json();
      result.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else if (type === "Delete") {
      const formdata = new FormData();
      formdata.append("categoryId", data._id);
      formdata.append("name", data.name);
      formdata.append("isDeleted", true);
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/event-categories?lang=en",
        options
      );
      const result = response.json();
      result.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else if (type === "Add") {
      const formdata = new FormData();
      formdata.append("name", data.name);
      let options = {
        method: "POST",
        headers: {
          Authorization: userData.token,
        },
        body: formdata,
      };
      const response = await fetch(
        "https://api.eventstan.com/admin/event-categories?lang=en",
        options
      );
      const result = response.json();
      result.catch((error) => console.log(error));
      setReload((old) => {
        return !old;
      });
      handleClose();
    } else {
      handleClose();
    }
  };

  let disabled = type === "View" || type === "Delete" ? true : false;

  return (
    <>
      {children ? (
        <div onClick={handleOpen}>{children}</div>
      ) : (
          <BootstrapButton
            variant='contained'
            color='primary'
            onClick={handleOpen}
          >
            {buttonText}
          </BootstrapButton>
        )}

      <Dialog
        open={open}
        fullWidth={buttonText === "Add Services" ? true : false}
        maxWidth='md'
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
      >
        <div className={classes.title}>
          <DialogTitle id='form-dialog-title'>
            <span>{buttonText}</span>
          </DialogTitle>
          <IconButton
            className={classes.closeButton}
            onClick={handleClose}
            color='primary'
          >
            <Close />
          </IconButton>
        </div>
        <div
          style={{ border: "0.25px solid rgba(0, 0, 0, 0.1)", width: "100%" }}
        />
        {buttonText === "Add Filter" ? (
          <DialogContent>
            <FormLabel style={{ marginTop: "1rem" }}>Filter Name</FormLabel>
            <TextField
              style={{ margin: "1rem 0" }}
              margin='dense'
              id='filter_name'
              variant='outlined'
              fullWidth
              className={classes.textField}
              disabled={disabled}
              onChange={handleChange}
            />
            <FormLabel style={{ marginTop: "0.5rem" }}>Filter Type</FormLabel>
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              value={data ? data.type : ""}
              onChange={handleSelectChange}
              fullWidth
              style={{ height: "2rem", margin: "1rem 0" }}
            >
              <MenuItem value={1}>Single Select</MenuItem>
              <MenuItem value={2}>Multi Select</MenuItem>
              <MenuItem value={3}>Date</MenuItem>
              <MenuItem value={4}>Date Range</MenuItem>
              <MenuItem value={5}>Time</MenuItem>
              <MenuItem value={6}>Time Range</MenuItem>
              <MenuItem value={7}>Range Slider</MenuItem>
              <MenuItem value={8}>Location</MenuItem>
            </Select>

            {data.type === 1 || data.type === 2 ? (
              <>
                <FormLabel style={{ marginTop: "0.5rem" }}>Value</FormLabel>
                <PlusTextField
                  edit={filterValueArray}
                  setEdit={setFilterValueArray}
                  typedValue={filterValue}
                  setTypedValue={setFilterValue}
                />
              </>
            ) : data.type === 7 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormLabel style={{ marginTop: "0.5rem" }}>Min</FormLabel>
                  <TextField
                    style={{ margin: "1rem 0" }}
                    margin='dense'
                    id='filter_name'
                    variant='outlined'
                    className={classes.textField}
                    disabled={disabled}
                    onChange={handleMinRangeChange}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormLabel style={{ marginTop: "0.5rem" }}>Max</FormLabel>
                  <TextField
                    style={{ margin: "1rem 0" }}
                    margin='dense'
                    id='filter_name'
                    variant='outlined'
                    className={classes.textField}
                    disabled={disabled}
                    onChange={handleMaxRangeChange}
                  />
                </div>
              </div>
            ) : null}
          </DialogContent>
        ) : buttonText === "Add Services" ? (
          <Grid container style={{ padding: "1rem" }}>
            <Grid item xs={12} sm={6}>
              <DialogContent>
                <FormLabel style={{ marginTop: "1rem" }}>Name</FormLabel>
                <TextField
                  style={{ margin: "1rem 0" }}
                  margin='dense'
                  id='name_text'
                  variant='outlined'
                  fullWidth
                  className={classes.textField}
                  disabled={disabled}
                  onChange={handleChange}
                  placeholder='Catering...'
                />
                <FormLabel style={{ marginTop: "0.5rem" }}>Type</FormLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "1rem 0",
                  }}
                >
                  <div
                    style={
                      serviceType === "Professional"
                        ? {
                          border: "0.5px solid #f47824",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#ffdbc3",
                          color: "#f47824",
                        }
                        : {
                          border: "0.5px solid rgba(0,0,0,0.3)",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#f6f5f7",
                        }
                    }
                    onClick={() => setServiceType("Professional")}
                  >
                    Professional
                  </div>
                  <div
                    style={
                      serviceType === "Service"
                        ? {
                          border: "0.5px solid #f47824",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#ffdbc3",
                          color: "#f47824",
                        }
                        : {
                          border: "0.5px solid rgba(0,0,0,0.3)",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#f6f5f7",
                        }
                    }
                    onClick={() => setServiceType("Service")}
                  >
                    Service
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormLabel style={{ marginTop: "0.5rem" }}>Filters</FormLabel>
                  {newFilter !== "" && (
                    <AddCircleTwoTone
                      style={{ color: "#f47824", cursor: "pointer" }}
                      onClick={handleFilterChange}
                    />
                  )}
                  {/* line to add */}

                </div>
                <Select
                  labelId='demo-simple-select-filled-label'
                  id='demo-simple-select-filled'
                  value={newFilter}
                  onChange={handleFilterTextChange}
                  fullWidth
                  style={{ height: "2rem", margin: "1rem 0" }}
                >
                  {serviceFilters.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value.name}>
                        {value.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                    flexWrap: "wrap",
                  }}
                >
                  {selectedFilterArray.map((f, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          borderRadius: "0.2rem",
                          backgroundColor: "#8489b2",
                          padding: "0.5rem 1rem",
                          marginRight: "1rem",
                          marginBottom: "1rem",
                          color: "white",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: -10,
                            right: -10,
                            color: "black",
                            cursor: "pointer",
                          }}
                          onClick={() => handleRemoveFilter(f)}
                        >
                          <CancelTwoTone color='action' />
                        </div>
                        {typeToNameFilter(f)}
                      </div>
                    );
                  })}
                </div>
              </DialogContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DialogContent>
                <FormLabel style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "0.5rem" }}>Questions</span>
                  <AddCircleTwoTone
                    style={{ color: "#f47824", cursor: "pointer" }}
                    onClick={handleAddQuestion}
                  />
                </FormLabel>
                {questionBox.map((value, index) => {
                  return (
                    <QuestionBox
                      key={index}
                      disabled={disabled}
                      setQuestionBox={setQuestionBox}
                      value={value}
                      index={index}
                    />
                  );
                })}
              </DialogContent>
            </Grid>
          </Grid>
        ) : page === "filter" ? (
          <DialogContent>
            <TextField
              margin='dense'
              id='name'
              variant='outlined'
              fullWidth
              className={classes.textField}
              disabled={disabled}
              value={data ? data.name : ""}
              onChange={handleChange}
              style={{ marginBottom: "1rem" }}
            />
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              value={data ? data.type : ""}
              onChange={handleSelectChange}
              disabled={disabled}
              fullWidth
              style={{ height: "2rem", margin: "1rem 0" }}
            >
              <MenuItem value={1}>Single Select</MenuItem>
              <MenuItem value={2}>Multi Select</MenuItem>
              <MenuItem value={3}>Date</MenuItem>
              <MenuItem value={4}>Date Range</MenuItem>
              <MenuItem value={5}>Time</MenuItem>
              <MenuItem value={6}>Time Range</MenuItem>
              <MenuItem value={7}>Range Slider</MenuItem>
              <MenuItem value={8}>Location</MenuItem>
            </Select>
            {data.type === 1 || data.type === 2 ? (
              <>
                <FormLabel style={{ marginTop: "0.5rem" }}>Value</FormLabel>
                {data.type === 1
                  ? data.singleSelect.map((filter) => {
                    return (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <TextField
                          margin='dense'
                          id='name'
                          variant='outlined'
                          className={classes.textField}
                          disabled={disabled}
                          value={filter}
                          onChange={handleChange}
                          style={{ marginBottom: "1rem" }}
                        />
                      </div>
                    );
                  })
                  : data.multiSelect.map((filter) => {
                    return (
                      <div
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <TextField
                          margin='dense'
                          id='name'
                          variant='outlined'
                          className={classes.textField}
                          disabled={disabled}
                          value={filter}
                          onChange={handleChange}
                          style={{ marginBottom: "1rem" }}
                        />
                      </div>
                    );
                  })}
                {type === "View" ? null : (
                  <PlusTextField
                    edit={filterValueArray}
                    setEdit={setFilterValueArray}
                    typedValue={filterValue}
                    setTypedValue={setFilterValue}
                  />
                )}
              </>
            ) : data.type === 7 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "1rem",
                  }}
                >
                  <FormLabel style={{ marginTop: "0.5rem" }}>Min</FormLabel>
                  <TextField
                    style={{ margin: "1rem 0" }}
                    margin='dense'
                    id='filter_name'
                    variant='outlined'
                    className={classes.textField}
                    value={data.membersMinRange}
                    disabled={disabled}
                    onChange={handleMinRangeChange}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FormLabel style={{ marginTop: "0.5rem" }}>Max</FormLabel>
                  <TextField
                    style={{ margin: "1rem 0" }}
                    margin='dense'
                    id='filter_name'
                    variant='outlined'
                    className={classes.textField}
                    value={data.membersMaxRange}
                    disabled={disabled}
                    onChange={handleMaxRangeChange}
                  />
                </div>
              </div>
            ) : null}
          </DialogContent>
        ) : page === "services" ? (
          <Grid container style={{ padding: "1rem" }}>
            <Grid item xs={12} sm={6}>
              <DialogContent>
                <FormLabel style={{ marginTop: "1rem" }}>Name</FormLabel>
                <TextField
                  style={{ margin: "1rem 0" }}
                  margin='dense'
                  id='name_text'
                  variant='outlined'
                  fullWidth
                  className={classes.textField}
                  value={data ? data.name : ""}
                  disabled={disabled}
                  onChange={handleChange}
                  placeholder='Catering...'
                />
                <FormLabel style={{ marginTop: "0.5rem" }}>Type</FormLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "1rem 0",
                  }}
                >
                  <div
                    style={
                      serviceType === "Professional"
                        ? {
                          border: "0.5px solid #f47824",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#ffdbc3",
                          color: "#f47824",
                        }
                        : {
                          border: "0.5px solid rgba(0,0,0,0.3)",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#f6f5f7",
                        }
                    }
                    onClick={
                      disabled ? null : () => setServiceType("Professional")
                    }
                  >
                    Professional
                  </div>
                  <div
                    style={
                      serviceType === "Service"
                        ? {
                          border: "0.5px solid #f47824",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#ffdbc3",
                          color: "#f47824",
                        }
                        : {
                          border: "0.5px solid rgba(0,0,0,0.3)",
                          padding: "0.75rem 3rem",
                          cursor: "pointer",
                          fontWeight: 700,
                          backgroundColor: "#f6f5f7",
                        }
                    }
                    onClick={disabled ? null : () => setServiceType("Service")}
                  >
                    Service
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormLabel style={{ marginTop: "0.5rem" }}>Filters</FormLabel>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1rem",
                  }}
                >
                  {editServiceFilters.map((f, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          borderRadius: "0.2rem",
                          backgroundColor: "#8489b2",
                          padding: "0.5rem 1rem",
                          marginRight: "1rem",
                          marginBottom: "1rem",
                          color: "white",
                          position: "relative",
                        }}
                      >
                        {disabled ? (
                          ""
                        ) : (
                            <div
                              style={{
                                position: "absolute",
                                top: -10,
                                right: -10,
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={() => handleEditRemoveFilter(f)}
                            >
                              <CancelTwoTone color='action' />
                            </div>
                          )}
                        {f.name}
                      </div>
                    );
                  })}
                </div>
              </DialogContent>
            </Grid>
            <Grid item xs={12} sm={6}>
              <DialogContent>
                <FormLabel style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: "0.5rem" }}>Questions</span>
                  <AddCircleTwoTone
                    style={
                      disabled ? {} : { color: "#f47824", cursor: "pointer" }
                    }
                    onClick={disabled ? "" : handleAddQuestion}
                  />
                </FormLabel>
                {questionBox.map((value, index) => {
                  return (
                    <QuestionBox
                      key={index}
                      disabled={disabled}
                      setQuestionBox={setQuestionBox}
                      value={value}
                      index={index}
                    />
                  );
                })}
              </DialogContent>
            </Grid>
          </Grid>
        ) : (
                  <DialogContent>
                    <TextField
                      margin='dense'
                      id='name'
                      variant='outlined'
                      fullWidth
                      className={classes.textField}
                      disabled={disabled}
                      value={data ? data.name : ""}
                      onChange={handleChange}
                    />
                  </DialogContent>
                )}

        {buttonText === "Add Services" ? (
          <DialogActions>
            <Button
              className={classes.addButton}
              onClick={handleServicesSubmit}
              variant='contained'
              style={{
                fontSize: "1.1rem",
                fontWeight: 700,
                color: "white",
                backgroundColor: "#f47824",
                width: "40%",
                margin: "0 auto 1rem",
                padding: "1rem",
                textTransform: "capitalize",
                letterSpacing: "0.1rem",
              }}
            >
              Create Service
            </Button>
          </DialogActions>
        ) : (
            <DialogActions>
              <Button
                className={classes.addButton}
                onClick={
                  page === "filter"
                    ? handleFilterSubmit
                    : page === "services"
                      ? handleServicesSubmit
                      : handleSubmit
                }
                color={type === "Delete" ? "secondary" : "primary"}
                variant='contained'
                style={type === "View" ? { display: "none" } : {}}
              >
                {buttonText === "Add Filter" ? "Submit" : type}
              </Button>
            </DialogActions>
          )}
      </Dialog>
    </>
  );
}

const useStyles = makeStyles(() => ({
  addButton: {
    width: "100%",
    margin: 20,
  },
  closeButton: {
    paddingRight: 20,
  },
  textField: {
    backgroundColor: "#c4c4c4",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
