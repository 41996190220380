import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Button,
  lighten
} from "@material-ui/core";
import * as XLSX from 'xlsx';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { BASE_API_URL } from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteDialog from '../../components/dialog/DeleteDialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import './../../styles/cssformui.css';
import { Link } from 'react-router-dom';

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell>ID</StyledTableCell>
        <StyledTableCell>NAME</StyledTableCell>
        <StyledTableCell>EMAIL</StyledTableCell>
        <StyledTableCell>MOBILE</StyledTableCell>
        <StyledTableCell>TYPE</StyledTableCell>
        <StyledTableCell>LOCATION</StyledTableCell>
        <StyledTableCell>SERVICE</StyledTableCell>
        <StyledTableCell>Edit</StyledTableCell>
        <StyledTableCell>Delete</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ value, onChange, onButtonClicked }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Lead Data
      </Typography>

      {/*<div style={{ marginLeft: "auto" }}>
        <Modal type='Add' buttonText='Add Vendor' />
      </div>*/}
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>

        <OutlinedInput
          id="outlined-adornment-weight"
          startAdornment={<SearchIcon />}
          value={value}
          onChange={onChange}
          placeholder="Search"
          style={{ marginRight: '10px' }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
          labelWidth={0}
        />
      </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function LeadData({ classes }) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const { userData, setUserData } = useContext(userContext);
  const [count, setcount] = useState(0);
  const [create, setcreate] = useState(false);
  const [delete1, setdelete] = useState(null);
  const [error, setError] = useState(null);
  const [update, setupdate] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getVendor = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      `${BASE_API_URL}lead-data-list?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}`, options);

    const data = response.json();

    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      console.log(data)
      setRows(data.data.result);
      setcount(data.data.userCount);
      setLoading(false);
    })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getVendor();
    // eslint-disable-next-line
  }, [search, create, rowsPerPage, page]);

  const handleServiceDelete = async () => {
    // let formdata = new FormData();
    // formdata.append("vendorId", delete1._id);
    // formdata.append("isDeleted", true);

    let formdata = {
      vendorId: delete1._id,
      isDeleted: true
    };

    try {
      const response = await fetch(
        `${BASE_API_URL}delete-vendor`,
        {
          method: "POST",
          headers: {
            Authorization: userData.token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(formdata)
        }
      );
      const result = await response.json();
      setdelete(null)
      getVendor()
    } catch (e) {
      console.log(e)
      setError('delete Failed')
    }
  }

  const exportToExcel = () => {
    console.log('exportToExcel started');
    if (!rows || rows.length === 0) {
      console.error('No data to export!');
      return;
    }

    // Filter the rows to include only the required fields and add an 'ID' column
    const filteredData = rows.map((row, index) => ({
      'ID': index + 1,
      'Name': (row.firstName+row.lastName) || 'N/A',
      'Email': row.email || 'N/A',
      'Mobile': row.phoneNumber || 'N/A',
      'Type': row.user_type || 'N/A',
      'Location': row.location || 'N/A',
      'Service': row.specilization || 'N/A',
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

    XLSX.writeFile(workbook, "lead_data.xlsx");
    console.log('exportToExcel ended');
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Paper style={{ margin: 10 }}>
          <EnhancedTableToolbar onButtonClicked={e => setcreate(true)} value={search} onChange={e => { setsearch(e.target.value); setPage(0) }} />
          <Divider />
          <TableContainer>
            <Table >
              <EnhancedTableHead />

              <TableBody>
                {rows.map((row, index) => {
                  return (
                    <StyledTableRow
                      key={row.name}>
                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> {page * rowsPerPage + index + 1}</StyledTableCell>
                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.firstName} {row.lastName}</StyledTableCell>
                      <StyledTableCell align='left'>{row.email}</StyledTableCell>
                      <StyledTableCell align='left'>{row.phoneNumber}</StyledTableCell>
                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.user_type}</StyledTableCell>
                      <StyledTableCell align='left'>{row.location}</StyledTableCell>
                      <StyledTableCell align='left'>{row.specilization}</StyledTableCell>
                      <StyledTableCell align='right' style={{ padding: 0, width: 10 }} >
                        <IconButton>
                          {row.user_type == 'VENDOR' ? <Link to={`/edit-vendor/${row._id}`}><EditOutlinedIcon style={{ color: '#bcbcbc' }} /></Link> : <Link to={`/edit-professional/${row._id}`}><EditOutlinedIcon style={{ color: '#bcbcbc' }} /></Link>}
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align='right' style={{ padding: 0, width: 10 }} >
                        <IconButton onClick={e => setdelete(row)}>
                          <DeleteOutlineIcon style={{ color: '#FF0000' }} />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, count]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
          <DeleteDialog open={delete1} onOkClick={handleServiceDelete} onCancelClick={e => setdelete(null)} what={'data'} />
          <Snackbar open={error} autoHideDuration={3000} onClose={e => setError(null)}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: '10px' }}
          >
            Export to Excel
          </Button>
        </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})
