
import React, { useState, useContext, useEffect } from 'react';
import EventDialog from '../../components/dialog/EventDialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditText from '../../components/small/EditText';
import Select from '../../components/small/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import AddCircleTwoTone from '@material-ui/icons/AddCircleTwoTone';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import userContext from "../../context/userContext";
import { BASE_API_URL } from '../../constant'



const ReviewAdd = ({ classes, open, onClick, update }) => {
    const { userData } = useContext(userContext);

    // const [name, setname] = useState('');
    const [userName, setUserName] = useState('');
    const [reviewText, setReviewText] = useState('');
    const [rating, setRating] = useState(1);
    const [company, setCompany] = useState('');
    const [city, setCity] = useState('');
    const [apprStatus, setAppr] = useState(false);
    useEffect(()=>{
        // console.log({update})
        setUserName(update?.userName)
        setReviewText(update?.reviewText)
        setRating(update?.rating)
        setCompany(update?.company)
        setCity(update?.city)
        setAppr(update?.isApproved)

    },[update])


    const clearForm = ()=>{
        setUserName("")
        setReviewText("")
        setRating(1)
        setCompany("")
        setCity("")
        setAppr(false)
    }

    const [error, setError] = useState({
        open: false,
        message: 'Error'
    });

    const checkValidation = () => { 
        if (userName.length === 0) {
            setError({ open: true, message: 'User Name Should not be empty' })
            return false
        } else  if (reviewText.length === 0) {
            setError({ open: true, message: 'Review Text Should not be empty' })
            return false
        } else  if (isNaN(rating) || rating > 5 || rating < 1) {
            setError({ open: true, message: 'Numbers between 1 and 5 only is accepted as rating' })
            return false
        } 
        // else  if (company.length === 0) {
        //     setError({ open: true, message: 'Company Name Should not be empty' })
        //     return false
        // } 
        else  if (apprStatus.length === 0) {
            setError({ open: true, message: 'Please Select Approval Status' })
            return false
        }
        // else  if (city.length === 0) {
        //     setError({ open: true, message: 'City is not allowed to be empty' })
        //     return false
        // }
        return true
    }

    const handleFinalSubmit = async () => {
        if (checkValidation()) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer "+ userData.token);

            var raw = JSON.stringify({
                "userName": userName,
                "reviewText": reviewText,
                "city": city,
                "rating": rating,
                "company": company,
                "isApproved": apprStatus,
                "filterId" : update ? update?._id : false

            });
           /* if (update) {
                raw.append("rateReviewId", update._id)
            }*/

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };


            const response = await fetch(`${BASE_API_URL}rate-review`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log({result})

                    if (result.statusCode == '409') {
                        setError({ open: true, message: 'Already exists' })
                    } else {
                        // setError({ open: true, message: 'Review Added' })
                        alert(update ? 'Review Updated' : 'Review Added')
                        handleOnClose()
                    }

                })
                .catch(error => console.log('error', error));

        }
    }
    const handleOnClose = () => {
        onClick()
        clearForm()
    }

    return (
        <EventDialog open={open} onClick={handleOnClose} style={{ width: '30%' }}>
            <Grid className={classes.list}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Add Review</Typography>
                <EditText label="User Name" value={userName} onChange={e => setUserName(e.target.value)} rootStyle={{ width: '100%' }} />
                <EditText label="Review Text" value={reviewText} onChange={e => setReviewText(e.target.value)} rootStyle={{ width: '100%' }} />
                <EditText label="Rating" type={'number'} value={rating} onChange={e => setRating(e.target.value)} rootStyle={{ width: '100%' }} />
                <EditText label="Company" value={company} onChange={e => setCompany(e.target.value)} rootStyle={{ width: '100%' }} />
                <EditText label="City" value={city} onChange={e => setCity(e.target.value)} rootStyle={{ width: '100%' }} />
                <Select
                    value={apprStatus ? 'Approved' : 'Not Approved'}
                    list={
                        ['Approved','Not Approved']
                    }
                    onChange={e => setAppr(e.target.value === 'Approved')}
                    // types={true}
                    title="Approval Status"
                />

            </Grid>
            <Button
                variant="contained"
                disableElevation
                color="primary"
                size="large"
                onClick={handleFinalSubmit}
                style={{ width: '100%', marginTop: '2rem', textTransform: 'none' }}>
                {update ? "Update" : "Create"} Review
            </Button>

            <Snackbar open={error.open} autoHideDuration={3000} onClose={e => setError({ ...error, open: false })}>
                <Alert severity="error">{error.message}</Alert>
            </Snackbar>
        </EventDialog>
    );
}

const styles = theme => ({
    list: {
        maxHeight: '60vh',
        overflow: 'auto',
        overflowX: 'hidden',
        '&>*': {
            marginTop: '0.75rem'
        }
    }
})

export default withStyles(styles)(ReviewAdd);
