import React from 'react';
import Paper from '@material-ui/core/Paper';
import BackDrop from '@material-ui/core/Backdrop';
import withStyles from '@material-ui/core/styles/withStyles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';


const EventDialog = ({ classes, open, children, onClick, style }) => {
    return (
        <BackDrop open={open} className={classes.backdrop}>
            <Paper style={{ width: '60%', padding: 40, position: 'relative', ...style }}>
                {children}
                <IconButton onClick={onClick} className={classes.floatIcon}>
                    <ClearIcon fontSize="small" style={{ color: "#515151" }} />
                </IconButton>
            </Paper>
        </BackDrop>
    );
}

const styles = theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
    },
    floatIcon: {
        position: 'absolute',
        top: -10,
        right: -10,
        backgroundColor: 'lightGrey',
        width: '1.5rem',
        height: '1.5rem'
    },
})

export default withStyles(styles)(EventDialog);
