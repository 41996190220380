
import React, { useState, useContext, useEffect } from 'react';
import EventDialog from '../components/dialog/EventDialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EditText from '../components/small/EditText';
import EventSelect from '../components/small/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import AddCircleTwoTone from '@material-ui/icons/AddCircleTwoTone';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import userContext from "../context/userContext";
import { BASE_API_URL } from '../constant'



const FilterAdd = ({ classes, open, onClick, update }) => {
    const { userData } = useContext(userContext);
    const [type1, settype] = useState(0);
    const [name, setname] = useState('');
    const [values, setvalues] = useState([""]);
    const [minMax, setminMax] = useState({
        min: '',
        max: ''
    });
    const [error, setError] = useState({
        open: false,
        message: 'Error'
    });


    const handleValueChange = (value, i) => {
        const v = [...values];
        v[i] = value;
        setvalues(v)
    }

    const checkValidation = () => {
        if (name.length === 0) {
            setError({ open: true, message: 'Name Sould not be empty' })
            return false
        } else if (type1 === '' && type1) {
            setError({ open: true, message: 'Type Select is must' })
            return false
        }
        else if (type1 === 1 || type1 === 2) {
            for (let val of values) {
                if (val === '') {
                    setError({ open: true, message: 'Values Sould not be empty' })
                    return false
                }
            }
        } else if (type1 === 7) {
            if (minMax.min.length === 0) {
                setError({ open: true, message: 'Min Sould not be empty' })
                return false
            } else if (minMax.max.length === 0) {
                setError({ open: true, message: 'Max Sould not be empty' })
                return false
            }
        }
        return true
    }

    const handleFinalSubmit = async () => {
        if (checkValidation()) {
            const formdata = new FormData();
            formdata.append("name", name);
            formdata.append("type", type1);
            if (update) {
                formdata.append("filterId", update._id)
            }
            type1 === 1
                ? formdata.append("singleSelect", JSON.stringify(values))
                : type1 === 2
                    ? formdata.append("multiSelect", JSON.stringify(values))
                    : console.log(null);
            if (type1 === 7) {
                formdata.append("membersMinRange", minMax.min);
                formdata.append("membersMaxRange", minMax.max);
            }
            let options = {
                method: "POST",
                headers: {
                    Authorization: userData.token,
                },
                body: formdata,
            };

            const response = await fetch(
                `${BASE_API_URL}events-filters`,
                options
            );
            const res = await response;

            if (response.status == '409') {
                setError({ open: true, message: 'Already exists' })
            } else {
                handleOnClose()
            }

            const filterResult = await response.json();

        }
    }

    const handleAdornClick = i => {
        const val = [...values];
        val.splice(i, 1);
        setvalues(val)
    }

    const handleAddMoreClicked = () => {
        setvalues([...values, ""])
    }

    const handleOnClose = () => {
        setname('')
        setvalues([""])
        setminMax({
            min: '',
            max: ''
        })
        settype(0)
        onClick()
    }


    useEffect(() => {
        if (update) {
            settype(update.type);
            setname(update.name);
            setminMax({
                min: update.membersMinRange,
                max: update.membersMaxRange
            })
            if (update.type === 1) {
                setvalues(update.singleSelect)
            }
            if (update.type === 2) {
                setvalues(update.multiSelect)
            }
        }
    }, [open])


    return (
        <EventDialog open={open} onClick={handleOnClose} style={{ width: '30%' }}>
            <Grid className={classes.list}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Add Filter</Typography>
                <EditText label="NAME" value={name} onChange={e => setname(e.target.value)} rootStyle={{ width: '100%' }} />
                <EventSelect value={type1} onChange={e => settype(e.target.value)} types={true} title="TYPE" />

                {(type1 === 1 || type1 === 2 || type1 === 7) && (
                    <>
                        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>Value</Typography>
                            {values[values.length - 1] !== '' && (
                                <AddCircleTwoTone
                                    onClick={handleAddMoreClicked}
                                    style={{ color: "#f47824", cursor: "pointer" }}
                                />
                            )}

                        </Grid>
                        {(type1 === 1 || type1 === 2) && (
                            values.map((value, i) => (
                                <EditText
                                    label="NAME"
                                    key={`edit${i}`}
                                    value={value}
                                    deleteIcon={true}
                                    onAdornClick={e => handleAdornClick(i)}
                                    onChange={e => handleValueChange(e.target.value, i)}
                                    rootStyle={{ width: '100%' }} />
                            ))

                        )}
                        {type1 === 7 && (
                            <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <EditText placeholder="Min" type="number" value={minMax.min} onChange={e => { setminMax({ ...minMax, min: Math.abs(e.target.value) }) }} rootStyle={{ width: '40%' }} />
                                <EditText placeholder="Max" type="number" value={minMax.max} onChange={e => { setminMax({ ...minMax, max: Math.abs(e.target.value) }) }} rootStyle={{ width: '40%' }} />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
            <Button
                variant="contained"
                disableElevation
                color="primary"
                size="large"
                onClick={handleFinalSubmit}
                style={{ width: '100%', marginTop: '2rem', textTransform: 'none' }}>
                {update ? "Update" : "Create"} Filter
                   </Button>

            <Snackbar open={error.open} autoHideDuration={3000} onClose={e => setError({ ...error, open: false })}>
                <Alert severity="error">{error.message}</Alert>
            </Snackbar>
        </EventDialog>
    );
}

const styles = theme => ({
    list: {
        maxHeight: '60vh',
        overflow: 'auto',
        overflowX: 'hidden',
        '&>*': {
            marginTop: '0.75rem'
        }
    }
})

export default withStyles(styles)(FilterAdd);
