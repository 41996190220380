import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import { BASE_API_URL } from '../../constant';
import userContext from "../../context/userContext";
import { uploadImageToS3 } from '../../aws/awsUploader';
import {Link,useParams} from 'react-router-dom'
import moment from "moment";
import axios from "axios";

const EditVendor = () => {
    const history = useHistory();
    const { userData, setUserData } = useContext(userContext);
    const [errMessage, setErrMessage] = useState('');
    const [locations, setLocation] = useState([]);
    const [services, setServices] = useState([]);
    const [onBoard, setOnBoard] = useState(false);
    const params = useParams();
    const [user, setUser] = useState({
        email:"",
        phoneNumber:"",
        specilization:"",
        user_type:"",
        password:"",
        address:"",
        isPremium:0,
    })
    
    const [userDetails, setUserDetails] = useState({
        aboutyou:"",
        companyName:"",
	    mobile:"",
	    whereYourBusiness:"",
        vendorId:params.vendorId,
        contactPerson:"",
        contactMobile:"",
        contactEmail:"",
	    visaType:"",
	    planDetails:"",
	    planExpiry:"",
        tradeExpiry:"",
        estCardExpiry:"",
	    agreementFileUrl:"",
	    bankName:"",
	    accountName:"",
	    ibanNo:"",
	    accountNumber:"",
	    swift:"",
	    branchAddress:"",
        noOfPartners:"",

    })
    
    const setTradeExpiry = (e) => { 
        setUserDetails({...userDetails, tradeExpiry:moment.unix(e).format("X")}); 
    }
    const setPlanExpiry = (e) => { 
        setUserDetails({...userDetails, planExpiry:moment.unix(e).format("X")}); 
    }
    const setEstCardExpiry = (e) => { 
        setUserDetails({...userDetails, estCardExpiry:moment.unix(e).format("X")}); 
    }
	const toReadable = (Unix="") =>{
		return Unix === "" ? moment().format("YYYY-MM-DD") : moment.unix(Unix/1000).format("YYYY-MM-DD");
	}

    const getVendorData = async () => { 
        /*const options = {
          method: "POST",
          headers: {
            Authorization: userData.token,
            'Content-Type': 'application/json'
          },
          body : JSON.stringify({
            vendorId:params.vendorId
          })
        };
        const response = await fetch(`${BASE_API_URL}get-vendor-information`,options);
        const data = response.json(); 
        data.then((data) => {
            console.log({data})
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
          else{
            setUser(data.data) ;
            setUserDetails(data.data.vendor_info);
            if(data.data.password===undefined){
                setOnBoard(true);   
            }
          }
         
        })
        .catch((error) => console.log(error));
*/






        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${userData.token}`);

        const raw = JSON.stringify({
            "vendorId": params.vendorId
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${BASE_API_URL}get-vendor-information`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log({data})
                if (data?.statusCode === 401) {
                    localStorage.removeItem("auth-token");
                    setUserData({
                        token: "",
                    });
                }
                else{
                    setUser(data.data) ;
                    setUserDetails(data.data.vendor_info);
                    if(data.data.password===undefined){
                        // setOnBoard(true);
                    }
                }

            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        getVendorData();
        getLocations();
        getServices();
        // eslint-disable-next-line
    }, []);


    let name, value;

    const handleInputs = (e) => {
        name = e.target.name;
        value = e.target.value;
        setUser({ ...user, [name]: value });
    }

    const handleInputsDetails = (e) => { 
        name=e.target.name;
        value=e.target.value;
        setUserDetails({...userDetails, [name]:value}); 
    }

    const fileChange = evt => { 
        uploadImageToS3(evt.target.files[0],'vendor_document')
            .then(res => { setUserDetails({ ...userDetails, agreementFileUrl: res.location }) })
            .catch(err => { console.log(err) })
    }

    const handleCalendarClose = () => {
        //console.log('pragya :'+startDate);
        //const NewDate = moment(startDate).format('YYYY-MM-DD');
        //console.log('new value1 :' + NewDate);
        //setUser({planExpiry:moment(NewDate).format('YYYY-MM-DD')});   
        //user.planExpiry=moment(user.planExpiry).format('YYYY-MM-DD');
        //console.log('new value2 :'+user.planExpiry);
    }

    //Get Locations
    const getLocations = () => {
        axios.get(`${BASE_API_URL}get-locations`)
            .then(res => {
                setLocation(res.data.data);
            })
            .catch(err => { console.log(err) })
    }

    //get services
    const getServices = () => {
        const headers = {
            "Content-Type": "application/json",
            "authorization": userData.token
        }
        axios.get(`${BASE_API_URL}service-types`, { headers })
            .then(res => {
                setServices(res.data.data.result);
            })
            .catch(err => { console.log(err) })
    }

    const PostData = async (e) => { 
        e.preventDefault();
        const { 
            email,
            phoneNumber,
            specilization,
            user_type,
            password,
            address,
            isPremium,
        } = user;

        const { 
            aboutyou,
            companyName,
            mobile,
            whereYourBusiness,
            vendorId,
            contactPerson,
            contactMobile,
            contactEmail,
            planDetails,
            planExpiry,
            tradeExpiry,
            estCardExpiry,
            agreementFileUrl,
            bankName,
            accountName,
            ibanNo,
            accountNumber,
            swift,
            branchAddress,
            noOfPartners, } = userDetails;

            // console.log(user); 
            // console.log(userDetails); 
            // return false;
        /*const res = await fetch(`${BASE_API_URL}vendor-details-edit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": userData.token
            },
            body: JSON.stringify({
                isPremium,
                vendorId,
                aboutyou,
                companyName,
                email,
                phoneNumber,
                mobile,
                contactPerson,
                contactEmail,
                contactMobile,
                specilization,
                whereYourBusiness,
                tradeExpiry,
                estCardExpiry,
                address,
                planDetails,
                planExpiry,
                agreementFileUrl,
                bankName,
                accountName,
                ibanNo,
                accountNumber,
                swift,
                branchAddress,
                noOfPartners,
                user_type,
                password,
                onBoard,
            })
        });*/




        //begin jerry code
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${userData.token}`);

        const raw = JSON.stringify({
            isPremium,
            vendorId,
            aboutyou,
            companyName,
            email,
            phoneNumber,
            mobile,
            contactPerson,
            contactEmail,
            contactMobile,
            specilization,
            whereYourBusiness,
            tradeExpiry,
            estCardExpiry,
            address,
            planDetails,
            planExpiry,
            agreementFileUrl,
            bankName,
            accountName,
            ibanNo,
            accountNumber,
            swift,
            branchAddress,
            noOfPartners,
            user_type,
            password,
            onBoard,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${BASE_API_URL}vendor-details-edit`, requestOptions)
            .then((response) => response.json())
            .then((response) => {
                console.log({response})
                if (response.statusCode === 401) {
                    localStorage.removeItem("auth-token");
                    setUserData({
                        token: "",
                    });
                }
                else if (response.status === response || response.status === response) {
                    setErrMessage(response.message)
                }
                else {
                    window.alert("Vender update successfull");
                    history.push("/vendors-data");
                }
            })
            .catch((error) => console.error(error));

        //end jerry code




        /*const data = await res.json();

        if (res.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
                token: "",
            });
        }
        else if (res.status === 400 || res.status === 409) {
            setErrMessage(data.message)
        }
        else {
            window.alert("Vender update successfull");
            history.push("/vendors-data");
        }*/
    }




    return (
        <div class="container">
            <div class="row">
                <h2 class="page-title my-3 ">Edit Vendor Registration (Organization)</h2>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="border-bottom pb-2 ">
                        <div id="services" className="tab-pane active my-4">
                            <h2 className="heading">For Companies</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="aboutyou">Tell Us about yourself</label>
                                                <textarea name="aboutyou" rows="4" cols="50" class="form-control"
                                                    value={userDetails.aboutyou}
                                                    onChange={handleInputsDetails} >&nbsp;</textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="companyName">Company Name</label>
                                                <input type="text" required className="form-control" name="companyName" placeholder="Enter Company Name"
                                                    value={userDetails.companyName}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="email">(Primary)Company Email*</label>
                                                <input type="email" className="form-control" readOnly placeholder="Enter Company Email" name="email"
                                                    value={user?.email}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="mobile">Telephone</label>
                                                <input type="text" className="form-control" name="mobile" placeholder="+971"
                                                    value={userDetails.mobile}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="phoneNumber">Primary Mobile</label><br />
                                                <input type="phoneNumber" className="form-control" name="phoneNumber" readOnly
                                                    value={user.phoneNumber}
                                                    onChange={handleInputs} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="contactPerson">Contact Person Name</label>
                                                <input type="text" className="form-control" name="contactPerson" placeholder="Enter Name"
                                                    value={userDetails.contactPerson}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="contactMobile">Contact Person Mobile</label><br />
                                                <input type="mobile" className="form-control" name="contactMobile" placeholder="+971"
                                                    value={userDetails.contactMobile}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="contactEmail">Contact Person Email</label>
                                                <input type="email" className="form-control" id="contactEmail" name="contactEmail" placeholder=""
                                                    value={userDetails.contactEmail}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                    </div>
                                    { onBoard ?
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="password">Password*</label><br />
                                                <input type="text" className="form-control" id="password" name="password" placeholder=""
                                                    value={user.password}
                                                    onChange={handleInputs} />
                                                (Minimum 9 character, at least one uppercase letter, one lowercase letter, one number and one special character.)
                                            </div>
                                        </div>
                                    </div>:''}

                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="specilization">Specialization*</label>
                                                <select name="specilization"
                                                    className="form-control"
                                                    value={user.specilization}
                                                    onChange={handleInputs} >
                                                    <option>Select Specialization</option>
                                                    {services.map((ser,i) => {
                                                        return <option  value={ser.name}>{ser.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="whereYourBusiness">Where is your Business*</label>
                                                <select name="whereYourBusiness" className="form-control"
                                                    value={userDetails.whereYourBusiness}
                                                    onChange={handleInputsDetails} >
                                                    <option>Select Location</option>
                                                    {locations.map((loc,i) => {
                                                        return <option value={loc.stateName}>{loc.stateName}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="address">Address*</label><br />
                                                <input type="text" className="form-control" name="address" placeholder=""
                                                    value={user.address}
                                                    onChange={handleInputs} /><br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Company Information</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4 py-3">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="tradeExpiry">Trade License Expiry</label>
                                                <DatePicker className="form-control"
                                                minDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="dd/mm/yyyy"
                                                onChange={(date) => setTradeExpiry(date)}
                                                selected = {userDetails.tradeExpiry==="" ? null : new Date(toReadable(userDetails.tradeExpiry))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="estCardExpiry">Establishment Card Expiry</label>
                                                <DatePicker className="form-control"
                                                minDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="dd/mm/yyyy"
                                                onChange={(date) => setEstCardExpiry(date)}
                                                selected = {userDetails.estCardExpiry==="" ? null : new Date(toReadable(userDetails.estCardExpiry))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Vendor Plan</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="planDetails">Details of Plan</label>
                                                <input type="text" className="form-control" id="planDetails" name="planDetails"
                                                    value={userDetails.planDetails}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="planExpiry">Plan Expiry</label>
                                                <DatePicker className="form-control"
                                                minDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="dd/mm/yyyy"
                                                onChange={(date) => setPlanExpiry(date)}
                                                selected = {userDetails.planExpiry==="" ? null : new Date(toReadable(userDetails.planExpiry))}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="agreementFileUrl">Agreement File {userDetails.agreementFileUrl !==''?<a href={userDetails.agreementFileUrl} target="_blank">View</a>:''}</label>
                                                <input type="file" className="form-control" name="agreementFileUrl"
                                                    onChange={fileChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="heading ">Payment Bank Details</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="bankName">Bank Name</label>
                                                <input type="text" className="form-control" id="bankName" name="bankName"
                                                    value={userDetails.bankName}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="accountName">Account Full Name</label>
                                                <input type="text" className="form-control" id="accountName" name="accountName"
                                                    value={userDetails.accountName}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="ibanNo">IBAN No.</label>
                                                <input type="text" className="form-control" id="ibanNo" name="ibanNo"
                                                    value={userDetails.ibanNo}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="accountNumber">Account Number</label>
                                                <input type="text" className="form-control" id="accountNumber" name="accountNumber"
                                                    value={userDetails.accountNumber}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="swift">Swift</label>
                                                <input type="text" className="form-control" name="swift"
                                                    value={userDetails.swift}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="branchAddress">Branch Address</label>
                                                <input type="text" className="form-control" name="branchAddress"
                                                    value={userDetails.branchAddress}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-outer mb-4">
                                <h2 className="heading ">Partner Information</h2>
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-2 col-sm-6 col-xs-6">
                                            <div className="form-group">
                                                <label for="partnerexists">Is Partners Exists?</label><br />
                                                <input type="checkbox" name="uae-residence" value="yes" />&nbsp;<label for="yes">Partners</label>
                                            </div>
                                        </div>
                                        <div className="col-md-10 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <label for="noOfPartners">No of Partners</label>
                                                <input type="number" className="form-control" name="noOfPartners"
                                                    value={userDetails.noOfPartners}
                                                    onChange={handleInputsDetails} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="border-outer mb-4">
                                <h2 className="heading ">Premium Vendor</h2>
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label for="partnerexists">Is this a Premium Vendor?</label><br />
                                                <input checked={user.isPremium == 1} type="checkbox" name="uae-residence" value="yes" onChange={(e)=>{
                                                    setUser({...user,isPremium: e.target.checked ? 1 : 0})
                                                }} />&nbsp;<label for="yes">Premium Vendor</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <p style={{ color: "red" }}>{errMessage}</p>
                            <Button color="primary" disableElevation variant="contained" onClick={PostData}>Save</Button>
                            <br />
                            <hr />
                            {/* <h2 className="heading">Owners and Partners Entry</h2>
                            <div className="border-outer mb-4">
                                <div className="border-top px-4  py-3 ">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <a href="/owner-details" target="_blank">
                                                    <button className="btn-orange" >Partner 1 Entry</button>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <a href="/partner-details" target="_blank">
                                                    <button className="btn-orange" >Partner 2 Entry</button>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-xs-12">
                                            <div className="form-group">
                                                <a href="/partner-details" target="_blank">
                                                    <button className="btn-orange" >Partner 2 Entry</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>


                    </div>

                </div>
            </div>
        </div>

    );
}

export default EditVendor;