import { useContext, useEffect, useState } from "react";
import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import { getURL } from "../../util";
import userContext from "../../context/userContext";
import searchContext from "../../context/searchContext";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DeleteDialog from '../../components/dialog/DeleteDialog';
import { Link } from 'react-router-dom';
import { BASE_API_URL } from '../../constant';
import {FlipCameraAndroid} from "@material-ui/icons";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "Category",
    numeric: false,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "Primary Services",
    numeric: false,
    disablePadding: false,
    label: "Primary Services",
  },
  {
    id: "Add-Ons",
    numeric: false,
    disablePadding: false,
    label: "Add-Ons",
  },
  {
    id: "Link Filters",
    numeric: false,
    disablePadding: false,
    label: "Link Filters",
  },

  {
    id: "edit",
  },
  {
    id: "delete",
  },
];

function EnhancedTableHead({
  headCells,
}) {
  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell>S No.</StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </StyledTableCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default function Events({ reload, setReload, history, search = "",isDeleted=false }) {
  const [selected, setSelected] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData, setUserData } = useContext(userContext);
  const [forDelete, setforDelete] = useState(null);
  const [forRecycle, setforRecycle] = useState(null);
  const [count, setCount] = useState(0);
  const classes = useStyles();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const getEvents = async () => {
    let options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
        'Content-Type': 'application/json'
      },
    };
    const response = await fetch(
      `${BASE_API_URL}event-types?search=${search}${isDeleted ? '&isDeleted=true' : ''}`,
      options
    );
    const data = response.json();
    data
      .then((data) => {
        if (response.status === 401) {
          localStorage.removeItem("auth-token");
          setUserData({
            token: "",
          });
        }
        setRows(data.data.result);
        setCount(data.data.totalEvents)
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  useEffect(() => {
    getEvents();
    // eslint-disable-next-line
  }, [search, reload]);


  const handleConfirmDelete = async () => {

    let options = {
      method: "POST",
      headers: {
        Authorization: userData.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "eventId": forDelete._id,
        "isDeleted" : !isDeleted ,
      }),
    };

    try {
      const response = await fetch(
        `${BASE_API_URL}event-types`,
        options
      );

      const res = await response.json();
      getEvents()
      setforDelete(null)
    } catch (e) {
      console.log(e)
    }

  }

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Table className={classes.table}>
          {rows.length === 0 ? (
            <div
              style={{
                height: "65vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "30px",
                color: " #f47824",
                textTransform: "uppercase",
              }}
            >
              No match found
            </div>
          ) : (
            <>
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                headCells={headCells}
              />
              {rows.map((row, i) => {
                return (
                  <TableBody key={`table${i}`}>
                    <StyledTableRow>
                      <StyledTableCell align='left' width={80} style={{ fontWeight: 'bold' }}>{i + 1}</StyledTableCell>
                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.name}</StyledTableCell>
                      <StyledTableCell align='left'>
                        {row.categoryId.name}
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        {row.primary.map((value) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {value.serviceId.name}
                            </div>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        {row.addOns.map((value) => {
                          return (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              {value.serviceId.name}
                            </div>
                          );
                        })}
                      </StyledTableCell>
                      <StyledTableCell align='left'>
                        {row.filters && (
                          <Grid container>
                            {row.filters.length !== 0 && (<Typography component="button" className={classes.btn}>{row.filters[0].filterId.name}</Typography>)}
                            {row.filters.length > 1 && (
                              <Typography component="button" className={classes.btn} style={{ borderRadius: '50%' }}>+{row.filters.length - 1}</Typography>
                            )}
                          </Grid>
                        )}
                      </StyledTableCell>

                      <StyledTableCell
                        align='right'
                        width={"20px"}
                        style={{ padding: 0, width: 10 }}
                      >

                        <Link to={{
                          pathname: '/addevent',
                          state: row
                        }}>
                          <IconButton >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Link>


                      </StyledTableCell>
                      <StyledTableCell
                        align='right'
                        width={"20px"}
                        style={{ padding: 0, width: 10 }}
                      >

                        {
                          isDeleted ?
                              <IconButton onClick={e => setforDelete(row)}>
                                <FlipCameraAndroid />
                              </IconButton>
                              :
                              <IconButton onClick={e => setforDelete(row)}>
                                <DeleteOutlineIcon />
                              </IconButton>
                        }

                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                );
              })}
            </>
          )}
        </Table>

      )}
      <DeleteDialog
          isDeleted={isDeleted}
          open={forDelete}
          what="Event"
          onOkClick={handleConfirmDelete}
          onCancelClick={e => setforDelete(null)} />
    </>
  );
}

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 500,
  },
  btn: { border: '2px solid #f7cbb0', borderRadius: 2, backgroundColor: '#fef5f0', textAlign: 'center', padding: 3, fontWeight: 'bold', fontSize: 12, color: '#f87a25', marginRight: 6 },

}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FFF",
    color: "#787878",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);
