import { uploadFile } from 'react-s3'

const AWS_REGION = "ap-south-1"
const AWS_BUCKET = 'eventstannew';
const AWS_ACCESS_KEY = 'AKIA5KND4OBVXF45NYDK';
const AWS_ACCESS_SECRET = '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';


export const uploadImageToS3 = (file, dirName) => {
    const config = {
        bucketName: AWS_BUCKET,
        dirName: dirName,
        region: AWS_REGION,
        accessKeyId: AWS_ACCESS_KEY,
        secretAccessKey: AWS_ACCESS_SECRET,
    }
    return uploadFile(file, config)
}