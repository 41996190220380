import { useEffect, useState } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { AddCircleTwoTone } from "@material-ui/icons";

export default function PlusTextField({ disabled, edit, id, setQuestionBox }) {
  const [valueText, setValueText] = useState(edit);

  // console.log(edit);

  const classes = useStyles();
  const handleAddValue = () => {
    setValueText((old) => [...old, ""]);
  };
  const handleChange = (event, index) => {
    setValueText((old) => {
      old[index] = event.target.value;
      return [...old];
    });
  };

  useEffect(() => {
    setQuestionBox((old) => {
      return old.map((q) => {
        if (q._id === id) {
          return { ...q, value: valueText };
        } else {
          return q;
        }
      });
    });
    // eslint-disable-next-line
  }, [valueText]);

  return (
    <>
      {valueText.map((value, index) => {
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TextField
              style={{ margin: "1rem 0" }}
              margin='dense'
              id='name_value_text'
              variant='outlined'
              fullWidth
              value={value}
              className={classes.textField}
              onChange={(event) => handleChange(event, index)}
              disabled={disabled}
              placeholder='Write Here...'
            />
            <AddCircleTwoTone
              style={
                disabled
                  ? { display: "none" }
                  : {
                      cursor: "pointer",
                      marginLeft: "1rem",
                      color: "gray",
                    }
              }
              onClick={handleAddValue}
            />
          </div>
        );
      })}
    </>
  );
}

const useStyles = makeStyles(() => ({
  textField: {
    backgroundColor: "#c4c4c4",
  },
}));
