import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

const TableHeader = ({ onButtonClicked, lable, value, onChange, btnName }) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar className={classes.root}>
            <Typography
                className={classes.title}
                variant='body1'
                id='tableTitle'
                component='div'
            >
                {lable}
            </Typography>


            <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>

                <OutlinedInput
                    id="outlined-adornment-weight"
                    startAdornment={<SearchIcon />}
                    value={value}
                    onChange={onChange}
                    placeholder="Search"
                    style={{ marginRight: '10px' }}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
                    labelWidth={0}
                />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={onButtonClicked}
                    disableElevation
                    style={{ textTransform: 'none' }}
                >
                    {btnName}
                </Button>
            </div>
        </Toolbar>
    );
}


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        backgroundColor: '#FFF'
    },
}));

export default TableHeader;
