import { useContext, useEffect, useState } from "react";
import { withRouter } from 'react-router-dom'
import {
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import userContext from "../../context/userContext";
import { getURL } from "../../util";
import DeleteDialog from '../../components/dialog/DeleteDialog';
import { BASE_API_URL } from '../../constant'

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow>
        <StyledTableCell>S.No</StyledTableCell>
        <StyledTableCell>Name</StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const EventCategory = ({ reload, change, onEdit, search }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData, setUserData } = useContext(userContext);
  const [getDelete, setdelete] = useState(null);


  const classes = useStyles();



  const getEventCategory = async () => {
    let options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      getURL(`${BASE_API_URL}event-categories`, search),
      options
    );
    const data = response.json();
    data
      .then((data) => {
        if (response.status === 401) {
          localStorage.removeItem("auth-token");
          setUserData({
            token: "",
          });
        }

        setRows(data.data.result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getEventCategory();
    // eslint-disable-next-line
  }, [search, change, onEdit]);



  const handleDelete = async () => {
    const formdata = new FormData();
    formdata.append("categoryId", getDelete._id);
    //  formdata.append("name", data.name);
    formdata.append("isDeleted", true);
    let options = {
      method: "POST",
      headers: {
        Authorization: userData.token,
      },
      body: formdata,
    };
    const response = await fetch(
      `${BASE_API_URL}event-categories?lang=en`,
      options
    );
    const result = response.json();
    getEventCategory()
    setdelete(null);
    result.catch((error) => console.log(error));
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Table className={classes.table}>
          {rows.length === 0 ? (
            <div
              style={{
                height: "65vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "30px",
                color: " #f47824",
                textTransform: "uppercase",
              }}
            >
              No match found
            </div>
          ) : (
            <>
              <EnhancedTableHead />
              <TableBody>
                {rows.map((row, index) => {


                  return (
                    <StyledTableRow
                      key={index}
                    >

                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }} width={80}>
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.name}</StyledTableCell>

                      <StyledTableCell
                        align='right'
                        width={"20px"}
                        style={{ padding: 0, width: 10 }}
                      >
                        <IconButton onClick={e => onEdit(row)}>
                          <EditOutlinedIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell
                        align='right'
                        width={"20px"}
                        style={{ padding: 0, width: 10 }}
                      >

                        <IconButton onClick={e => setdelete(row)}>
                          <DeleteOutlineIcon />
                        </IconButton>

                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </>
          )}
          <DeleteDialog open={getDelete} onOkClick={handleDelete} onCancelClick={() => setdelete(null)} />
        </Table>

      )}
    </>
  );
}


export default withRouter(EventCategory);

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 500,
  },
}));

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FFF",
    color: "#787878",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);
