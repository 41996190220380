import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  createMuiTheme,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Collapse,

} from "@material-ui/core";
import { HomeOutlined,ExpandLess ,ExpandMore  } from "@material-ui/icons";
import axios from "axios";
import { BASE_API_URL } from './constant'
import TopNavbar from "./components/TopNavbar";
import UserContext from "./context/userContext";
import EventAdd from "./pages/Event/EventAdd";
import Event from "./pages/Event/Event";
import Services from "./pages/Services/Services";
import Features from "./pages/Features/Features";
import Users from "./pages/Users/Users";
import Filter from "./pages/Filter";
import Login from "./pages/Login";
import Sidebar from "./pages/Sidebar";
import LeadData from "./pages/Vendor/LeadData";
import VendorsLog from "./pages/Vendor/VendorsLog";
import VendorsPayments from "./pages/Vendor/VendorsPayments";
import VendorPaymentLedger from "./pages/Vendor/VendorPaymentLedger";
import VendorListingDetails from "./pages/Vendor/VendorListingDetails";
import ProfessionalsData from "./pages/Vendor/ProfessionalsData";
import VendorsData from "./pages/Vendor/VendorsData";
import VendorRegistration from "./pages/Vendor/VendorRegistration";
import ProfessionalRegistration from "./pages/Vendor/ProfessionalRegistration";
import OwnerDetails from "./pages/Vendor/OwnerDetails";
import PartnerDetails from "./pages/Vendor/PartnerDetails";
import CouponMaster from "./pages/Coupon/CouponMaster";
import CouponList from "./pages/Coupon/CouponList";
import EditCoupon from "./pages/Coupon/EditCoupon";
import SearchContext from "./context/searchContext";
import Dashboard from './pages/Dasboard/dashboard';
import BookedEvents from "./pages/Event/BookedEvents";
import BookedEventDetails from "./pages/Event/BookedEventDetails";
import paymentLog from "./pages/Payment/PaymentLog";
import EditProfessional from "./pages/Vendor/EditProfessional"
import EditVendor from "./pages/Vendor/EditVendor"
import PendingAmount from "./pages/Event/PendingAmount";
import PaymentDistribution from "./pages/Event/PaymentDistribution";
import FeedbackApproval from "./pages/Event/FeedbackApproval";
import ContactAnalytics from "./pages/Reports/ContactAnalytics";
import TopService from "./pages/TopServices/TopServices";
import RolePermission from "./pages/UserAuthentication/RolePermission";
import TestimonialApproval from "./pages/Reports/TestimonialApproval";
import SendNotificationToAll from "./pages/Notification/SendNotificationToAll";
import CreateEmployee from "./pages/UserAuthentication/CreateEmployee";
import EmployeeList from "./pages/UserAuthentication/employeeList";
import RateReview from "./pages/RateReview/List";


const App = () => {
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState({
    token: undefined,
  });

  const checkLoggedIn = async () => {
    let token = localStorage.getItem("auth-token");
    if (token === null) {
      localStorage.setItem("auth-token", "");
      token = "";
    }
    setUserData({ token,});
  };

  const logout = ()=>{
    const headers = {
      'Content-Type': 'application/json',
      "authorization": userData.token
    }
  axios.put(`${BASE_API_URL}logout`,{},{headers: headers})
    .then((res)=>{
      localStorage.removeItem("auth-token");
      setUserData({
        token: '',
      });
    })
  }


  useEffect(() => {
    checkLoggedIn();
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary:{
        main: '#8489b2',
      },
      secondary:{
        main: '#f47824',
      },
      background:{
        default:'#f2f2f2'
      }
    },
    typography: {
      fontFamily: "campton",
      fontSize: 14,
    },
  });

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div className='App'>
          <UserContext.Provider value={{ userData, setUserData }}>
            {userData.token ? (
              <SearchContext.Provider value={{ search, setSearch }}>
                <TopNavbar />
                <Grid container>
                  <Grid item xs={2} style={{ backgroundColor: "#8489B2", paddingTop: "40px", minHeight: "110vh", }}>
                    <List component='nav'>
                      <Sidebar />
                      <ListItem button component={Link} to='/' onClick={logout}>
                      <ListItemText
                        primary='Logout'
                        style={{ color: "white" }}
                      />
                      </ListItem>
                    </List>
                  </Grid>
                  
                  <Grid item xs={10}>
                    <Switch>
                    <Route path='/dashboard'>
                        <Dashboard />
                      </Route>
                      <Route path='/event'>
                        <Event />
                      </Route>
                      <Route path='/rate-review'>
                        <RateReview />
                      </Route>
                      <Route path='/role'>
                        <RolePermission />
                      </Route>
                      <Route path='/create-employee'>
                        <CreateEmployee />
                      </Route>
                      <Route path='/booked-events'>
                        <BookedEvents />
                      </Route>
                      <Route path='/pending-amount'>
                        <PendingAmount />
                      </Route>
                      <Route path='/payment-distribution'>
                        <PaymentDistribution />
                      </Route>
                      <Route path='/booked-event-details/:eventId'>
                        <BookedEventDetails />
                      </Route>
                      <Route path='/feedback-approval'>
                        <FeedbackApproval />
                      </Route>
                      <Route path='/payment-log/:eventId' component={paymentLog} />
                      <Route path='/edit-professional/:vendorId' component={EditProfessional} />
                      <Route path='/edit-vendor/:vendorId' component={EditVendor} />
                      <Route path='/edit-coupon/:couponId' component={EditCoupon} />
                      <Route path='/lead-data'>
                        <LeadData />
                      </Route>
                      <Route path='/vendors-log'>
                        <VendorsLog />
                      </Route>
                      <Route path='/vendors-payments'>
                        <VendorsPayments />
                      </Route>
                      <Route path='/vendor-listing-details/:vendorServiceId'>
                        <VendorListingDetails />
                      </Route>
                      <Route path='/vendor-payment-ledger'>
                        <VendorPaymentLedger />
                      </Route>
                      <Route path='/vendors-data'>
                        <VendorsData />
                      </Route>
                      <Route path='/Professionals-data'>
                        <ProfessionalsData />
                      </Route>
                      <Route path='/vendor-register'>
                        <VendorRegistration />
                      </Route>
                      <Route path='/top-service'>
                        <TopService /> 
                      </Route>
                      <Route path='/professional-register'>
                        <ProfessionalRegistration />
                      </Route>
                      <Route path='/owner-details/:email' component={OwnerDetails} />
                      <Route path='/partner-details'>
                        <PartnerDetails /> 
                      </Route>
                      <Route path='/coupon-master'>
                        <CouponMaster /> 
                      </Route>
                      <Route path='/coupon-List'>
                        <CouponList /> 
                      </Route>
                      <Route path='/send-notification-to-all'>
                        <SendNotificationToAll /> 
                      </Route>
                      <Route path='/filter'>
                        <Filter />
                      </Route>
                      <Route path='/services'>
                        <Services />
                      </Route>
                      <Route path='/features'>
                        <Features />
                      </Route>
                      
                      <Route path='/addevent'>
                        <EventAdd />
                      </Route>
                      <Route path='/users'>
                        <Users />
                      </Route>
                      <Route path='/employee-list'>
                        <EmployeeList />
                      </Route>
                      <Route path='/vendor-contact-analytics'>
                        <ContactAnalytics />
                      </Route>
                      <Route path='/testimonal-approval'>
                        <TestimonialApproval />
                      </Route>
                      <Route path='/'>
                        <Services />
                      </Route>
                    </Switch>
                  </Grid>
                </Grid>
              </SearchContext.Provider>
            ) : (
                <Login />
              )}
          </UserContext.Provider>
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default App;
