import React,{ useContext, useEffect, useState } from 'react';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Button
  } from "@material-ui/core";
  import { makeStyles, withStyles } from "@material-ui/core/styles";
  import { BounceLoader } from "react-spinners";
  import { css } from "@emotion/core";
  import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
  import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
  import Divider from '@material-ui/core/Divider';
  import TablePagination from '@material-ui/core/TablePagination';
  import userContext from "../../context/userContext";
  import OutlinedInput from '@material-ui/core/OutlinedInput';
  import SearchIcon from '@material-ui/icons/Search';
  import DeleteDialog from '../../components/dialog/DeleteDialog';
  import Snackbar from '@material-ui/core/Snackbar';
  import Alert from '@material-ui/lab/Alert';
  import {BASE_API_URL} from '../../constant';
  import Select from '@mui/material/Select';
  import MenuItem from '@mui/material/MenuItem';
  import FormControl from '@mui/material/FormControl';
  import InputLabel from '@mui/material/InputLabel';

  function EnhancedTableHead() {
    return (
      <TableHead>
        <StyledTableRow >
          <StyledTableCell>ID</StyledTableCell>
          <StyledTableCell>Role</StyledTableCell>
          <StyledTableCell>NAME</StyledTableCell>
          <StyledTableCell>EMAIL</StyledTableCell>
          <StyledTableCell>STATUS</StyledTableCell>
          <StyledTableCell>ACTION</StyledTableCell>
        </StyledTableRow>
      </TableHead>
    );
  }
  const EnhancedTableToolbar = ({ srole, value, onChange, role,setRole }) => {
    const classes = useToolbarStyles();
  
    return (
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant='body1'
          id='tableTitle'
          component='div'
        >
          Employee List
        </Typography>

        <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
          <OutlinedInput
            id="outlined-adornment-weight"
            startAdornment={<SearchIcon />}
            value={value}
            onChange={onChange}
            placeholder="Search"
            style={{ marginRight: '1px' }}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
            labelWidth={0}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="demo-simple-select-helper-label">By Role</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={srole} onChange={setRole} >
            <MenuItem value="">Select Role</MenuItem>
            {role && role.map((ro,i)=>{
                return <MenuItem value={ro._id}>{ro.role_name}</MenuItem>
            })}
        </Select>
      </FormControl>
      </div>
      </Toolbar>
    );
  }; 
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const EmployeeList=({ classes })=>{
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [delete1, setdelete] = useState(null);
    const [error, setError] = useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [role, setRole] = useState([]);
    const [srole, setSRole] = useState("");

    const handlesetRole = (e)=>{
        setSRole(e.target.value);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
      const getEmployee = async () => {
        const options = {
          method: "GET",
          headers: {
            Authorization: userData.token,
          },
        };
        const response = await fetch(
          `${BASE_API_URL}employee-list?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}&role=${srole}`, options);

        const data = response.json(); 

        data.then((data) => { console.log(data)
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
    
          setRows(data.data.result);
          setcount(data.data.userCount);
          setLoading(false);
        })
          .catch((error) => console.log(error));
      };

      const getRoles = async () => {
        const options = {
          method: "GET",
          headers: {
            Authorization: userData.token,
          },
        };
        const response = await fetch(
          `${BASE_API_URL}role-list`, options);
    
        const data = response.json(); 
    
        data.then((data) => { 
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
          setRole(data.data);
        })
          .catch((error) => console.log(error));
    };

      useEffect(() => {
        getRoles();
        getEmployee();
        // eslint-disable-next-line
      }, [search, create,rowsPerPage, page,srole]);

      

    const handleUserDelete = async () => {
      let formdata = new FormData();
      formdata.append("userId", delete1._id);
      formdata.append("isDeleted", true);
      try {
        const response = await fetch(
          `${BASE_API_URL}employee-manipulation`,
          {
            method: "POST",
            headers: {
              Authorization: userData.token,
            },
            body: formdata
          }
        );
        const result = await response.json();
        setdelete(null)
        getEmployee()
      } catch (e) {
        setError('delete failed Failed')
      }
    }
      
    return(
        <>
        {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Paper className={classes.paper} style={{ margin: 40 }}>
            <>
              <EnhancedTableToolbar srole={srole} role={role} setRole={handlesetRole} onButtonClicked={e => setcreate(true)} value={search} onChange={e => {setsearch(e.target.value); setPage(0)} } />
              <Divider />
              <TableContainer>
                <Table className={classes.table}>
                  <EnhancedTableHead />

                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={row._id}>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> {page*rowsPerPage+index+1}</StyledTableCell>
                          <StyledTableCell align='left'>{row.role_id.role_name}</StyledTableCell>
                          <StyledTableCell align='left'>{row.name}</StyledTableCell>
                          <StyledTableCell align='left'>{row.email}</StyledTableCell>
                          <StyledTableCell align='left'>{row.isDeleted?'Inactive':'Active'}</StyledTableCell>
                          <StyledTableCell align='right' style={{ padding: 0, width: 10 }}>
                            <IconButton onClick={e => setdelete(row)}>
                              <DeleteOutlineIcon style={{ color: '#bcbcbc' }} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </>

            {/*(create || update) && (
              <ServiceAdd open={create || update} update={update} onClose={e => { setcreate(false); setupdate(null); getUsers() }} />
            )*/}
            <DeleteDialog open={delete1} onOkClick={handleUserDelete} onCancelClick={e => setdelete(null)} />
            
            <Snackbar open={error} autoHideDuration={3000} onClose={e => setError(null)}>
              <Alert severity="error">{error}</Alert>
            </Snackbar>
          </Paper>
       ) }
        </>
    );
}
const useToolbarStyles = makeStyles((theme) => ({
    title: {
      fontWeight: '600'
    },
    root: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: '#fff'
    },
  }));
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#fff",
      color: "#787878",
    },
    body: {
      fontSize: 14,
      padding: '0px 16px',
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      backgroundColor: '#fff',
      "&:nth-of-type(odd)": {
        backgroundColor: '#f8f8f8',
      },
    },
  }))(TableRow);
  
  const rootStyles = theme => ({
    btn: {
      border: '2px solid #f7cbb0',
      borderRadius: 2,
      backgroundColor: '#fef5f0',
      textAlign: 'center',
      padding: 3,
      fontWeight: 'bold',
      fontSize: 12,
      color: '#f87a25',
      marginRight: 6,
      marginTop: 3,
      marginBottom: 3
    },
    noMatch: {
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "30px",
      color: " #f47824",
      textTransform: "uppercase"
    }
  })

export default withStyles(rootStyles)(EmployeeList)