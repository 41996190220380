import React, { Component } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditIcon from "@material-ui/icons/Edit";
import Typography from '@material-ui/core/Typography';


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const updatePosition = [];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  for( var i = 0 ; i< result.length;i++) {
    updatePosition.push({...result[i], position: i+1})
  }; 
  return updatePosition;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  }),

  padding: 0
});


const DragDrop = ({ list, onDrag }) => {


  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    );


    onDrag(items)
    // this.setState({
    //   items
    // });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <RootRef rootRef={provided.innerRef}>
            <List style={{padding:0}}>
              {list.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided, snapshot) => (
                    <ListItem
                      ContainerComponent="li"
                      ContainerProps={{ ref: provided.innerRef }}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                     
                      <ListItemText
                        primary={<p style={{display: 'flex', fontSize:14}}><Typography style={{borderRadius:'50%', backgroundColor:'#f47824', width:20, height:20, textAlign:'center', color:'#fff', fontSize:12}}>{item.position}</Typography><span style={{marginLeft:10}}>{item.name}</span></p>}
                      />
                      <ListItemSecondaryAction style={{ display: 'none' }}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </RootRef>
        )}
      </Droppable>
    </DragDropContext>
  );

}


export default DragDrop

