import React, { useState, useEffect, useContext } from 'react';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';


let curdisplay="";

const PartnerDetails = () => {  
      
        const [cDisplay, setDisplay] = useState(curdisplay);
    
        const displayuid = () => {
            curdisplay="";
            setDisplay(curdisplay);           
        }
    
        const hideuid = () => {
            curdisplay="none";
            setDisplay(curdisplay);           
        }
    

    return ( 
    <div className="container">
        <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="border-bottom pb-2 ">
                <h2 className="page-title my-3">Partner Details</h2>
                    <div className="border-outer mb-4" id="partners">
                        <div className="border-top px-4  py-3 ">
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="residenceofuae">Is residence of UAE ?</label>
                                        <input type="radio" id="yes" name="uae-residence" value="yes" onClick={displayuid} checked />
  									    <label for="html">Yes</label>
  									    <input type="radio" id="no" name="uae-residence" value="no" onClick={hideuid}/>
  									    <label for="css">No</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display : cDisplay }}>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="idnumber">Emirates ID Number</label>
                                        <input type="number" className="form-control" id="idnumber" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="uid">UID</label>
                                        <input type="text" className="form-control" id="uid" placeholder="" />
                                    </div>
                                </div>
                            </div>
						    <div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="email">Email</label>
                                        <input type="email" className="form-control" id="email" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="telephone">Telephone</label>
                                        <input type="number" className="form-control" id="telephone" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="mobile">Mobile</label>
                                        <input type="mobile" className="form-control" id="mobile" placeholder="+971" />
                                    </div>
                                </div>
                            </div>
						    <div className="row">
                                <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="salutation">Salutation </label>
                                        <input type="radio" id="mr" name="mr" value="Mr." />
  									    <label for="html">Mr.</label>
  									    <input type="radio" id="mrs" name="mrs" value="Mrs." />
  									    <label for="css">Mrs.</label>
									    <input type="radio" id="ms" name="ms" value="Ms." />
  									    <label for="html">Ms.</label>
                                    </div>
                                </div>
							    <div className="col-md-6 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="gender">Gender</label>
                                        <select name="gender" id="gender" class="form-control">
										    <option value="male">Male</option>
										    <option value="female">Female</option>
										    <option value="other">Other</option>
									    </select>
                                    </div>
                                </div>
                            </div>
						    <div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="fname">First Name</label>
                                        <input type="text" class="form-control" id="fname" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="mname">Middle Name</label>
                                        <input type="text" class="form-control" id="mname" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">    
                                        <label for="lname">Last Name</label>
                                        <input type="text" className="form-control" id="lname" placeholder="" />
                                    </div>
                                </div>
                            </div>
						    <div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="faname">First Name (Arabic)</label>
                                        <input type="text" className="form-control" id="faname" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="maname">Middle Name (Arabic)</label>
                                        <input type="text" className="form-control" id="maname" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="laname">Last Name (Arabic)</label>
                                        <input type="text" className="form-control" id="laname" placeholder="" />
                                    </div>
                                </div>
                            </div>
						    <div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="pass">Passport Number</label>
                                        <input type="text" className="form-control" id="pass" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="passidate">Passport Issue Date</label>
                                        <DatePicker className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="passedate">Passport Expiry Date</label>
                                        <DatePicker className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="placeissue">Place Of Issue</label>
                                        <input type="text" className="form-control" id="placeissue" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="passcountry">Passport Country of Issue </label>
                                        <select name="passcountry" id="passcountry" className="form-control">
										    <option value="abu-dhabi">Abu Dhabi</option>
										    <option value="saab">Ajman</option>
										    <option value="mercedes">Fujairah</option>
										    <option value="saab">Dubai</option>
										    <option value="abu-dhabi">Ras Al Khaimah</option>
										    <option value="mercedes">Sharjah</option>
										    <option value="mercedes">Umm Al-Quwain</option>
									    </select>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="birthdate">Date of Birth</label>
                                        <DatePicker className="form-control"/>
                                    </div>
                                </div>
                            </div>
						    <div className="row">
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="birthcountry">Country of Birth</label>
                                        <select name="birthcountry" id="birthcountry" className="form-control">
										    <option value="abu-dhabi">Abu Dhabi</option>
										    <option value="saab">Ajman</option>
										    <option value="mercedes">Fujairah</option>
										    <option value="saab">Dubai</option>
										    <option value="abu-dhabi">Ras Al Khaimah</option>
										    <option value="mercedes">Sharjah</option>
										    <option value="mercedes">Umm Al-Quwain</option>
									    </select>
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="cnationality">Current Nationality</label>
                                        <input type="text" className="form-control" id="cnationality" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="pnationality">Previous Nationality</label>
                                        <input type="text" className="form-control" id="pnationality" placeholder="" />
                                    </div>
                                </div>
                            </div>
						    <div className="row">
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="address1">Address Line 1</label>
                                        <input type="text" className="form-control" id="address1" />
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="address2">Address Line 2</label>
                                        <input type="text" className="form-control" id="address2" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="address3">Address Line 3</label>
                                        <input type="text" className="form-control" id="address3" />
                                    </div>
                                </div>
                            </div>
						    <div className="row"> 
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="city">City</label>
                                        <input type="text" className="form-control" id="city" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="state">State / Province</label>
                                        <input type="text" className="form-control" id="state" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="country">Country</label>
                                        <select name="country" id="country" className="form-control">
										    <option value="abu-dhabi">Abu Dhabi</option>
										    <option value="saab">Ajman</option>
										    <option value="mercedes">Fujairah</option>
										    <option value="saab">Dubai</option>
										    <option value="abu-dhabi">Ras Al Khaimah</option>
										    <option value="mercedes">Sharjah</option>
										    <option value="mercedes">Umm Al-Quwain</option>
									    </select>
                                    </div>
                                </div>
                            </div>
                        </div>                           
                    </div>
					<Button
                    color="primary"
                    disableElevation
                    variant="contained">Save</Button>
                </div>
            </div>
        </div>
    </div>
        ); 
    }

export default PartnerDetails;