import React from 'react'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import withStyles from '@material-ui/core/styles/withStyles';




const StanButton = ({
    onClick,
    isSelected = false,
    children,
    classes,
    onCloseClick,
    type
}) => {
    return (

        <Button
            variant="contained"
            disableElevation
            type={type}
            color="primary"
            onClick={onClick}
            style={{ padding: '4px 6px', margin: '7px', textTransform: 'capitalize', backgroundColor: '#8489b2' }}
            startIcon={isSelected ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
        >
            <CancelIcon onClick={e => {
                e.stopPropagation();
                if (onCloseClick) {
                    onCloseClick(e)
                }

            }} fontSize="small" className={classes.cancelIcon} />
            {children}
        </Button>


        // <Button
        //     style={{ fontSize: 14, textTransform: 'capitalize' }}
        //     onClick={onClick}
        //     startIcon={isSelected ? <CheckCircleIcon style={{ color: '#f47824' }} /> : <CheckCircleOutlineIcon style={{ color: '#f47824' }} />}
        // >{child}</Button>
    )
}

const styles = theme => ({
    cancelIcon: {
        position: 'absolute',
        right: -5,
        width: 18,
        top: -6,
        color: 'lightgrey'
    }
})

export default withStyles(styles)(StanButton)