import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Button
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Divider from '@material-ui/core/Divider';
import TablePagination from '@material-ui/core/TablePagination';
import userContext from "../../context/userContext";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import ServiceAdd from './serviceAdd';
import DeleteDialog from '../../components/dialog/DeleteDialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { BASE_API_URL } from '../../constant';
import noImage from "../../assets/noimage.jpg";



function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell>SN</StyledTableCell>
        <StyledTableCell>Service</StyledTableCell>
        <StyledTableCell>Image</StyledTableCell>
        <StyledTableCell>Filters</StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = ({ value, onChange, onButtonClicked }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='body1'
        id='tableTitle'
        component='div'
      >
        Services
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
        <OutlinedInput
          id="outlined-adornment-weight"
          startAdornment={<SearchIcon />}
          value={value}
          onChange={onChange}
          placeholder="Search"
          style={{ marginRight: '10px' }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
          labelWidth={0}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={onButtonClicked}
          disableElevation
          style={{ textTransform: 'none' }}
        >
          Add New Service
        </Button>
      </div>
    </Toolbar>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Services = ({ classes }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const { userData, setUserData } = useContext(userContext);
  const [count, setcount] = useState(0);
  const [create, setcreate] = useState(false);
  const [delete1, setdelete] = useState(null);
  const [error, setError] = useState(null);
  const [update, setupdate] = useState(null);


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getServices = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      `${BASE_API_URL}service-types?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}`, options);
    const data = response.json();
    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      console.log(data);

      setRows(data.data.result);
      setcount(data.data.serviceCount);
      setLoading(false);
    })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getServices();
    // eslint-disable-next-line
  }, [search, create, rowsPerPage, page]);


  const handleServiceDelete = async () => {
    let formdata = new FormData();
    formdata.append("serviceId", delete1._id);
    formdata.append("isDeleted", true);
    try {
      const response = await fetch(
        `${BASE_API_URL}service-types`,
        {
          method: "POST",
          headers: {
            Authorization: userData.token,
          },
          body: formdata
        }
      );
      const result = await response.json();
      setdelete(null)
      getServices()
    } catch (e) {
      console.log(e)
      setError('delete failed Failed')
    }
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Paper className={classes.paper} style={{ margin: 40 }}>
          <>
            <EnhancedTableToolbar onButtonClicked={e => setcreate(true)} value={search} onChange={e => { setsearch(e.target.value); setPage(0) }} />
            <Divider />
            <TableContainer>
              <Table className={classes.table}>
                <EnhancedTableHead />

                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <StyledTableRow
                        key={row.name}>
                        <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            {page * rowsPerPage + index + 1}
                            <div style={{
                              backgroundColor: row?.bgColor ?? 'black',
                              height: 10,
                              width: 10,
                              borderRadius: 5,
                              borderWidth: 1,
                              borderColor: '#ff0000',
                            }}></div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align='left' style={{
                          //fontWeight: 'bold'
                        }}>
                          <b>{row.name}</b> <br />
                          <span style={{
                            fontSize: 'small',
                            fontStyle: 'italic',
                            color: '#f87a25',
                          }}>{row.type}</span>
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          <img
                            style={{
                              height: 30,
                              width: 50,
                              borderRadius: 8,
                            }}
                            // src={require(noImage)}
                            // src={noImage}
                            src={row?.bgImage} //noImage.jpg"
                          />
                        </StyledTableCell>
                        <StyledTableCell align='left' style={{ width: '50%' }}>
                          {row.filters.map((filter, i) => (
                            <Typography key={i} component="button" className={classes.btn}>{filter.name}</Typography>
                          ))}
                        </StyledTableCell>

                        <StyledTableCell
                          align='right'
                          style={{ padding: 0, width: 10 }}
                        >
                          <IconButton onClick={e => setupdate(row)}>
                            <EditOutlinedIcon style={{ color: '#bcbcbc' }} />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell
                          align='right'
                          style={{ padding: 0, width: 10 }}
                        >
                          <IconButton onClick={e => setdelete(row)}>
                            <DeleteOutlineIcon style={{ color: '#bcbcbc' }} />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>

          {(create || update) && (
            <ServiceAdd open={create || update} update={update} onClose={e => { setcreate(false); setupdate(null); getServices() }} />
          )}

          <DeleteDialog open={delete1} onOkClick={handleServiceDelete} onCancelClick={e => setdelete(null)} />
          <Snackbar open={error} autoHideDuration={3000} onClose={e => setError(null)}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        </Paper>
      )}
    </>
  );
}


const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})


export default withStyles(rootStyles)(Services)
