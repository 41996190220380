import * as React from 'react';
import {
  AppBar,
  IconButton,
  InputBase,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import { useContext, useState } from "react";
import searchContext from "../context/searchContext";
//import Notification from './Notification';
import Notifications from "react-notifications-menu";

const DEFAULT_NOTIFICATION = {
  image:
    "https://cutshort-data.s3.amazonaws.com/cloudfront/public/companies/5809d1d8af3059ed5b346ed1/logo-1615367026425-logo-v6.png",
  message: "Event Book for date : 22/6",
  detailPage: "/booked-events",
  receivedTime: "16/6"
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: "#8489B2",
  },
  dialog: {
    alignItems: "right",
    verticalAlign:"text-top",
    top:"20px",
    display: "flex",
    flexDirection: "row-reverse",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontFamily: "exotic",
    textTransform: "uppercase",
    fontSize: "32.5px",
    color: "#fff",
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: "1rem",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      marginRight: "10rem",
      width: "auto",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50ch",
      "&:focus": {
        width: "53ch",
      },
    },
  },
}));

const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}


const TopNavbar = () => {
  const [value, setValue] = useState("");
  const { setSearch } = useContext(searchContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [data, setData] = useState([DEFAULT_NOTIFICATION]);
  const [message, setMessage] = useState("");


  const onClick = () => {
    if (message.length > 0) {
      setData([
        ...data,
        {
          ...DEFAULT_NOTIFICATION,
          message
        }
      ]);
      setMessage("");
      alert("notification added");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setSearch(value);
    }
  };

  const handleClick = () => {
    setSearch(value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={classes.root}>
      <AppBar position='static' elevation={0} className={classes.appbar}>
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            Event<span style={{ color: "#f47824" }}>stan</span>
          </Typography>
          <div className={classes.search}>
            <IconButton style={{ cursor: "pointer" }} onClick={handleClick}>
              <Search />
            </IconButton>

            <InputBase
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder='Search…'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
          <Typography variant="subtitle1" component="div">
        
      </Typography>
      <Notifications
          data={data}
          header={{
            title: "Notifications",
            option: { text: "View All", onClick: () => console.log("Clicked") }
          }}
          markAsRead={(data) => {
            console.log(data);
          }}
        />

          <span>Welcome, Admin</span>
                  </Toolbar>
      </AppBar>
    </div>
  );
};

export default TopNavbar;
