import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from '@material-ui/core/InputBase';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';





const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid',
    borderColor: 'rgba(0,0,0,0.25)',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);


const Input = ({
  label, onChange, placeholder, style, type, rootStyle, name, value,disabled, deleteIcon = false, onAdornClick
}) => {

  return (
    <FormControl style={rootStyle}>

      {label &&
        <InputLabel  shrink htmlFor={label}>
          {label}</InputLabel>
      }

      <BootstrapInput
        value={value}
        style={{
          ...style,
          borderColor: 'rgba(0,0,0,0.12)',
        }}
        type={type}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        endAdornment={deleteIcon && <IconButton onClick={onAdornClick}><DeleteOutlineIcon  style={{color:'gray', cursor: 'pointer'}}/></IconButton>}
        id={label} />

    </FormControl>
  );
};

const styles = (theme) => ({

});

export default withStyles(styles)(Input);
