import React from 'react'
import { Link } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';
import { MdNotificationsNone } from 'react-icons/md';
import { BiDotsHorizontal } from 'react-icons/bi';
import "./dashboard.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Button } from '@material-ui/core';
import ExampleChart from "./charts";




class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            search: ""
        }
    }


    handleChange = (e) => {
        this.setState({ search: e.target.value })
    }
    render() {
        return (
            <div className="dashboard">
                {/* <div className="header-dashboard" style={{backgroundColor : "#8489a2" , padding : "10px 30px"}}>
                    <div className="row align-items-center">
                        <div className="col-md-3">
                            <Link to="#" style={{textTransform : "uppercase" , color : "#fff" , fontSize : "33px" , fontWeight : "bold"}}>event<span style={{color :"#f47824"}}>stan</span></Link>
                        </div>
                        <div className="col-md-6">
                            <div className="search-boxa">
                                <FiSearch />
                                <input type="text" onChange={this.handleChange} value={this.state.search} placeholder="search for people , documents , goods"/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="right-header">
                                <span><MdNotificationsNone /></span>
                                <span><BiDotsHorizontal /></span>
                                <div className="pro-box">
                                    <img src="" alt="" />
                                    <p>Nancy <span>&#8249;</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div style={{padding : 40}}>
                    <div style={{ backgroundColor: "#f6eae2", margin: "30px 0px", borderRadius: "8px", display: 'flex' }}>

                        <div className="t-box">
                            <p>Revenue generated</p>
                            <span>2300</span>
                        </div>


                        <div className="t-box">
                            <p>Expected Revenue</p>
                            <span>2300</span>
                        </div>

                        <div className="t-box">
                            <p>Total Bookings</p>
                            <span>2300</span>
                        </div>


                        <div className="t-box t-outbox">
                            <p>Canceled Bookings</p>
                            <span>2300</span>
                        </div>
                    </div>
                    <div  style={{ marginBottom: "30px", display:'flex' }}>
                        <div style={{flexGrow: 9, marginRight:20}}>
                            <div className="grup-box" style={{ backgroundColor:'#fff', boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px 0px", padding: "30px" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px",}}>
                                    <h4 style={{ fontWeight: "bold", color: "#000" }}> Revenue</h4>
                                    <p style={{ fontWeight: "bold", color: "#000" }}><span style={{ color: "red" }}>+KD 45 (days)</span> KD 456</p>
                                </div>
                                <ExampleChart />
                            </div>
                        </div>
                        <div style={{flexGrow: 3}}>
                            <div className="pro-gress-box" style={{ backgroundColor:'#fff'}}>
                                <div className='re-circle'>
                                    <span><CircularProgress variant="determinate" value={80} color="#f47824" style={{ transform: "Scale(2.5)" }} /></span>
                                    <p className="pro-count">1,325</p>
                                </div>
                                <p className="ven-f" style={{ paddingTop: "120px", marginBottom: "0px", fontWeight: "bold", paddingLeft: "25px" }}><span className="ven"></span><span>vendors</span> : 1,325</p>
                                <p className="ven-f" style={{ paddingLeft: "25px" }}><span className="cus"></span><span>customer</span> : 1,100</p>
                            </div>
                        </div>
                    </div>
                    <div  style={{ marginBottom: "30px",display:'flex' }}>
                        <div style={{flexGrow: 9,marginRight: 20, backgroundColor: '#fff'}} >
                            <div className="right-ven-box" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px 0px", border: "2px solid #eeeeee", borderRadius: "8px" }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px 25px", borderBottom: "2px solid #eeeeee" }}><h4>vendors</h4> <h4>34, 0000</h4></div>
                                <div className="right-tab-box" style={{ borderBottom: "2px solid #eeeeee" }}>
                                    <Tabs>
                                        <TabList>
                                            <Tab style={{fontSize: 14, fontWeight: 'normal'}}>pending <span>1</span></Tab>
                                            <Tab style={{fontSize: 14, fontWeight: 'normal'}}>Approved <span>2</span></Tab>
                                            <Tab style={{fontSize: 14, fontWeight: 'normal'}}>Rejected <span>4</span></Tab>
                                        </TabList>

                                        <TabPanel>
                                            <div style={{ overflowX: "auto" }}>
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">VENDOR NAME</th>
                                                            <th scope="col">LISTING</th>
                                                            <th scope="col">SERVICE</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Mark</td>
                                                            <td>Otto</td>
                                                            <td>@mdo</td>
                                                            <td><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Jacob</td>
                                                            <td>Thornton</td>
                                                            <td>@fat</td>
                                                            <td><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Larry</td>
                                                            <td>the Bird</td>
                                                            <td>@twitter</td>
                                                            <td className="tab-btn"><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div style={{ overflowX: "auto" }}>
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">VENDOR NAME</th>
                                                            <th scope="col">LISTING</th>
                                                            <th scope="col">SERVICE</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Mark</td>
                                                            <td>Otto</td>
                                                            <td>@mdo</td>
                                                            <td><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Jacob</td>
                                                            <td>Thornton</td>
                                                            <td>@fat</td>
                                                            <td><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Larry</td>
                                                            <td>the Bird</td>
                                                            <td>@twitter</td>
                                                            <td className="tab-btn"><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            <div style={{ overflowX: "auto" }}>
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col">VENDOR NAME</th>
                                                            <th scope="col">LISTING</th>
                                                            <th scope="col">SERVICE</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">1</th>
                                                            <td>Mark</td>
                                                            <td>Otto</td>
                                                            <td>@mdo</td>
                                                            <td><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">2</th>
                                                            <td>Jacob</td>
                                                            <td>Thornton</td>
                                                            <td>@fat</td>
                                                            <td><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                        <tr>
                                                            <th scope="row">3</th>
                                                            <td>Larry</td>
                                                            <td>the Bird</td>
                                                            <td>@twitter</td>
                                                            <td className="tab-btn"><Button className="accept">Accept</Button> <Button className="cancel">Cancel</Button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                        <div style={{flexGrow: 3}}>
                            <div className="left-ven-box" style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px 0px", backgroundColor: '#fff' }}>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "20px 25px", borderBottom: "2px solid #eeeeee" }}><h4>Vendors</h4> <h4>34, 0000</h4></div>
                                <div style={{ overflowX: "auto" }}>
                                    <table class="table table-striped">
                                        <thead>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">chris sanders</th>
                                                <td>Recently Joined</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">chris sanders</th>
                                                <td>Recently Joined</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">chris sanders</th>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Dashboard;