import React, { useState, useEffect, useContext } from 'react';
import StanDialog from '../../components/dialog/EventDialog';
import EditText from '../../components/small/EditText';
import Button from '@material-ui/core/Button';
import userContext from "../../context/userContext";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { BASE_API_URL } from '../../constant'
import axios from "axios";


const EventCategoryAdd = ({ open, onClose, update }) => {
    const [name, setname] = useState('');
    const [error, setError] = useState({ open: false, message: 'Error' });
    const { userData } = useContext(userContext);

    const handleOnClose = () => {
        setname('')
        onClose()
    }

    const handleForSave = async (e) => {
        e.preventDefault();
        if (name.length === 0) {
            setError({ open: true, message: 'Name Should not be empty' })
        } else {
            const formdata = new FormData();
            formdata.append("name", name);
            if (update) {
                formdata.append("categoryId", update._id);
            }
            let options = {
                method: "POST",
                headers:{
                    "authorization":userData.token
                },
                body: formdata,
            };
            const response = await fetch(`${BASE_API_URL}event-categories`,options);
            const result = response.json();
            handleOnClose()
            result.catch((error) => console.log(error));
        }
    }


    useEffect(() => {
        if (update) { setname(update.name) }
    }, [update])




    return (
        <StanDialog open={open} onClick={handleOnClose} style={{ width: '25%' }}>
            <form onSubmit={handleForSave}>
                <EditText
                    label="Name"
                    value={name}
                    onChange={e => setname(e.target.value)}
                    rootStyle={{ width: '100%' }} />
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    style={{ width: '100%', marginTop: '3rem', textTransform: 'none' }}
                >Save</Button>
            </form>

            <Snackbar open={error.open} autoHideDuration={3000} onClose={e => setError({ open: false, message: '' })}>
                <Alert severity="error" >{error.message} </Alert>
            </Snackbar>
        </StanDialog>
    );
}

export default EventCategoryAdd;
