import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Button
} from "@material-ui/core";
import * as XLSX from 'xlsx';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Divider from '@material-ui/core/Divider';
import TablePagination from '@material-ui/core/TablePagination';
import userContext from "../../context/userContext";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import SearchIcon from '@material-ui/icons/Search';
import DeleteDialog from '../../components/dialog/DeleteDialog';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { BASE_API_URL } from '../../constant';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { FlipCameraAndroid } from '@material-ui/icons';

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell>ID</StyledTableCell>
        <StyledTableCell>NAME</StyledTableCell>
        <StyledTableCell>EMAIL</StyledTableCell>
        <StyledTableCell>PHONE</StyledTableCell>
        <StyledTableCell>DEVICE</StyledTableCell>
        <StyledTableCell>LOGIN TYPE</StyledTableCell>
        <StyledTableCell>Status</StyledTableCell>
        <StyledTableCell>ACTION</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ handlesetLoginType, value, onChange, onButtonClicked, loginType, deviceType, handlesetDeviceType, }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='body1'
        id='tableTitle'
        component='div'
      >
        Users
      </Typography>

      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
        <OutlinedInput
          id="outlined-adornment-weight"
          startAdornment={<SearchIcon />}
          value={value}
          onChange={onChange}
          placeholder="Search"
          style={{ marginRight: '1px' }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
          labelWidth={0}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Device Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={deviceType}
            onChange={handlesetDeviceType}
          >
            <MenuItem value="">Select Device Type</MenuItem>
            <MenuItem value="IOS">IOS</MenuItem>
            <MenuItem value="WEB">WEB</MenuItem>
            <MenuItem value="ANDROID">ANDROID</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Login Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={loginType}
            onChange={handlesetLoginType}
          >
            <MenuItem value="">Select Login Type</MenuItem>
            <MenuItem value="GOOGLE">GOOGLE</MenuItem>
            <MenuItem value="NORMAL">NORMAL</MenuItem>
            <MenuItem value="FACEBOOK">FACEBOOK</MenuItem>
          </Select>
        </FormControl>
      </div>
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Users = ({ classes }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const { userData, setUserData } = useContext(userContext);
  const [count, setcount] = useState(0);
  const [create, setcreate] = useState(false);
  const [delete1, setdelete] = useState(null);
  const [action, setAction] = useState(null);
  const [error, setError] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loginType, setLoginType] = useState('');
  const [deviceType, setDeviceType] = useState('');

  const handlesetLoginType = (e) => {
    setLoginType(e.target.value);
  }
  const handlesetDeviceType = (e) => {
    setDeviceType(e.target.value);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getUsers = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      `${BASE_API_URL}user-list?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}&loginType=${loginType}&deviceType=${deviceType}`, options);

    const data = response.json();

    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      console.log(data)
      setRows(data.data.result);
      setcount(data.data.userCount);
      setLoading(false);
    })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [search, create, rowsPerPage, page, loginType, deviceType]);

  const handleUserDelete = async () => {
    let formdata = {
      userId: delete1._id,
      isDeleted: action
    };

    try {
      const response = await fetch(
        `${BASE_API_URL}user-manipulation`,
        {
          method: "POST",
          headers: {
            Authorization: userData.token,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formdata)
        }
      );
      const result = await response.json();
      setdelete(null)
      getUsers()
    } catch (e) {
      setError('delete Failed')
    }
  }

  const exportToExcel = () => {
    console.log('exportToExcel started');
    if (!rows || rows.length === 0) {
      console.error('No data to export!');
      return;
    }

    const formatedDob = (timestamp) => {
      if (!timestamp) {
        return 'N/A';
      }

      const date = new Date(parseInt(timestamp));
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }

    // Filter the rows to include only the required fields and add an 'ID' column
    const filteredData = rows.map((row, index) => ({
      ID: index + 1,
      'First Name': row.firstName || 'N/A',
      'Email': row.email || 'N/A',
      'Date of Birth': formatedDob(row.dob),
      'Phone Number': row.phoneNumber || 'N/A',
      'Device Type': row.deviceType || 'N/A',
      'Login type': row.loginType || 'N/A',
      'Status': row.isDeleted ? 'Inactive' : 'Active'
    }));

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

    XLSX.writeFile(workbook, "users_data.xlsx");

  };

  
  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Paper className={classes.paper} style={{ margin: 40 }}>
          <>
            <EnhancedTableToolbar loginType={loginType} deviceType={deviceType} handlesetDeviceType={handlesetDeviceType} handlesetLoginType={handlesetLoginType} onButtonClicked={e => setcreate(true)} value={search} onChange={e => { setsearch(e.target.value); setPage(0) }} />
            <Divider />
            <TableContainer>
              <Table className={classes.table}>
                <EnhancedTableHead />

                <TableBody>
                  {rows.map((row, index) => {
                    return (
                      <StyledTableRow
                        key={row.name}>
                        <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> {page * rowsPerPage + index + 1}</StyledTableCell>
                        <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.firstName}</StyledTableCell>
                        <StyledTableCell align='left'>{row.email}</StyledTableCell>
                        <StyledTableCell align='left'>{row.phoneNumber}</StyledTableCell>
                        <StyledTableCell align='left'>{row.deviceType}</StyledTableCell>
                        <StyledTableCell align='left'>{row.loginType}</StyledTableCell>
                        <StyledTableCell align='left'>{row.isDeleted ? 'Inactive' : 'Active'}</StyledTableCell>
                        <StyledTableCell
                          align='right'
                          style={{ padding: 0, width: 10 }}
                        >
                          {row.isDeleted ? (
                            <IconButton onClick={e => { setdelete(row); setAction(false) }}>
                              <FlipCameraAndroid style={{ color: 'green' }} />
                            </IconButton>
                          ) : (
                            <IconButton onClick={e => { setdelete(row); setAction(true) }}>
                              <DeleteOutlineIcon style={{ color: '#FF0000' }} />
                            </IconButton>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, count]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </>

          {/*(create || update) && (
              <ServiceAdd open={create || update} update={update} onClose={e => { setcreate(false); setupdate(null); getUsers() }} />
            )*/}
          <DeleteDialog open={delete1} onOkClick={handleUserDelete} onCancelClick={e => setdelete(null)} what={'user'} isDeleted={!action} />

          <Snackbar open={error} autoHideDuration={3000} onClose={e => setError(null)}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: '10px' }}
          >
            Export to Excel
          </Button>
        </Paper>
      )}
    </>
  );
}
const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})

export default withStyles(rootStyles)(Users)