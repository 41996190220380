import { useContext, useEffect, useState } from "react";
import TablePagination from '@material-ui/core/TablePagination';

import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { BounceLoader } from "react-spinners";
import { css } from "@emotion/core";
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import userContext from "../context/userContext";
import TableHeader from '../components/table/TableHeader';
import FilterAdd from './FilterAdd';
import DeleteDialog from '../components/dialog/DeleteDialog';
import { BASE_API_URL } from '../constant';


const handleTypes = (type) => {
  let val = ""
  switch (type) {
    case 1: val = "Single Select"; break
    case 2: val = "Multi Select"; break
    case 3: val = "Date"; break
    case 4: val = "Date Range"; break
    case 5: val = "Time"; break
    case 6: val = "Time Range"; break
    case 7: val = "Range"; break
    case 8: val = "Location"; break

  }
  return val
}

// console.log(handleTypes(1))

const StyledTableCell = withStyles(() => ({
  head: {
    backgroundColor: "#FFF",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);


function EnhancedTableHead() {
  return (
    <TableHead >
      <StyledTableRow >

        <StyledTableCell>S No</StyledTableCell>
        <StyledTableCell>NAME</StyledTableCell>
        <StyledTableCell>TYPE</StyledTableCell>
        <StyledTableCell>VALUE</StyledTableCell>
        <StyledTableCell></StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Filter() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const { userData, setUserData } = useContext(userContext);
  const [count, setcount] = useState(0);
  const [create, setcreate] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [forDelete, setForDelete] = useState(null);
  const [update, setupdate] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const classes = useStyles();




  const getFilters = async () => {
    let options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(
      `${BASE_API_URL}events-filters?search=${search}&skip=${page * rowsPerPage}&limit=${rowsPerPage}`,
      options
    );
    const data = response.json();
    data
      .then((data) => {
        if (response.status === 401) {
          localStorage.removeItem("auth-token");
          setUserData({
            token: "",
          });
        }

        setRows(data.data.result);
        setcount(data.data.totalFilter);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirmDelete = async () => {
    const formdata = new FormData();
    formdata.append("filterId", forDelete._id);

    formdata.append("isDeleted", true);
    let options = {
      method: "POST",
      headers: {
        Authorization: userData.token,
      },
      body: formdata,
    };
    const response = await fetch(
      `${BASE_API_URL}events-filters`,
      options
    );
    try {
      const filterResult = await response.json();
      getFilters()
      setForDelete(null);
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getFilters();
    // eslint-disable-next-line
  }, [search, rowsPerPage, page, create, update]);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) :
        (
          <div className={classes.root} style={{ margin: 10 }}>

            <Paper className={classes.paper}>
              <TableHeader
                lable="Filter List"
                btnName="Create Filter"
                value={search}
                onChange={e => { setsearch(e.target.value); setPage(0) }}
                onButtonClicked={e => setcreate(true)}
              />
              <div
                style={{
                  border: "0.25px solid rgba(0, 0, 0, 0.1)",
                  width: "100%",

                }}
              />
              <TableContainer>
                <Table className={classes.table}>
                  <EnhancedTableHead />
                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <StyledTableRow

                          key={row.name}>

                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{page * rowsPerPage + index + 1}</StyledTableCell>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.name}</StyledTableCell>
                          <StyledTableCell align='left'>{handleTypes(row.type)}</StyledTableCell>
                          <StyledTableCell style={{ width: '40%' }} >
                            {row.type === 7 && (row.membersMinRange + " - " + row.membersMaxRange)}
                            {row.type === 2 && (row.multiSelect.join(' - '))}
                            {row.type === 1 && (row.singleSelect.join(' - '))}

                          </StyledTableCell>
                          <StyledTableCell
                            align='right'
                          >
                            <Grid container justify="flex-end">
                              <IconButton onClick={e => setupdate(row)}>
                                <EditOutlinedIcon />
                              </IconButton>
                              <IconButton onClick={e => setForDelete(row)}>
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </Grid>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Paper>
            <FilterAdd open={create || update} update={update} onClick={e => { setcreate(false); setupdate(null) }} />
            <DeleteDialog open={forDelete} what="Filter" onOkClick={handleConfirmDelete} onCancelClick={e => setForDelete(null)} />
          </div>
        )
      }
    </>
  );
}


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30
  },
  paper: {
    backgroundColor: "#F2F2F2",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
