import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import Button from '@material-ui/core/Button';
import {BASE_API_URL} from '../../constant';
import userContext from "../../context/userContext";


const SendNotificationToAll = () => {
    const history = useHistory();
    const { userData, setUserData } = useContext(userContext);
    const [notification, setNotification] = useState({
        notification_title:"",
        notification_message:"",
    })

    let name, value;
    const handleInputs = (e) => {
        name=e.target.name;
        value=e.target.value;
        setNotification({...notification, [name]:value}); 
    }

    const PostData = async (e) => {
        e.preventDefault();
        const {
            notification_title,
            notification_message } = notification;
        const res = await fetch(`${BASE_API_URL}send-notification-to-all`,{
            method:"POST",
            headers:{
                    "Content-Type":"application/json",
                    "authorization":userData.token
            },
            body:JSON.stringify({
                notification_title,
                notification_message
            })          
        });

        const data=await res.json();

        if (res.status === 401) {
              localStorage.removeItem("auth-token");
              setUserData({
                token: "",
              });
        }
        
        if(!data)
        {
            window.alert("Somthing went wrong");
        }
        else
        {
            window.alert("Notification sent successfully");
        }
    }
return ( 
    <div class="container">
        <div class="row">
            <h2 class="page-title my-3 ">Notification</h2>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="border-bottom pb-2 ">
                    <div id="services" className="tab-pane active my-4">
                        <h2 className="heading">Notification to all</h2>
                        <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3 ">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="couponType">Notification Title<span className='orangeTxt'>*</span></label>
                                        <input type="text" name="notification_title" className="form-control" value={notification.notification_title} onChange={handleInputs} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-6">
                                        <div className="form-group">
                                        <label htmlFor="applying">Message<span className='orangeTxt'>*</span></label>
                                            <textarea name="notification_message" className="form-control" onChange={handleInputs}>{notification.notification_message}</textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>          
                        </div>
                        <Button  color="primary" disableElevation variant="contained" onClick={PostData}>Save</Button>
                        </div>          
                    </div>
            </div>                    
        </div>                
    </div>
            
    );
}

export default SendNotificationToAll;