import React, { useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Button,
  lighten
} from "@material-ui/core";
import * as XLSX from 'xlsx';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { BASE_API_URL } from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import priceType from "./../../util/priceType";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';


function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell>Sr.No.</StyledTableCell>
        <StyledTableCell>Service Name</StyledTableCell>
        <StyledTableCell>Service Type</StyledTableCell>
        <StyledTableCell>Vendor Name</StyledTableCell>
        <StyledTableCell>Price Type</StyledTableCell>
        <StyledTableCell>Price</StyledTableCell>
        <StyledTableCell>Status</StyledTableCell>
        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = ({ vendorId, VendorChange, vendorName, serviceType, selectedService, handleService }) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Vendor service listing
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Vendor</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={vendorId}
            onChange={VendorChange}
          >
            <MenuItem value="">Select Vendor</MenuItem>
            {vendorName.map(element => {
              return <MenuItem value={element._id}>{element.firstName} {element.lastName}</MenuItem>
            })}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-simple-select-helper-label">By Service Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedService}
            onChange={handleService}
          >
            <MenuItem value="">Select Service Type</MenuItem>
            {serviceType.map(element => {
              return <MenuItem value={element._id}>{element.name} </MenuItem>
            })}
          </Select>
        </FormControl>
      </div>
    </Toolbar>
  );
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const VendorsLog = ({ classes }) => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setsearch] = useState('');
  const { userData, setUserData } = useContext(userContext);
  const [count, setcount] = useState(0);
  const [create, setcreate] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [vendorId, setVenderId] = React.useState("");
  const [selectedService, setSelectedService] = React.useState("");
  const [vendor, setVendor] = useState([]);
  const [serviceType, setServiceType] = useState([]);
  const [selectedVenderService, setselectedVenderService] = useState(
    {
      vser: []
    }
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const VendorChange = (event) => {
    setVenderId(event.target.value);
  };

  const handleService = (event) => {
    setSelectedService(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getServices = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(`${BASE_API_URL}get-vendor-services?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}&vendorId=${vendorId}&serviceId=${selectedService}`, options);
    const data = response.json();

    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      console.log(data)
      setRows(data.data.result);
      setVendor(data.data.vendorData)
      setcount(data.data.serviceCount);
      setLoading(false);
    })
      .catch((error) => console.log(error));
  };

  const getServiceType = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
    const response = await fetch(`${BASE_API_URL}service-types?limit=100`, options);
    const data = response.json();

    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setServiceType(data.data.result);
    })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getServices();
    getServiceType();
    // eslint-disable-next-line
  }, [search, create, rowsPerPage, page, vendorId, selectedService]);

  const exportToExcel = () => {
    console.log('exportToExcel started');
    if (!rows || rows.length === 0) {
      console.error('No data to export!');
      return;
    }

    // Filter the rows to include only the required fields and add an 'ID' column
    const filteredData = rows.map((row, index) => {
      let v = vendor.find((vid) => vid._id === row.vendorId);
      return {
        'Sr No.': index + 1,
        'Service Name': row.title || 'N/A',
        'Service Type': row.serviceId?.name || 'N/A',
        'Vendor Name': v ? v.firstName : 'N/A',
        'Price Type': priceType[row.priceType] || 'N/A',
        'Price': row.price || 'N/A',
        'Status': row.status === 0 ? 'Active' : 'Inactive'
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

    XLSX.writeFile(workbook, "vendors-log.xlsx");
    console.log('exportToExcel ended');
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
        <Paper style={{ margin: 10 }}>
          <EnhancedTableToolbar selectedService={selectedService} handleService={handleService} serviceType={serviceType} vendorName={vendor} vendorId={vendorId} VendorChange={VendorChange} onChange={e => { setsearch(e.target.value); setPage(0) }} />
          <Divider />
          <TableContainer>
            <Table >
              <EnhancedTableHead />
              <TableBody>
                {rows.map((row, index) => {
                  let v = vendor.find((vid) => { return vid._id == row.vendorId });
                  return (
                    <StyledTableRow
                      key={row.name}>
                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> {page * rowsPerPage + index + 1}</StyledTableCell>
                      <StyledTableCell align='left' style={{ fontWeight: 'bold' }}>{row.title}</StyledTableCell>
                      {/* <StyledTableCell align='left'>{row.serviceId.name}</StyledTableCell> */}
                      <StyledTableCell align='left'>{row.serviceId?.name || 'N/A'}</StyledTableCell>
                      <StyledTableCell align='left'>{v.firstName} {v.lastName}</StyledTableCell>
                      <StyledTableCell align='left'>{priceType[row.priceType]}</StyledTableCell>
                      <StyledTableCell align='left'>{row.price}</StyledTableCell>
                      <StyledTableCell align='left'> <span style={{ color: 'blue' }}>{row.status === 0 ? 'Active' : 'Inactive'}</span></StyledTableCell>
                      <StyledTableCell align='left'>
                        <a href={`/vendor-listing-details/${row._id}`} target="_blank"><button className="btn-orange">view</button></a>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, count]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToExcel}
            style={{ marginBottom: '10px' }}
          >
            Export to Excel
          </Button>
        </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 14,
    padding: '0px 16px',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})

export default withStyles(rootStyles)(VendorsLog)