import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import './../../styles/bootstrap.css';
import './../../styles/App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from '@material-ui/core/Button';
import {BASE_API_URL} from '../../constant';
import userContext from "../../context/userContext";
import moment from "moment";
import axios from "axios";
import { uploadImageToS3 } from '../../aws/awsUploader';
import {Link,useParams} from 'react-router-dom'


const EditProfessional = () => {
    const history = useHistory();
    const { userData, setUserData } = useContext(userContext);
    const [errMessage, setErrMessage] = useState('');
    const [onBoard, setOnBoard] = useState(false);
    const [locations,setLocation] = useState([]);
    const [services,setServices] = useState([]);
    const params = useParams();
    const [user, setUser] = useState({
        firstName:"", 
        lastName:"",
        email:"",
        phoneNumber:"",
        specilization:"",
        user_type:"",
        password:"",
        address:"",
    })
    
    const [userDetails, setUserDetails] = useState({
        aboutyou:"",
	    mobile:"",
	    whereYourBusiness:"",
        vendorId:params.vendorId,
	    visaType:"",
	    planDetails:"",
	    planExpiry:"",
	    agreementFileUrl:"",
	    bankName:"",
	    accountName:"",
	    ibanNo:"",
	    accountNumber:"",
	    swift:"",
	    branchAddress:"",
    })

    const setPlanExpiry = (e) => { 
        //setStartDate(moment.unix(e).format("X"))
        setUserDetails({...userDetails, planExpiry:moment.unix(e).format("X")}); 
    }
	const toReadable = (Unix="") =>{
		return Unix === "" ? moment().format("YYYY-MM-DD") : moment.unix(Unix/1000).format("YYYY-MM-DD");
	}

    const getVendorData = async () => { 
        const options = {
          method: "POST",
          headers: {
            Authorization: userData.token,
            'Content-Type': 'application/json'
          },
          body : JSON.stringify({
            vendorId:params.vendorId
          })
        };
        const response = await fetch(`${BASE_API_URL}get-vendor-information`,options);
        const data = response.json(); 
        data.then((data) => { 
          if (response.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
              token: "",
            });
          }
          else{
            setUser(data.data) ;
            setUserDetails(data.data.vendor_info);
            if(data.data.password===undefined){
                setOnBoard(true);   
            }
          }
         
        })
        .catch((error) => console.log(error));
    };

    useEffect(() => {
        getVendorData();
        getLocations();
        getServices();
        // eslint-disable-next-line
    }, []);

    let name, value;

    const handleInputs = (e) => {
        name=e.target.name;
        value=e.target.value;
        setUser({...user, [name]:value}); 
    }

    const handleInputsDetails = (e) => { 
        name=e.target.name;
        value=e.target.value;
        setUserDetails({...userDetails, [name]:value}); 
    }


    const fileChange = evt => { 
        uploadImageToS3(evt.target.files[0],'vendor_document')
            .then(res => { setUserDetails({ ...userDetails, agreementFileUrl: res.location });  })
            .catch(err => { console.log(err) })
    }

    //Get Locations
    const getLocations = () =>{
        axios.get(`${BASE_API_URL}get-locations`)
        .then(res=>{
            setLocation(res.data.data);
        })
        .catch(err=>{ console.log(err)})
    }
    
    //get services
    const getServices = () =>{
        const headers={
            "Content-Type":"application/json",
            "authorization":userData.token
        }
        axios.get(`${BASE_API_URL}service-types`,{headers})
        .then(res=>{
            setServices(res.data.data.result);
        })
        .catch(err=>{ console.log(err)})
    }

    const PostData = async (e) => {
        e.preventDefault();
        const {
            
            firstName, 
            lastName,
            email,
            phoneNumber,
            specilization,
            user_type,
            address,
            password,
            } = user;

        const {
            aboutyou,
            mobile,
            whereYourBusiness,
            visaType,
            vendorId,
            planDetails,
            planExpiry,
            agreementFileUrl,
            bankName,
            accountName,
            ibanNo,
            accountNumber,
            swift,
            branchAddress} = userDetails;
        const res = await fetch(`${BASE_API_URL}profe-details-edit`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "authorization":userData.token
            },
            body:JSON.stringify({
                vendorId,
                firstName, 
                lastName,
                email,
                phoneNumber,
                specilization,
                user_type,
                password,
                aboutyou,
                mobile,
                whereYourBusiness,
                address,
                visaType,
                planDetails,
                planExpiry,
                agreementFileUrl,
                bankName,
                accountName,
                ibanNo,
                accountNumber,
                swift,
                branchAddress,
                onBoard
            })          
        });

        const data=await res.json();

        if (res.status === 401) {
            localStorage.removeItem("auth-token");
            setUserData({
            token: "",
            });
        }
        else if(res.status === 400 || res.status === 409){
            setErrMessage(data.message)
        }
        else{
            window.alert("Professional update Successfully");
            history.push("/professionals-data");
        }
    }

    return ( 
    <div className="container">
        <div className="row">
	    <h2 className="page-title my-3">Professional Registration (Individual)</h2>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="border-bottom pb-2">
                    <div id="services" className="tab-pane active my-4">
					    <div className="border-outer mb-4">
                            <div className="border-top px-4  py-3">
							    <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <hidden name="vendorId" value={userDetails.vendorId} />
                                        <div className="form-group">
                                            <label for="about">Tell Us about yourself</label>
									        <textarea name="aboutyou" rows="4" cols="50" className="form-control" 
                                             value={userDetails.aboutyou}
                                             onChange={handleInputsDetails}></textarea> 
                                        </div>
                                    </div>
                                </div>
						        <div className="row">
							        <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="firstName">First Name*</label>
                                            <input type="text" className="form-control" 
                                            name="firstName" placeholder="First Name" 
                                            value={user.firstName}
                                            onChange={handleInputs}/>
                                        </div>
                                    </div> 
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="lastName">Last Name*</label>
                                            <input type="text" className="form-control" 
                                            name="lastName" placeholder="Last name" 
                                            value={user.lastName}
                                            onChange={handleInputs}/>
                                        </div>
                                    </div>
                                </div>
						        <div className="row">
							        <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="email">Primary Email*</label>
                                            <input type="email" className="form-control" 
                                            name="email" readOnly
                                            value={user.email} onChange={handleInputs}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="mobile">Telephone</label>
                                            <input type="text" className="form-control" 
                                            name="mobile" 
                                            value={userDetails.mobile} onChange={handleInputsDetails}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="phoneNumber">Primary Mobile*(Don't add 0 or +971)</label>
                                            <input type="phoneNumber" className="form-control" 
                                            readOnly name="phoneNumber" placeholder="Mobile Number(Don't add 0 or +971)" 
                                            value={user.phoneNumber}
                                            maxlength="15" 
                                            onChange={handleInputs}/>
                                        </div>
                                    </div>
                                </div>
                                { onBoard ?
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="password">Password*</label><br/>
                                            <input type="text" className="form-control" name="password" placeholder="" 
                                            value={user.password}
                                            onChange={handleInputs} />
                                            (Minimum 9 character, at least one uppercase letter, one lowercase letter, one number and one special character.)
                                        </div>
                                    </div>
                                </div>
                                :''
                                }
						        <div className="row">
						    	    <div className="col-md-4 col-sm-12 col-xs-12">
                                        <div className="form-group">
                                            <label for="specilization">Specialization*</label>
                                            <select name="specilization" 
                                                className="form-control" 
                                                value={user.specilization}
                                                onChange={handleInputs} >
                                                <option>Select Specialization</option>
                                                {services.map((ser)=>{
                                                    return <option value={ser.name}>{ser.name}</option>
                                                })}
									        </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="whereYourBusiness">Where is your Business*</label>
                                        <select name="whereYourBusiness" 
                                        className="form-control" 
                                        value={userDetails.whereYourBusiness}
                                        onChange={handleInputsDetails} >
                                        <option>Select Location</option>
                                        { locations.map((loc)=>{
                                            return <option value={loc.stateName}>{loc.stateName}</option>
                                        })
                                        }
									    </select>
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="visaType">Visa Type</label>
                                        <select name="visaType" className="form-control" 
                                        value={userDetails.visaType}
                                        onChange={handleInputsDetails} >
										    <option value="work-visa">UAE Work Visa</option>
										    <option value="student-visa">UAE Student Visa</option>
										    <option value="family-visa">UAE Family Visa</option>
										    <option value="investment-visa">UAE Investment Visa</option>
										    <option value="retirement-visa">UAE Retirement Visa</option>
									    </select>
                                    </div>
                                </div>
                            </div>
						    <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="address">Address*</label>
                                        <input type="text" className="form-control" 
                                        name="address"
                                        value={user.address}
                                        onChange={handleInputs}
                                        placeholder="Enter Address" />
                                    </div>
                                </div>
                            </div>
						</div>          
                    </div>
				    <h2 className="heading">Professional Plan</h2>
					<div className="border-outer mb-4">
                        <div className="border-top px-4  py-3 ">
							<div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
									    <label for="planDetails">Details of Plan</label>
                                        <input type="text" className="form-control" 
                                            name="planDetails"
                                            value={userDetails.planDetails}
                                            onChange={handleInputsDetails}
                                            />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="planExpiry">Plan Expiry</label>
                                        <DatePicker className="form-control"
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        showYearDropdown
                                        showMonthDropdown
                                        placeholderText="dd/mm/yyyy"
                                        onChange={(date) => setPlanExpiry(date)}
                                        selected = {userDetails.planExpiry==="" ? null : new Date(toReadable(userDetails.planExpiry))}
                                        />
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="agreementFileUrl">Agreement File Upload. {userDetails.agreementFileUrl !==''?<a href={userDetails.agreementFileUrl} target="_blank">View</a>:''}</label>
                                        <input type="file" className="form-control" 
                                        name="agreementFileUrl"
                                        onChange={fileChange}/>
                                    </div>
                                </div>
							</div>
						</div>          
					</div>
				    <h2 className="heading ">Payment Bank Details</h2>
					<div className="border-outer mb-4">
                        <div className="border-top px-4  py-3 ">
							<div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
									    <label for="bankName">Bank Name</label>
                                        <input type="text" className="form-control" 
                                        name="bankName"
                                        value={userDetails.bankName}
                                        onChange={handleInputsDetails} />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="accountName">Account Full Name</label>
                                        <input type="text" className="form-control" 
                                        name="accountName"
                                        value={userDetails.accountName}
                                             onChange={handleInputsDetails} />
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="ibanNo">IBAN No.</label>
                                        <input type="text" className="form-control" 
                                        name="ibanNo"
                                        value={userDetails.ibanNo}
                                             onChange={handleInputsDetails} />
                                    </div>
                                </div>
							</div>
							<div className="row">
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
									    <label for="accountNumber">Account Number</label>
                                        <input type="text" className="form-control" 
                                        name="accountNumber"
                                        value={userDetails.accountNumber}
                                             onChange={handleInputsDetails} />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="swift">Swift</label>
                                        <input type="text" className="form-control" 
                                        name="swift"
                                        value={userDetails.swift}
                                        onChange={handleInputsDetails} />
                                    </div>
                                </div>
							    <div className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="form-group">
                                        <label for="branchAddress">Branch Address</label>
                                        <input type="text" className="form-control" 
                                        name="branchAddress" 
                                        value={userDetails.branchAddress}
                                        onChange={handleInputsDetails} />
                                    </div>
                                </div>
							</div>
						</div>          
					</div>	
				</div>		
                <p style={{color:"red"}}>{errMessage}</p>        
                <Button color="primary" disableElevation variant="contained" onClick={PostData} >Update</Button>
            </div>
		</div>
       
    </div>
</div>
);
}

export default EditProfessional;