import React,{ useContext, useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Button,
  lighten
} from "@material-ui/core";
import {Link} from 'react-router-dom'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {BASE_API_URL} from '../../constant';
import { css } from "@emotion/core";
import { BounceLoader } from "react-spinners";
import userContext from "../../context/userContext";
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
// import BootstrapButton from "../../components/BootstrapButton";
import Modal from "../../components/Modal";
import './../../styles/cssformui.css';
import unixResolver from "../../components/UnixResolver";

function EnhancedTableHead() {
  return (
    <TableHead>
      <StyledTableRow >
        <StyledTableCell style={{ fontWeight: 'bold'}}>ID</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event ID</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>User</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Dates</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Coupon/Wallet</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Name</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Type</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Location</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Amount</StyledTableCell>        
        <StyledTableCell style={{ fontWeight: 'bold'}}>Vendor Service</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Event Status</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Payment Status</StyledTableCell>
        <StyledTableCell style={{ fontWeight: 'bold'}}>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}
const EnhancedTableToolbar = ({ value, onChange, filterStatus,handleChange }) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography
        className={classes.title}
        variant='h6'
        id='tableTitle'
        component='div'
      >
        Booked Events
      </Typography>
      <div style={{ marginLeft: "auto", display: 'flex', alignItems: 'center' }}>
        <OutlinedInput
          id="outlined-adornment-weight"
          startAdornment={<SearchIcon />}
          value={value}
          onChange={onChange}
          placeholder="Enter event ID"
          style={{ marginRight: '10px' }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{ 'aria-label': 'weight', style: { padding: 9 } }}
          labelWidth={0}
        />
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filterStatus}
          label="Status"
          style={{ height: '37px' , width: '150px'}}
         onChange={handleChange}
        >
          <MenuItem value={0}>All</MenuItem>
          <MenuItem value={false}>Pending</MenuItem>
          <MenuItem value={true}>Confirm</MenuItem>
        </Select>
      </div>
      
    </Toolbar>
  );
};
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export default function BookedEvents({ classes }) {
  const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setsearch] = useState('');
    const { userData, setUserData } = useContext(userContext);
    const [count, setcount] = useState(0);
    const [create, setcreate] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [status, setStatus] = React.useState(0);

  const handleChangePage = (event, newPage) => { 
    setPage(newPage);
  };

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getBookings = async () => {
    const options = {
      method: "GET",
      headers: {
        Authorization: userData.token,
      },
    };
   const response = await fetch(
    `${BASE_API_URL}get-booked-events?skip=${page * rowsPerPage}&limit=${rowsPerPage}&search=${search}&estatus=${status}`, options);
   // const response = await fetch(`${VENDOR_API_BASE_URL}booking`);
    const data = response.json(); 
    
    data.then((data) => {
      if (response.status === 401) {
        localStorage.removeItem("auth-token");
        setUserData({
          token: "",
        });
      }
      setRows(data.data.result);
      setcount(data.data.eventCount);
      setLoading(false);
    })
    .catch((error) => console.log(error));
  };

  useEffect(() => {
    getBookings();
    // eslint-disable-next-line
  }, [search, create,rowsPerPage, page,status ]);

  return (
    <>
     {loading ? (
        <div
          style={{
            height: "28rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader
            color={" #f47824"}
            loading={loading}
            css={override}
            size={50}
          />
        </div>
      ) : (
    <Paper style={{ margin: 10 }}>
    <EnhancedTableToolbar value={search} onChange={e => {setsearch(e.target.value); setPage(0)}} filterStatus={status} handleChange={handleChange}/>
    <Divider />
    <TableContainer>
                <Table >
                  <EnhancedTableHead />

                  <TableBody>
                    {rows.map((row, index) => {
                      return (
                        <StyledTableRow
                          key={row.name}>
                          <StyledTableCell align='left' style={{ fontWeight: 'bold' }}> {page*rowsPerPage+index+1}</StyledTableCell>
                          <StyledTableCell align='left'>{row.sysEventId}</StyledTableCell> 
                          <StyledTableCell align='left'>{row.user[0].firstName}<br/>{row.user[0].phoneNumber}</StyledTableCell>
                          <StyledTableCell align='left' style={{fontSize:12,whiteSpace:'nowrap'}}><span style={{fontWeight:'bold'}}>Event:</span> {moment(parseInt(unixResolver(row.eventDate))).format('MMM DD YYYY')} <br/><span style={{fontWeight:'bold'}}>Booked: </span>{moment(row.updatedAt).format('MMM DD YYYY')}</StyledTableCell>
                          <StyledTableCell align='left' style={{fontSize:12,whiteSpace:'nowrap'}}><span style={{fontWeight:'bold'}}>Coupon:</span>{row?.coupondata?.[0]?.couponCode || 'None'} <br/>
                            <span style={{color:'orange'}}>
                               <span style={{fontWeight:'bold'}}>AED Wallet:</span> {row?.coupondata?.[0]?.walletAmount || 'None'}
                            </span>
                           </StyledTableCell>
                          <StyledTableCell align='left'><Link to={`/booked-event-details/${row._id}`} href="_blank">{row.eventName}</Link></StyledTableCell>
                          <StyledTableCell align='left'>{row.eventType==1?'Service':'Professional'}</StyledTableCell>
                          <StyledTableCell align='left'>{row.eventAddress !=''? row.eventAddress: row.user[0].ship_add.address}<br/>{row?.user?.[0]?.ship_add?.city }</StyledTableCell>
                          <StyledTableCell align='right'>AED {row.packagePrice}</StyledTableCell> 
                          <StyledTableCell align='left'>{row.count.accepted}/{row.count.total}</StyledTableCell> 
                          {row.isApproved? <StyledTableCell align='left' style={{ color:"green" }}>Confirm</StyledTableCell>:<StyledTableCell align='left' style={{ color:"blue" }}>Pending</StyledTableCell>} 
                          {row.status==3? <StyledTableCell align='left' style={{ color:"green" }}>Full payment</StyledTableCell>:<StyledTableCell align='left' style={{ color:"blue" }}>Half Payment</StyledTableCell>}  
                          {row.count.accepted != row.count.total ? <StyledTableCell align='left'>NA</StyledTableCell>:<StyledTableCell style={{ color:"blue" }}><Link to={`/payment-log/${row._id}`}>Payment Log</Link></StyledTableCell>}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25,50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
    </Paper>
      )}
    </>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  title: {
    fontWeight: '600'
  },
  root: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff'
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: "#787878",
  },
  body: {
    fontSize: 13,
    padding: '0px 16px',
    whiteSpace:'nowrap'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    "&:nth-of-type(odd)": {
      backgroundColor: '#f8f8f8',
    },
  },
}))(TableRow);

const rootStyles = theme => ({
  btn: {
    border: '2px solid #f7cbb0',
    borderRadius: 2,
    backgroundColor: '#fef5f0',
    textAlign: 'center',
    padding: 3,
    fontWeight: 'bold',
    fontSize: 12,
    color: '#f87a25',
    marginRight: 6,
    marginTop: 3,
    marginBottom: 3
  },
  noMatch: {
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: " #f47824",
    textTransform: "uppercase"
  }
})